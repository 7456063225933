import { TFunction, useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MasterReportType, ReportType } from '../utils/assessmentType';
import UsageReportIcon from './icons/UsageReportIcon';

interface UsageReportInfoCardProps {
  title: string;
  clinicAccountId: string;
  clinicAccountName: string;
  reportType: string;
  fromDate: string;
  toDate: string;
  total: number;
  totalCompleted?: number;
  onDownLoadReport: () => Promise<void>;
}

const getTotalAssessmentName = (reportType: string, t: TFunction<'translation', undefined>) => {
  if (reportType === MasterReportType.All) {
    return t('report.usageReport.infoCard.totalAssessments');
  } else if (reportType === ReportType.Interview) {
    return t('report.usageReport.infoCard.totalInterviews');
  } else {
    return t('report.usageReport.infoCard.totalMonitors');
  }
};

const getAssessmentCompletedName = (reportType: string, t: TFunction<'translation', undefined>) => {
  if (reportType === MasterReportType.All) {
    return t('report.usageReport.infoCard.assessmentsCompleted');
  } else if (reportType === ReportType.Interview) {
    return t('report.usageReport.infoCard.interviewsCompleted');
  } else {
    return t('report.usageReport.infoCard.monitorsCompleted');
  }
};

export default function UsageReportInfoCard({
  clinicAccountId,
  clinicAccountName,
  reportType,
  fromDate,
  toDate,
  total,
  totalCompleted,
  onDownLoadReport,
  title,
}: UsageReportInfoCardProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex' }}>
        <UsageReportIcon />
        <Box
          sx={{
            ml: 1,
          }}
        >
          <>
            <Typography component="span" sx={{ fontWeight: 600 }}>
              {title}{' '}
              <Typography component="span" sx={{ fontWeight: 600 }}>
                {clinicAccountName ? clinicAccountName + ' (' + clinicAccountId + ')' : ''}
              </Typography>
            </Typography>
            <Box sx={(theme) => ({ color: theme.xPalette.lightGrey })}>
              <Typography
                component="span"
                variant="body1"
                sx={(theme) => ({ color: theme.xPalette.shadeGrey })}
              >
                {t('report.usageReport.infoCard.time')}
              </Typography>{' '}
              <Typography
                component="span"
                variant="body1"
                sx={(theme) => ({ color: theme.xPalette.black })}
              >{`${fromDate} - ${toDate}`}</Typography>
            </Box>
            <Box sx={(theme) => ({ color: theme.xPalette.lightGrey })}>
              <Typography
                component="span"
                variant="body1"
                sx={(theme) => ({ color: theme.xPalette.shadeGrey })}
              >
                {getTotalAssessmentName(reportType, t)}
              </Typography>{' '}
              <Typography
                component="span"
                variant="body1"
                sx={(theme) => ({ color: theme.xPalette.black })}
              >
                {total}
              </Typography>
            </Box>
            {typeof totalCompleted !== 'undefined' && (
              <Box sx={(theme) => ({ color: theme.xPalette.lightGrey })}>
                <Typography
                  component="span"
                  variant="body1"
                  sx={(theme) => ({ color: theme.xPalette.shadeGrey })}
                >
                  {getAssessmentCompletedName(reportType, t)}
                </Typography>{' '}
                <Typography
                  component="span"
                  variant="body1"
                  sx={(theme) => ({ color: theme.xPalette.black })}
                >
                  {totalCompleted}
                </Typography>
              </Box>
            )}
          </>
        </Box>
      </Box>
      <Button
        type="button"
        fullWidth
        variant="contained"
        sx={{
          width: matches ? '100%' : '30%',
          my: 2,
          py: 2,
          float: 'right',
        }}
        onClick={async () => {
          await onDownLoadReport();
        }}
      >
        {t('report.usageReport.infoCard.downloadReport')}
      </Button>
    </Box>
  );
}
