import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import EditPencilIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import HistoryIcon from '@mui/icons-material/History';
import LockOpen from '@mui/icons-material/LockOpen';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import EyeIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  useChangeInterviewModeToClinicianMutation,
  useCheckResultInProgressOfPacketMutation,
  useSendInterviewEmailOrTextTheQuestionsMutation,
  useUnlockReportMutation,
} from '../../app/services/interview';
import {
  useChangeMonitorModeToClinicianMutation,
  useSendMonitorEmailOrTextTheQuestionsMutation,
} from '../../app/services/monitor';
import { useToast } from '../../app/toast';
import PopupModal from '../../common/components/PopupModal';
import EditInterviewIcon from '../../common/components/icons/EditInterviewIcon';
import { PageStatus, setPageStatus } from '../../common/slices/globalSlice';
import { AssessmentStatus } from '../../common/utils/assessmentStatus';
import { AssessmentType } from '../../common/utils/assessmentType';
import { InterviewMode } from '../../common/utils/interviewsMode';
import { logger } from '../../common/utils/logger';
import { Role } from '../../common/utils/loginRole';
import { MonitorModeName } from '../../common/utils/monitorsMode';
import { PatientStatus } from '../../common/utils/patientStatus';
import { DownloadReportRequest } from '../../types/InterviewReportRequest';
import { CheckResultInProgressOfPacketResponse } from '../../types/InterviewTypes';
import { AssessmentMode, LatestAssessmentItem, PatientInfo } from '../../types/PatientRequest';
import {
  ChangeInterviewModeToClinicianResponse,
  ChangeMonitorModeToClinicianResponse,
} from '../../types/PatientType';
import { selectUser } from '../auth/authSlice';
import { setInterviewMode, setTrialInterview } from '../interview/interviewSlice';
import { setMonitorMode } from '../monitor/monitorSlide';
import ContextMenu from './ContextMenu';
import useDownloadInterviewOrMonitorReport from './hooks/useDownloadInterviewOrMonitorReport';
import { clearAssessmentCachedData } from './patientUtils';

interface Props {
  patientInfo?: PatientInfo;
  assessmentItem: LatestAssessmentItem;
  patientId: string;
  assessmentId: number;
  status: string;
  type: AssessmentType;
  patientStatus?: string;
  reducedTypeId: number;
  hasInterval?: boolean;
  completedAt: Date;
  callShowPopup: () => Promise<void>;
  editExpireTimeStart?: Date;
  refetchData?: any;
  isSignature?: boolean;
}

interface PopupContent {
  title: string;
  description: string;
  btnOk: string;
  btnClose: string;
  toastMessage: string;
  hidePatient?: boolean;
}

export default function DashboardTableMenu({
  patientInfo,
  assessmentItem,
  patientId,
  assessmentId,
  status,
  type,
  patientStatus,
  reducedTypeId,
  hasInterval,
  completedAt,
  callShowPopup,
  editExpireTimeStart,
  refetchData,
  isSignature,
}: Props) {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectUser);
  const isAssessmentCompleted = status === AssessmentStatus.Completed;
  const [isShowPacketPopup, setIsShowPacketPopup] = useState<boolean>(false);
  const [continueHandler, setContinueHandler] = useState<any>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { startDownloadReport } = useDownloadInterviewOrMonitorReport(t);

  const [unlockReport] = useUnlockReportMutation();

  const [sendInterviewEmailOrTextTheQuestions] = useSendInterviewEmailOrTextTheQuestionsMutation();

  const [sendMonitorEmailOrTextTheQuestions] = useSendMonitorEmailOrTextTheQuestionsMutation();

  const [checkResultInProgressOfPacket] = useCheckResultInProgressOfPacketMutation();

  const [changeInterviewModeToClinician] = useChangeInterviewModeToClinicianMutation();

  const [changeMonitorModeToClinician] = useChangeMonitorModeToClinicianMutation();

  const deleteReport = () => {
    dispatch(callShowPopup);
    handleClose();
  };

  const packetContentPopup = {
    title: t('patient.patientDashboard.menuList.packetPopup.title'),
    description: t('patient.patientDashboard.menuList.packetPopup.description'),
    btnOk: t('patient.patientDashboard.menuList.packetPopup.btnOk'),
    btnClose: t('patient.patientDashboard.menuList.packetPopup.btnClose'),
  } as PopupContent;

  const viewReport = async (type: AssessmentType) => {
    dispatch(setPageStatus({ pageStatus: PageStatus.Loading }));
    handleClose();

    let data: DownloadReportRequest;
    if (type === AssessmentType.Interview) {
      data = {
        interviewResultId: assessmentId,
        latestVersion: true,
        type: AssessmentType.Interview,
      };
    } else {
      data = {
        monitorResultId: assessmentId,
        type: AssessmentType.Monitor,
      };
    }
    await startDownloadReport(data);
  };

  const isSingleAssessment = (
    checkResultInProgressOfPacketResponse: CheckResultInProgressOfPacketResponse
  ) =>
    checkResultInProgressOfPacketResponse &&
    !checkResultInProgressOfPacketResponse.success &&
    !checkResultInProgressOfPacketResponse.interviewResultId &&
    !checkResultInProgressOfPacketResponse.trackerResultId;

  const handleCheckResultInProgressOfPacket = async (
    isAllowToShowPacketPopup: boolean,
    type: AssessmentType,
    assessmentId: number
  ): Promise<CheckResultInProgressOfPacketResponse> => {
    const checkResultInProgressOfPacketResponse: CheckResultInProgressOfPacketResponse =
      await checkResultInProgressOfPacket({
        [type === AssessmentType.Interview ? 'interviewResultId' : 'trackerResultId']: assessmentId,
      }).unwrap();

    if (!isSingleAssessment(checkResultInProgressOfPacketResponse) && isAllowToShowPacketPopup) {
      setIsShowPacketPopup(true);
    }
    return checkResultInProgressOfPacketResponse;
  };

  const handleProviderAsksTheQuestions = async (assessmentId: number) => {
    handleClose();
    try {
      if (type === AssessmentType.Interview) {
        const changeInterviewModeToClinicianResponse: ChangeInterviewModeToClinicianResponse =
          await changeInterviewModeToClinician({
            interviewResultId: assessmentId,
          }).unwrap();
        if (changeInterviewModeToClinicianResponse?.newInterviewResultId) {
          clearAssessmentCachedData();
          refetchData();
          dispatch(setInterviewMode({ interviewMode: InterviewMode.Clinician }));
          navigate(
            `/dashboard/patient/${patientId}/${changeInterviewModeToClinicianResponse?.newInterviewResultId}/start-interview`,
            {
              state: { resumeInterview: false },
            }
          );
        }
      } else {
        const changeMonitorModeToClinicianResponse: ChangeMonitorModeToClinicianResponse =
          await changeMonitorModeToClinician({
            trackerResultId: assessmentId,
          }).unwrap();
        if (changeMonitorModeToClinicianResponse?.newTrackerResultId) {
          clearAssessmentCachedData();
          refetchData();
          dispatch(setMonitorMode({ monitorMode: MonitorModeName.Clinician }));
          navigate(
            `/dashboard/patient/${patientId}/${changeMonitorModeToClinicianResponse?.newTrackerResultId}/start-monitor`,
            {
              state: { resumeInterview: false },
            }
          );
        }
      }
    } catch (e) {
      dispatch(setPageStatus({ pageStatus: PageStatus.Idle }));
      toast.publish(
        t('patient.patientDashboard.form.intakePacketPopup.patientUsesThisDeviceError'),
        'error'
      );
    }
  };

  const handleRestartOrResumeInterview = async () => {
    handleClose();
    const { id, trial, type, status } = assessmentItem;
    try {
      const checkResultInProgressOfPacketResponse = await handleCheckResultInProgressOfPacket(
        true,
        type,
        id
      );
      if (isSingleAssessment(checkResultInProgressOfPacketResponse)) {
        if (status === AssessmentStatus.InProgress) {
          if (type === AssessmentType.Interview) {
            navigate(`/dashboard/patient/${patientId}/${id}/resume-interview`, {
              state: { resumeInterview: true },
            });
            dispatch(setTrialInterview({ trialInterview: trial || false }));
          } else {
            navigate(`/dashboard/patient/${patientId}/${id}/resume-monitor`, {
              state: { resumeMonitor: true },
            });
          }
        } else {
          if (type === AssessmentType.Interview) {
            navigate(`/dashboard/patient/${patientId}/${id}/start-interview`, {
              state: { resumeInterview: false },
            });
          } else {
            navigate(`/dashboard/patient/${patientId}/${id}/start-monitor`, {
              state: { resumeInterview: false },
            });
          }
        }
      } else {
        let resultType: AssessmentType = type;
        if (
          checkResultInProgressOfPacketResponse &&
          !checkResultInProgressOfPacketResponse.success
        ) {
          resultType = checkResultInProgressOfPacketResponse.interviewResultId
            ? AssessmentType.Interview
            : AssessmentType.Monitor;
        }

        const resultStatus = checkResultInProgressOfPacketResponse?.statusData || status;
        const resultTrial: boolean = (checkResultInProgressOfPacketResponse?.trial || trial)!;
        if (resultStatus === AssessmentStatus.InProgress) {
          if (resultType === AssessmentType.Interview) {
            setContinueHandler(() => {
              return () => {
                navigate(
                  `/dashboard/patient/${patientId}/${
                    checkResultInProgressOfPacketResponse.success
                      ? id
                      : checkResultInProgressOfPacketResponse.interviewResultId
                  }/resume-interview`,
                  {
                    state: { resumeInterview: true },
                  }
                );
                dispatch(setTrialInterview({ trialInterview: resultTrial || false }));
              };
            });
          } else {
            setContinueHandler(() => {
              return () => {
                navigate(
                  `/dashboard/patient/${patientId}/${
                    checkResultInProgressOfPacketResponse.success
                      ? id
                      : checkResultInProgressOfPacketResponse.trackerResultId
                  }/resume-monitor`,
                  {
                    state: { resumeMonitor: true },
                  }
                );
              };
            });
          }
        } else {
          if (resultType === AssessmentType.Interview) {
            setContinueHandler(() => {
              return () => {
                navigate(
                  `/dashboard/patient/${patientId}/${
                    checkResultInProgressOfPacketResponse.success
                      ? id
                      : checkResultInProgressOfPacketResponse.interviewResultId
                  }/start-interview`,
                  {
                    state: { resumeInterview: false },
                  }
                );
              };
            });
          } else {
            setContinueHandler(() => {
              return () => {
                navigate(
                  `/dashboard/patient/${patientId}/${
                    checkResultInProgressOfPacketResponse.success
                      ? id
                      : checkResultInProgressOfPacketResponse.trackerResultId
                  }/start-monitor`,
                  {
                    state: { resumeInterview: false },
                  }
                );
              };
            });
          }
        }
      }
    } catch (e) {
      logger.error(e);
    }
  };

  const handleEmailOrTextTheQuestions = async (status: string, assessmentId: number) => {
    handleClose();
    try {
      if (type === AssessmentType.Interview) {
        await sendInterviewEmailOrTextTheQuestions({
          interviewResultId: assessmentId,
        }).unwrap();
        toast.publish(
          t('patient.patientDashboard.form.intakePacketPopup.sendEmailOrTextTheQuestionsSuccess'),
          'success'
        );
      } else {
        await sendMonitorEmailOrTextTheQuestions({
          trackerResultId: assessmentId,
        }).unwrap();
        toast.publish(
          t('patient.patientDashboard.form.intakePacketPopup.sendEmailOrTextTheQuestionsSuccess'),
          'success'
        );
      }
    } catch (e) {
      dispatch(setPageStatus({ pageStatus: PageStatus.Idle }));
      toast.publish(
        t('patient.patientDashboard.form.intakePacketPopup.sendEmailOrTextTheQuestionsError'),
        'error'
      );
    }
  };

  const viewReportHistory = () => {
    const url = generatePath('/dashboard/patient/:patientId/report-history/:interviewResultId', {
      patientId,
      interviewResultId: assessmentId + '',
    });
    navigate(url);
  };

  const editSchedule = () => {
    const url = generatePath(
      '/dashboard/patient/:patientId/edit-schedule/:assessmentType/:assessmentTypeId',
      {
        patientId: patientId,
        assessmentType: type + '',
        assessmentTypeId: reducedTypeId.toString(),
      }
    );
    navigate(url);
  };

  const editInterview = () => {
    const url = generatePath('/dashboard/patient/:patientId/:interviewResultId/edit-interview', {
      patientId: patientId,
      interviewResultId: assessmentId + '',
    });
    navigate(url);
  };

  const handleUnlockReport = async () => {
    await unlockReport({
      interviewResultId: assessmentId,
      patientId,
    }).unwrap();

    handleClose();
    clearAssessmentCachedData();
    refetchData();

    toast.publish(t('interviewReport.success.unlockReport'), 'success');
  };

  const handleClickPacketPopup = async (): Promise<void> => {
    setIsShowPacketPopup(false);

    if (continueHandler) {
      await continueHandler();
    }
  };

  const handleClosePacketPopup = async (): Promise<void> => {
    setIsShowPacketPopup(false);
  };

  return (
    <div>
      <PopupModal
        isOpen={isShowPacketPopup}
        contentPopup={packetContentPopup}
        onClick={handleClickPacketPopup}
        onClose={handleClosePacketPopup}
      />
      <ContextMenu
        id="patient-dashboard"
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleClick={handleClick}
        menuItems={[
          {
            isDisplayed: isAssessmentCompleted,
            label: t('patient.patientDashboard.menuList.viewReport'),
            icon: <EyeIcon />,
            onClick: () => viewReport(type),
          },
          {
            isDisplayed:
              !isSignature &&
              patientStatus === PatientStatus.Active &&
              type === AssessmentType.Interview &&
              status !== AssessmentStatus.InProgress &&
              (editExpireTimeStart
                ? new Date().getTime() <
                  new Date(
                    new Date(editExpireTimeStart).setDate(
                      new Date(editExpireTimeStart).getDate() + 14
                    )
                  ).getTime()
                : new Date().getTime() <
                  new Date(
                    new Date(completedAt).setDate(new Date(completedAt).getDate() + 14)
                  ).getTime()),
            label: t('patient.patientDashboard.menuList.editReport'),
            icon: <EditPencilIcon />,
            onClick: editInterview,
          },
          {
            isDisplayed: isAssessmentCompleted && type === AssessmentType.Interview,
            label: t('patient.patientDashboard.menuList.interviewHistory'),
            icon: <HistoryIcon />,
            onClick: viewReportHistory,
          },
          {
            isDisplayed:
              patientStatus === PatientStatus.Active &&
              currentUser.roleId === Role.SuperAdmin &&
              type === AssessmentType.Interview &&
              editExpireTimeStart &&
              new Date().getTime() >
                new Date(
                  new Date(editExpireTimeStart).setDate(
                    new Date(editExpireTimeStart).getDate() + 14
                  )
                ).getTime(),
            label: t('patient.patientDashboard.menuList.unlockReport'),
            icon: <LockOpen />,
            onClick: handleUnlockReport,
          },
          {
            isDisplayed:
              patientStatus === PatientStatus.Active &&
              (status === AssessmentStatus.NotStarted ||
                (status === AssessmentStatus.InProgress &&
                  (assessmentItem.assessmentMode === AssessmentMode.Clinician ||
                    assessmentItem.assessmentMode === AssessmentMode.ExternalClinician))),
            label: t('patient.patientDashboard.menuList.providerAsksTheQuestions'),
            icon: <QuestionMarkOutlinedIcon />,
            onClick: () => {
              if (
                status === AssessmentStatus.NotStarted &&
                (assessmentItem.assessmentMode === AssessmentMode.Patient ||
                  assessmentItem.assessmentMode === AssessmentMode.ExternalPatient)
              ) {
                handleProviderAsksTheQuestions(assessmentId);
              } else if (
                (status === AssessmentStatus.NotStarted ||
                  status === AssessmentStatus.InProgress) &&
                (assessmentItem.assessmentMode === AssessmentMode.Clinician ||
                  assessmentItem.assessmentMode === AssessmentMode.ExternalClinician)
              ) {
                handleRestartOrResumeInterview();
              }
            },
          },
          {
            isDisplayed:
              patientStatus === PatientStatus.Active &&
              !!(patientInfo?.email || patientInfo?.cellPhoneNumber) &&
              assessmentItem.assessmentMode !== AssessmentMode.Clinician &&
              assessmentItem.assessmentMode !== AssessmentMode.ExternalClinician &&
              (status === AssessmentStatus.NotStarted || status === AssessmentStatus.InProgress),
            label: t('patient.patientDashboard.menuList.emailOrTextTheQuestions'),
            icon: <EmailOutlinedIcon />,
            onClick: () => handleEmailOrTextTheQuestions(status, assessmentId),
          },
          {
            isDisplayed:
              patientStatus === PatientStatus.Active &&
              assessmentItem.assessmentMode !== AssessmentMode.ExternalClinician &&
              assessmentItem.assessmentMode !== AssessmentMode.Clinician &&
              (status === AssessmentStatus.NotStarted || status === AssessmentStatus.InProgress),
            label: t('patient.patientDashboard.menuList.patientUsesThisDevice'),
            icon: <DevicesOutlinedIcon />,
            onClick: handleRestartOrResumeInterview,
          },
          {
            isDisplayed:
              patientStatus === PatientStatus.Active && reducedTypeId! > 0 && hasInterval === true,
            label: t('patient.patientDashboard.menuList.editSchedule'),
            icon: <EditInterviewIcon />,
            onClick: editSchedule,
          },
          {
            isDisplayed:
              patientStatus === PatientStatus.Active && status !== AssessmentStatus.PendingScoring,
            label: t('patient.patientDashboard.menuList.deleteReport'),
            icon: <DeleteIcon />,
            onClick: deleteReport,
          },
        ]}
      />
    </div>
  );
}
