import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import Container from '@mui/material/Container';
import { useAppSelector } from '../../app/hooks';
import { useGetClinicAccountsQuery } from '../../app/services/account';
import { useGetBillingSettingsQuery } from '../../app/services/billing';
import {
  useAddSiteMutation,
  useEditSiteMutation,
  useGetSiteInfoQuery,
} from '../../app/services/site';
import { useToast } from '../../app/toast';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import SiteInfoCard from '../../common/components/SiteInfoCard';
import DashboardLayout from '../../common/layouts/Dashboard';
import { addBreadcrumb, BreadcrumbType } from '../../common/utils/editBreadcrums';
import { HttpStatusCode } from '../../common/utils/httpStatusCode';
import { getPageTitle } from '../../common/utils/pageUtils';
import { canAddSite, canEditSite } from '../../common/utils/permissionUtils';
import { SiteInfo } from '../../types/ClinicSiteType';
import { PageProps } from '../../types/PageProps';
import { selectUser } from '../auth/authSlice';
import ErrorPage from '../error/ErrorPage';
import SiteForm from './SiteForm';
import { Role } from '../../common/utils/loginRole';

export default function AddOrEditSitePage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const params = useParams();
  const siteId: string | undefined = params.siteId;

  const currentUser = useAppSelector(selectUser);
  const canEdit = canEditSite(currentUser.roleId);
  const canAdd = canAddSite(currentUser.roleId);
  const [addSite] = useAddSiteMutation();
  const [editSite] = useEditSiteMutation();
  const { data: clinicAccountData } = useGetClinicAccountsQuery(undefined, {
    skip: (siteId && !canEdit) || (!siteId && !canAdd),
  });
  const { data: billingSettingsData } = useGetBillingSettingsQuery(
    currentUser.roleId === Role.ClinicAdmin || currentUser.roleId === Role.SuperAdmin
      ? {
          siteId: siteId ? parseInt(siteId) : undefined,
        }
      : skipToken
  );
  const { data, error } = useGetSiteInfoQuery(
    siteId && canEdit ? { siteId: parseInt(siteId) } : skipToken
  );

  const { state } = useLocation();
  const { navBackUrl } = !!state && state;
  const newBreadcrumb: BreadcrumbType = {
    text: t('clinicSite.manageSites.title'),
    link: navBackUrl || `/dashboard/sites`,
  };

  const onFormSubmit = async (data: SiteInfo, siteId?: number, successCallback?: () => void) => {
    if (data && !data.intakePacketInterviews) {
      data.intakePacketInterviews = undefined;
    }
    if (data && !data.intakePacketDefaultProvider) {
      data.intakePacketDefaultProvider = undefined;
    }
    if (data && !data.locationId) {
      data.locationId = undefined;
    }
    if (siteId) {
      await editSite({ ...data, id: siteId }).unwrap();
    } else {
      await addSite(data).unwrap();
      navigate(`/dashboard/sites`);
    }

    if (successCallback) {
      successCallback();
    }

    toast.publish(
      (siteId && t('clinicSite.manageSites.updateSuccess')) ||
        t('clinicSite.manageSites.addSuccess'),
      'success'
    );
  };

  if (siteId && !canEdit) {
    return <ErrorPage statusCode={HttpStatusCode.Forbidden} />;
  }
  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }

  return (
    <DashboardLayout breadcrumbs={addBreadcrumb(breadcrumbs!, newBreadcrumb)}>
      <Helmet>
        <title>
          {siteId
            ? getPageTitle(t('clinicSite.manageSites.editSite'))
            : getPageTitle(t('clinicSite.manageSites.addSite'))}
        </title>
      </Helmet>
      <Container maxWidth="xl">
        <PageHeader
          sx={{ mt: 1, mb: 2 }}
          headerText={
            siteId ? t('clinicSite.manageSites.editSite') : t('clinicSite.manageSites.addSite')
          }
          backUrl={navBackUrl || `/dashboard/sites`}
        />
      </Container>

      {siteId && (
        <RoundedContainer sx={{ py: 2, mb: 2 }}>
          <SiteInfoCard data={data!} />
        </RoundedContainer>
      )}

      <SiteForm
        siteId={siteId ? parseInt(siteId) : 0}
        data={data}
        clinicAccounts={clinicAccountData?.clinicAccounts || []}
        billingSettings={billingSettingsData}
        onFormSubmit={onFormSubmit}
      />
    </DashboardLayout>
  );
}
