import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setTimeout } from 'timers';
import { clearAuthState, initialCredentials, selectTokenInfo } from '../../features/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { channel, ChannelTypes } from './channels';

const useBroadcastChannelSync = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tokenInfo = useAppSelector(selectTokenInfo);

  useEffect(() => {
    const handleMessage = (event: any) => {
      const broadCastData = event?.data?.auth;
      if (event.data.type === ChannelTypes.CHECK_AUTH_STATE) {
        const authState: any = JSON.parse(sessionStorage.getItem('persist:session') || '{}');
        if (authState?.token) {
          setTimeout(() => {
            channel.postMessage({ type: ChannelTypes.UPDATE_INITIAL_AUTH_STATE, auth: authState });
          }, 1000);
        }
      }

      if (event.data.type === ChannelTypes.SET_AUTH_STATE) {
        dispatch(
          initialCredentials({
            user:
              typeof broadCastData.user === 'string'
                ? JSON.parse(broadCastData.user)
                : broadCastData.user,
            token: broadCastData.token,
            refreshToken: broadCastData.refreshToken,
            isShowMfa: broadCastData.isShowMfa ? JSON.parse(broadCastData.isShowMfa) : false,
            remainingTime: broadCastData.remainingTime
              ? JSON.parse(broadCastData.remainingTime)
              : null,
            isAuthenticated: JSON.parse(broadCastData.isAuthenticated),
            lastLoginAt: JSON.parse(broadCastData.lastLoginAt),
          })
        );
        navigate('/dashboard', { replace: true });
      }

      if (event.data.type === ChannelTypes.CLEAR_AUTH_STATE) {
        dispatch(clearAuthState());
      }

      if (event.data.type === ChannelTypes.UPDATE_INITIAL_AUTH_STATE) {
        if (!tokenInfo?.accessToken) {
          dispatch(
            initialCredentials({
              user: JSON.parse(broadCastData.user),
              token: JSON.parse(broadCastData.token),
              refreshToken: broadCastData.refreshToken
                ? JSON.parse(broadCastData.refreshToken)
                : null,
              isShowMfa: broadCastData.isShowMfa ? JSON.parse(broadCastData.isShowMfa) : false,
              remainingTime: broadCastData?.remainingTime
                ? JSON.parse(broadCastData?.remainingTime)
                : null,
              isAuthenticated: JSON.parse(broadCastData.isAuthenticated),
              lastLoginAt: JSON.parse(broadCastData.lastLoginAt || null),
            })
          );
        }
      }
    };

    channel.addEventListener('message', handleMessage);
    return () => {
      channel.removeEventListener('message', handleMessage);
    };
  }, [dispatch, navigate, tokenInfo?.accessToken]);
};

export default useBroadcastChannelSync;
