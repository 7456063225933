import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import dayjs from 'dayjs';
import * as yup from 'yup';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Alert from '@mui/material/Alert';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useRequestInterviewMutation } from '../../app/services/interview';
import { useRequestMonitorMutation } from '../../app/services/monitor';
import { useGetPatientQuery, useGetProvidersQuery } from '../../app/services/patient';
import { customMdBreakpoints } from '../../app/theme';
import { ToastContent, useToast } from '../../app/toast';
import CommonDatePicker from '../../common/components/CommonDatePicker';
import LocalPopupModal from '../../common/components/LocalPopupModal';
import MeasurementPacketsList from '../../common/components/MeasurementPacketsList';
import { commonButtonStyle, commonWidthResponsive } from '../../common/utils/commonStyles';
import { endDateValidation } from '../../common/utils/endDateValidation';
import { intervals } from '../../common/utils/intervalResource';
import { interviewsModeCode as MonitorsModeCode } from '../../common/utils/interviewsMode';
import { DefaultLanguage, LanguageType } from '../../common/utils/languages';
import { MonitorModeName } from '../../common/utils/monitorsMode';
import { isBefore } from '../../common/utils/treatmentPlans';
import {
  clearPatientInterviewData,
  setInterviewMode,
  setPatientInterviewData,
  setTrialInterview,
} from '../../features/interview/interviewSlice';
import {
  clearPatientMonitorData,
  selectLanguagesForMonitor,
  selectMonitorTypesData,
  setMonitorMode,
  setPatientMonitorData,
} from '../../features/monitor/monitorSlide';
import {
  addMeasurementPacket,
  removeMeasurementPacketList,
  selectAssessmentMode,
  selectMeasurementPacketList,
  selectPatientHasEmail,
  selectPatientSMSOptIn,
  setAssessmentMode,
} from '../../features/patient/patientSlice';
import { MeasurementPacket } from '../../types/InterviewRequest';
import { MonitorDataType, RequestMonitorType } from '../../types/MonitorTypes';
import { MonitorInfo } from '../../types/PatientRequest';
import { MeasurementPacketType } from '../../types/PatientType';
import { PopupContent } from '../../types/PopupType';
import { ResponseError } from '../../types/ResponseError';
import { Provider } from '../../types/UserTypes';
import { getRequestData } from '../interview/interviewUtils';
import { clearAssessmentCachedData } from './patientUtils';

const StyledMenuItem = styled(MenuItem)(() => ({
  maxWidth: 440,
  '&.MuiMenuItem-root': {
    whiteSpace: 'unset',
    lineBreak: 'strict',
    display: 'flex',
    alignItems: 'flex-start',
  },
}));

const contentPopup = (t: (arg0: string) => string) =>
  ({
    title: t('patient.patientDashboard.form.warningPopup.title'),
    description: t('patient.patientDashboard.form.warningPopup.description'),
    btnOk: t('patient.patientDashboard.form.warningPopup.btnOk'),
    btnClose: t('patient.patientDashboard.form.warningPopup.btnClose'),
  } as PopupContent);

interface Props {
  monitorTypeId: number;
  defaultLanguage: string;
}

const defaultLanguages = [DefaultLanguage.English as string, DefaultLanguage.Universal as string];

const getLanguageDefaultValue = (languages: LanguageType[]) => {
  const defaultEnglishAndUni = languages.filter((language: LanguageType) =>
    defaultLanguages.includes(language.code)
  );

  return defaultEnglishAndUni && defaultEnglishAndUni.length > 0
    ? DefaultLanguage.English
    : languages[0].code;
};

const getMonitorDefaultValue = (monitors: MonitorDataType[], languages: LanguageType[]) => {
  const defaultEnglishAndUni = monitors.filter((monitor: MonitorDataType) =>
    defaultLanguages.includes(monitor.language)
  );

  if (defaultEnglishAndUni.length > 0) {
    return defaultEnglishAndUni[0].id;
  }

  const defaultOtherLanguage = monitors.filter(
    (monitor: MonitorDataType) =>
      !defaultLanguages.includes(monitor.language) && monitor.language === languages[0].code
  );
  return defaultOtherLanguage[0].id;
};

const getMonitorToastMessages = (
  t: TFunction<'translation'>,
  toast: ToastContent,
  patientHasEmail?: boolean,
  patientSMSOptIn?: boolean
) => {
  if (patientHasEmail && patientSMSOptIn) {
    toast.publish(t('monitor.MonitorInstructionsPage.emailAndSMSSentSuccess'), 'success');
  } else {
    toast.publish(
      patientHasEmail
        ? t('monitor.MonitorInstructionsPage.emailSentSuccess')
        : t('monitor.MonitorInstructionsPage.smsSentSuccess'),
      'success'
    );
  }
};

export default function StartMonitorForm({ monitorTypeId, defaultLanguage }: Props) {
  const theme = useTheme();
  const toast = useToast();
  const ref = useRef<any>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { patientId } = useParams();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down(customMdBreakpoints));

  const [formHeight, setFormHeight] = useState(0);
  const [showPatientEmailForm, setShowPatientEmailForm] = useState<boolean>(false);
  const [showDateScheduleForm, setShowDateForm] = useState<boolean>(false);
  const [endDateError, setEndDateError] = useState<string>('');
  const [mode, setMode] = useState<string>(MonitorModeName.Clinician);
  const [monitorData, setMonitorData] = useState<MonitorDataType[]>([]);
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [providers, setProviders] = useState<Provider[]>([]);
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);

  const patientHasEmail = useAppSelector(selectPatientHasEmail);
  const patientSMSOptIn = useAppSelector(selectPatientSMSOptIn);
  const languages = useAppSelector(selectLanguagesForMonitor);
  const monitors = useAppSelector(selectMonitorTypesData);
  const measurementPacketList = useAppSelector(selectMeasurementPacketList);
  const assessmentModeAcrossComponent = useAppSelector(selectAssessmentMode);
  const monitorModes = t('patient.patientDashboard.interviewMode', { returnObjects: true });

  const [requestMonitor] = useRequestMonitorMutation();
  const [requestInterview] = useRequestInterviewMutation();

  const { data: providerData } = useGetProvidersQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );
  const { data: infoData } = useGetPatientQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  const validationSchema = yup
    .object({
      language: yup.string(),
      monitor: yup.string(),
      providerId: yup.string().required(t('patient.error.blankProvider')),
      monitorMode: yup.string(),
      interval: yup.string(),
      startDate: yup
        .date()
        .when('interval', {
          is: (interval: string) => parseInt(interval) !== 0,
          then: yup.date().required(t('patient.error.blankStartDate')),
        })
        .test('start-date', t('interview.form.errors.validStartDate'), function (startDate) {
          const endDate: Date = getValues('endDate');
          if (endDate && startDate) {
            return !isBefore(endDate, startDate);
          }
          return true;
        }),
      endDate: yup
        .date()
        .typeError(`${t('monitor.form.errors.invalidEndDate')}`)
        .test('end-date', '', function (endDate) {
          const monitorMode = getValues('monitorMode');
          const interval = getValues('interval');
          const startDate = getValues('startDate');
          if (endDate) {
            const suggestDate = endDateValidation({
              assessmentMode: monitorMode,
              interval: parseInt(interval),
              timezone: infoData?.timezone,
              startDate,
              endDate,
            });

            if (suggestDate) {
              setEndDateError(`${t('monitor.form.errors.validEndDate')} ${suggestDate}`);
              return false;
            }
          }
          setEndDateError('');
          return true;
        })
        .nullable(),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    resetField,
  } = useForm<MonitorInfo>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      patientId,
      language: languages && languages.length > 0 && getLanguageDefaultValue(languages),
      monitor: monitors && monitors.length > 0 && getMonitorDefaultValue(monitors, languages),
      monitorMode: assessmentModeAcrossComponent || Object.keys(monitorModes)[0],
      interval: '0',
      startDate: dayjs(),
      endDate: null,
      providerId: undefined,
    },
  });

  const resetData = () => {
    resetField('startDate');
    resetField('endDate');
    resetField('interval');
    resetField('language');
    setValue('monitorMode', Object.keys(monitorModes)[0]);
    setMode(Object.keys(monitorModes)[0]);
    dispatch(setAssessmentMode({ mode: Object.keys(monitorModes)[0] }));
    setShowDateForm(false);
    setIsOpenPopup(false);
    setShowPatientEmailForm(false);

    const renderMonitor = monitors.filter(
      (monitor: MonitorDataType) =>
        (monitor.language === DefaultLanguage.Universal &&
          getLanguageDefaultValue(languages) === DefaultLanguage.English) ||
        monitor.language === getLanguageDefaultValue(languages)
    );
    setMonitorData(renderMonitor);
    setValue('monitor', renderMonitor[0].id);
    setValue('providerId', providers[0].id);
    if (providerData && providerData.defaultProviderId) {
      setValue('providerId', providerData.defaultProviderId);
    }
    setPageErrors([]);
  };

  const onRemoveMeasurementPacketList = () => {
    dispatch(removeMeasurementPacketList());
    resetData();
  };

  const onClosePopup = () => {
    setIsOpenPopup(false);
  };

  const showWarningPopup = () => {
    setIsOpenPopup(true);
  };

  const handleMonitorModeChange = (e: any) => {
    const modeValue = e.target.value;

    resetField('interval');
    resetField('endDate');
    setValue('monitorMode', modeValue);
    setShowPatientEmailForm(false);
    setShowDateForm(false);
    dispatch(setAssessmentMode({ mode: modeValue }));
    setValue(
      'monitor',
      monitorData[0]?.id.toString() || getMonitorDefaultValue(monitors, languages)?.toString()
    );

    if (modeValue === MonitorModeName.Email) {
      setShowPatientEmailForm(true);
    }
  };

  const handleIntervalChange = (e: any) => {
    const intervalValue = e.target.value;
    if (intervalValue !== 0) {
      setShowDateForm(true);
    } else {
      setShowDateForm(false);
    }
    setEndDateError('');
    resetField('endDate');
    setValue('interval', intervalValue);
  };

  const renderMonitorModes = () => {
    return Object.keys(monitorModes)
      .filter((mode: string) => {
        if (mode === MonitorModeName.Email && !patientHasEmail && !patientSMSOptIn) {
          return false;
        }
        return true;
      })
      .map((mode: string) => {
        let label = monitorModes[mode];
        if (mode === MonitorModeName.Email && patientSMSOptIn) {
          label = t(
            `patient.patientDashboard.interviewSMSMode.${patientHasEmail ? 'emailAndSMS' : 'sms'}`
          );
        }

        return (
          <FormControlLabel
            disabled={measurementPacketList.length > 0}
            key={mode}
            value={mode}
            control={<Radio />}
            label={label}
          />
        );
      });
  };

  const renderIntervals = () => {
    return intervals.map((interval, index) => (
      <StyledMenuItem key={interval} value={index}>
        {interval}
      </StyledMenuItem>
    ));
  };

  const renderMonitors = () => {
    let monitorsForRender = [];
    if (monitorData && monitorData.length > 0) {
      monitorsForRender = monitorData;
    }

    if (!monitorData || monitorData.length === 0) {
      const monitorHaveEnglishAndUni = monitors.find((monitor: MonitorDataType) =>
        defaultLanguages.includes(monitor.language)
      );

      if (monitorHaveEnglishAndUni) {
        monitorsForRender = monitors.filter((monitor: MonitorDataType) =>
          defaultLanguages.includes(monitor.language)
        );
      } else {
        monitorsForRender = monitors.filter(
          (monitor: MonitorDataType) => monitor.language === languages[0]?.code
        );
      }
    }

    if ((!monitorData || monitorData.length === 0) && (!monitors || monitors.length === 0)) {
      return [];
    }

    return monitorsForRender
      .sort((a: MonitorDataType, b: MonitorDataType) => a.order - b.order)
      .map((monitor: MonitorDataType) => (
        <StyledMenuItem key={monitor?.id} value={monitor?.id}>
          {monitor.name}
        </StyledMenuItem>
      ));
  };

  const renderLanguages = () => {
    return languages.map((language: LanguageType) => (
      <StyledMenuItem key={language.code} value={language.code}>
        {language.name}
      </StyledMenuItem>
    ));
  };

  const checkHasMonitors = (error: string) => {
    if (!monitors || monitors.length < 1) {
      return (
        <Alert key={error} severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      );
    }
  };

  const bindDataByMonitor = useCallback(
    (monitorTypeId: number) => {
      const monitorItem = monitors.find((monitor: MonitorDataType) => monitor.id === monitorTypeId);
      if (monitorItem) {
        setValue('monitor', monitorItem.id);
      }
      resetField('interval');
      resetField('endDate');
      setShowDateForm(false);
      setEndDateError('');
    },
    [monitors, resetField, setValue]
  );

  useEffect(() => {
    if (monitors && monitors.length > 0) {
      // in monitor type id exists in query string -> pre-populate it
      if (monitorTypeId) {
        // delay to ensure monitor data are ready
        setTimeout(() => {
          bindDataByMonitor(monitorTypeId);
        }, 500);
      } else {
        bindDataByMonitor(monitors[0]?.monitorTestId);
      }
      setPageErrors([]);
    }
  }, [setValue, dispatch, monitors, bindDataByMonitor, monitorTypeId]);

  useEffect(() => {
    if (providerData) {
      const sortProviders = [...(providerData.providers || [])].sort(
        (a: Provider, b: Provider) =>
          a.lastName?.localeCompare(b.lastName) || a.firstName?.localeCompare(b.firstName)
      );

      if (sortProviders.length > 0) {
        setProviders(sortProviders);
        setValue('providerId', sortProviders[0].id);

        if (providerData.defaultProviderId) {
          setValue('providerId', providerData.defaultProviderId);
        }
      }
    }
  }, [setValue, providerData]);

  useEffect(() => {
    if (assessmentModeAcrossComponent) {
      if (assessmentModeAcrossComponent === MonitorModeName.Email) {
        setShowPatientEmailForm(true);
      }
      setValue('monitorMode', assessmentModeAcrossComponent);
      setMode(assessmentModeAcrossComponent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentModeAcrossComponent]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (ref.current) {
      setFormHeight(ref.current.clientHeight);
    }
  });

  const getProviderOptionLabel = (option: any) => `${option.lastName}, ${option.firstName}`;
  const filterProviderOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getProviderOptionLabel(option),
  });

  const handleProviderChange = (e: any, values: any) => {
    const selectedState = values ? values.id : '';
    setValue('providerId', selectedState);
  };

  const handleLanguageChange = useCallback(
    (e?: any): void => {
      const selectedLanguage = e?.target?.value || defaultLanguage || DefaultLanguage.English;

      const renderMonitor = monitors.filter(
        (monitor: MonitorDataType) =>
          (monitor.language === DefaultLanguage.Universal &&
            selectedLanguage === DefaultLanguage.English) ||
          monitor.language === selectedLanguage
      );
      if (!renderMonitor?.length) {
        return;
      }
      setValue('monitor', renderMonitor[0].id);
      setValue('language', selectedLanguage);
      setValue('providerId', providers[0]?.id);
      if (providerData && providerData.defaultProviderId) {
        setValue('providerId', providerData.defaultProviderId);
      }
      setMonitorData(renderMonitor);
    },
    [defaultLanguage, monitors, providerData, providers, setValue]
  );

  useEffect(() => {
    handleLanguageChange();
  }, [defaultLanguage, handleLanguageChange]);

  const handleMonitorChange = (e: any): void => {
    const interviewTypeId = e.target.value;
    bindDataByMonitor(interviewTypeId);
  };

  const handleSubmitOption = async (data: MonitorInfo) => {
    const { monitor, language, patientId, monitorMode, interval, startDate, endDate, providerId } =
      data;

    if (measurementPacketList && measurementPacketList[0]?.trialInterview) {
      dispatch(setTrialInterview({ trialInterview: measurementPacketList[0].trialInterview }));
    }

    let scheduleMonitorData: RequestMonitorType = {
      language,
      nViewPatientId: patientId,
      monitorId: parseInt(monitor),
      monitorMode: MonitorsModeCode[monitorMode].modeCode,
      emailMonitor: MonitorsModeCode[monitorMode].emailCode,
      isFromFollowUp: monitorTypeId === parseInt(monitor) ? true : false,
      providerId,
      measurementPacketType: MeasurementPacketType.MeasurementPacket,
    };

    if (monitorMode !== MonitorModeName.Clinician && monitorMode !== MonitorModeName.Patient) {
      scheduleMonitorData = {
        ...scheduleMonitorData,
        interval: parseInt(interval),
      };
    }

    if (parseInt(interval) > 0) {
      scheduleMonitorData = {
        ...scheduleMonitorData,
        startDate,
        endDate,
      };
    }

    let requestData = null;

    if (measurementPacketList?.length) {
      requestData = getRequestData(measurementPacketList);
      if (requestData.monitorRequestData) {
        requestData.monitorRequestData = {
          ...scheduleMonitorData,
          ...requestData.monitorRequestData,
        };
      } else if (requestData.interviewRequestData) {
        // These properties are the same for interview and monitor
        requestData.interviewRequestData.interviewMode = scheduleMonitorData.monitorMode;
        requestData.interviewRequestData.emailInterview = scheduleMonitorData.emailMonitor;
        requestData.interviewRequestData.nViewPatientId = scheduleMonitorData.nViewPatientId;
      }
    } else {
      requestData = { monitorRequestData: scheduleMonitorData };
    }

    try {
      let res = null;
      if (requestData.interviewRequestData) {
        res = await requestInterview(requestData.interviewRequestData).unwrap();
      } else {
        res = await requestMonitor(requestData.monitorRequestData).unwrap();
      }

      if (res && res.monitorTestId) {
        dispatch(clearPatientInterviewData());
        dispatch(setMonitorMode({ monitorMode }));
        dispatch(
          setPatientMonitorData({
            data: {
              monitorTestId: res.monitorTestId!,
              accessToken: res.patientMonitorToken ? res.patientMonitorToken : '',
              lastActivityTs: Date.now(),
            },
          })
        );
      } else if (res && res.interviewTestId) {
        dispatch(clearPatientMonitorData());
        dispatch(
          setInterviewMode({ interviewMode: monitorMode, reportType: res.interview?.reportType })
        );
        dispatch(
          setPatientInterviewData({
            data: {
              interviewTestId: res.interviewTestId,
              accessToken: res.patientInterviewToken ? res.patientInterviewToken : '',
              lastActivityTs: Date.now(),
            },
          })
        );
      }
      dispatch(removeMeasurementPacketList());
      dispatch(setAssessmentMode({ mode: MonitorModeName.Clinician }));
      setPageErrors([]);
      return res;
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  const onSubmit = async (data: MonitorInfo) => {
    const monitorResult = await handleSubmitOption(data);
    clearAssessmentCachedData();

    if (monitorResult?.monitorTestId) {
      if (data.monitorMode === MonitorModeName.Email && monitorResult) {
        getMonitorToastMessages(t, toast, patientHasEmail, patientSMSOptIn);
        resetData();
      }

      if (data.monitorMode !== MonitorModeName.Email && monitorResult) {
        navigate(`/dashboard/patient/${patientId}/${monitorResult?.monitorTestId}/start-monitor`, {
          state: { resumeMonitor: false },
        });
      }
    } else if (monitorResult?.interviewTestId) {
      if (data.monitorMode === MonitorModeName.Email && monitorResult) {
        getMonitorToastMessages(t, toast, patientHasEmail, patientSMSOptIn);
      }
      if (data.monitorMode !== MonitorModeName.Email && monitorResult) {
        navigate(
          `/dashboard/patient/${patientId}/${monitorResult?.interviewTestId}/start-interview`,
          { state: { resumeInterview: false } }
        );
      }
    }
  };

  const onAddToPacket = async (data: MonitorInfo) => {
    const { monitor, language, interval, startDate, endDate, providerId } = data;

    const measurementPacket: MeasurementPacket = {
      language,
      monitorId: parseInt(monitor),
      isFromFollowUp: monitorTypeId === parseInt(monitor) ? true : false,
      providerId,
      interval,
      startDate,
      endDate,
      order: measurementPacketList.length,
    };

    const existMeasurementPacket = measurementPacketList.some(
      (item: MeasurementPacket) => item.monitorId === measurementPacket.monitorId
    );

    if (existMeasurementPacket) {
      setPageErrors([t('interview.form.errors.packetAlreadyExists')]);
    } else {
      dispatch(addMeasurementPacket({ measurementPacket }));
      setPageErrors([]);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        py: 4,
        px: matches ? 4 : 10,
        display: 'flex',
        flexDirection: 'column',
        columnGap: 2,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <Box ref={ref} sx={{ width: commonWidthResponsive(matches), px: 2 }}>
          <Box
            sx={{
              columnGap: 1,
              width: '100%',
            }}
          >
            <Typography fontWeight={600}>{t('monitor.form.monitorMode')}</Typography>
            <Controller
              name="monitorMode"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  onChange={handleMonitorModeChange}
                  aria-label="monitorModes"
                  name="radio-buttons-group"
                >
                  {renderMonitorModes()}
                </RadioGroup>
              )}
            />
            {languages && languages.length > 0 && (
              <Controller
                name="language"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.language}
                    helperText={errors.language?.message}
                    margin="normal"
                    select
                    fullWidth
                    label={t('patient.patientDashboard.form.language')}
                    variant="outlined"
                    onChange={handleLanguageChange}
                  >
                    {renderLanguages()}
                  </TextField>
                )}
              />
            )}
            {monitors.length !== 0 && (
              <Controller
                name="monitor"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.monitor}
                    helperText={errors.monitor?.message}
                    margin="normal"
                    onChange={handleMonitorChange}
                    select
                    fullWidth
                    label={t('monitor.form.monitorCondition')}
                    variant="outlined"
                  >
                    {renderMonitors()}
                  </TextField>
                )}
              />
            )}
            <Controller
              name="providerId"
              control={control}
              render={({ fieldState, formState, field: { value }, ...props }) => (
                <Autocomplete
                  {...props}
                  data-testid="mui-component-select-provider"
                  options={providers || []}
                  getOptionLabel={getProviderOptionLabel}
                  value={value ? providers?.find((item) => item.id === value) || null : null}
                  filterOptions={filterProviderOptions}
                  onChange={handleProviderChange}
                  renderOption={(props, option: any) => {
                    return (
                      <li {...props} key={option.id} value={option.id}>
                        {getProviderOptionLabel(option)}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.providerId}
                      helperText={errors.providerId?.message}
                      margin="normal"
                      fullWidth
                      label={t('patient.patientInfo.provider')}
                    />
                  )}
                />
              )}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            {showPatientEmailForm && (
              <Box sx={{ width: '100%' }}>
                <Controller
                  name="interval"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.interval}
                      helperText={errors.interval?.message}
                      margin="normal"
                      select
                      fullWidth
                      label={t('monitor.form.interval')}
                      variant="outlined"
                      onChange={handleIntervalChange}
                    >
                      {renderIntervals()}
                    </TextField>
                  )}
                />
                {showDateScheduleForm && (
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CommonDatePicker
                        name="startDate"
                        disablePast
                        control={control}
                        error={!!errors.startDate}
                        helperText={errors.startDate?.message}
                        label={t('interview.form.startDate')}
                        timezone={infoData?.timezone}
                        hideClearButton
                      />
                      <CommonDatePicker
                        name="endDate"
                        disablePast
                        control={control}
                        error={!!endDateError || !!errors.endDate}
                        helperText={endDateError || errors.endDate?.message}
                        label={t('interview.form.endDate')}
                        timezone={infoData?.timezone}
                      />
                    </LocalizationProvider>
                  </>
                )}
              </Box>
            )}
          </Box>
        </Box>

        {measurementPacketList?.length > 0 && !matches && (
          <Box
            sx={{
              width: '50%',
            }}
          >
            <MeasurementPacketsList
              items={measurementPacketList}
              providers={providers}
              formHeight={formHeight}
            />
          </Box>
        )}
      </Box>

      <Box sx={{ mx: 2 }}>
        {!matches && <Divider sx={{ my: 3 }} />}
        {pageErrors.length > 0 &&
          pageErrors.map((error) => (
            <Alert key={error} severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          ))}
        {checkHasMonitors(t('patient.error.blankMonitor'))}
      </Box>
      <Box sx={{ m: 2 }}>
        <Button
          onClick={handleSubmit(onAddToPacket)}
          variant="contained"
          disabled={
            mode === MonitorModeName.Clinician ||
            measurementPacketList.length > 9 ||
            monitors.length === 0
          }
          sx={{
            ...commonButtonStyle(matches),
            mr: 2,
          }}
        >
          {t('patient.patientDashboard.form.addToPacket')}
        </Button>
        <Button
          onClick={showWarningPopup}
          variant="contained"
          disabled={measurementPacketList.length === 0 || monitors.length === 0}
          sx={{
            ...commonButtonStyle(matches),
          }}
        >
          {t('patient.patientDashboard.form.deletePacket')}
        </Button>

        {measurementPacketList?.length > 0 && matches && (
          <Box sx={{ width: '100%' }}>
            <MeasurementPacketsList items={measurementPacketList} providers={providers} />
          </Box>
        )}

        <Button
          type="submit"
          variant="contained"
          disabled={monitors.length === 0}
          sx={{
            ...commonButtonStyle(matches),
            float: 'right',
          }}
        >
          {t('patient.patientDashboard.form.submit')}
        </Button>
      </Box>

      <LocalPopupModal
        isOpen={isOpenPopup}
        contentPopup={contentPopup(t)}
        onSuccess={onRemoveMeasurementPacketList}
        onClose={onClosePopup}
      />
    </Box>
  );
}
