import { forwardRef, useEffect, useRef, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import ImageListItem from '@mui/material/ImageListItem';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/system/Box';
import { useAppSelector } from '../../app/hooks';
import { useGetAccountLogoQuery } from '../../app/services/account';
import { selectIsAuthenticated, selectIsFromIframe } from '../../features/auth/authSlice';
import { roundedSquareButtonStyle } from '../utils/commonStyles';
import Logo from './Logo';
import Navigation from './Navigation';

interface HeaderBarProps {
  toggleNavigation: () => void;
  showMenu: boolean;
  logo?: string;
  isLoadingLogo?: boolean;
}

interface HeaderProps {
  accountLogo?: string;
}

const HeaderBar = forwardRef<HTMLDivElement, HeaderBarProps>(
  ({ toggleNavigation, showMenu, logo, isLoadingLogo }, ref) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xl'));
    const [y, setY] = useState(window.scrollY);
    const [headerPosition, setHeaderPosition] = useState<boolean>(false);
    const isFromIframe = useAppSelector(selectIsFromIframe);

    const handleScroll = useCallback(
      (e) => {
        const window = e.currentTarget;
        // Check scroll up or down
        if (y > window.scrollY) {
          setHeaderPosition(true);
        } else if (y < window.scrollY) {
          setHeaderPosition(false);
        }
        setY(window.scrollY);
      },
      [y]
    );

    useEffect(() => {
      setY(window.scrollY);
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [handleScroll]);

    return (
      <Box
        sx={{
          flexGrow: 1,
          pb: matches ? (headerPosition ? 8 : 0) : 8,
        }}
      >
        <AppBar
          ref={ref}
          sx={{
            bgcolor: theme.xPalette.background,
            color: theme.palette.text.primary,
            boxShadow: 0,
            position: matches ? (headerPosition ? 'fixed' : 'static') : 'absolute',
            top: matches ? (headerPosition ? 0 : '-100px') : 0,
            left: 0,
            right: 0,
            transition: 'all .6s ease',
            zIndex: 9,
          }}
        >
          <Container maxWidth="xl" disableGutters>
            <Toolbar>
              <Box sx={{ flexGrow: 1 }}>
                <Link to={isFromIframe ? '' : '/'}>
                  {logo ? (
                    <ImageListItem
                      sx={{
                        maxWidth: '130px',
                        height: '50px !important',
                        '&.MuiImageListItem-root .MuiImageListItem-img': {
                          objectFit: 'contain',
                        },
                      }}
                    >
                      <img
                        src={`${process.env.REACT_APP_ACCOUNT_BASE_URL}/${logo}`}
                        alt={'company_logo'}
                        loading="eager"
                      />
                    </ImageListItem>
                  ) : !isLoadingLogo ? (
                    <Logo width={130} />
                  ) : (
                    <></>
                  )}
                </Link>
              </Box>
              <Box>
                {showMenu && (
                  <IconButton
                    size="medium"
                    color="inherit"
                    aria-label="menu"
                    sx={roundedSquareButtonStyle}
                    onClick={toggleNavigation}
                  >
                    <MenuIcon />
                  </IconButton>
                )}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
    );
  }
);

export default function Header({ accountLogo }: HeaderProps) {
  const [navigationShown, setNavigationShown] = useState<boolean>(false);
  const appBarRef = useRef(null);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const { data, isLoading } = useGetAccountLogoQuery();

  const toggleNavigation = () => {
    setNavigationShown(!navigationShown);
  };

  return (
    <>
      <HeaderBar
        toggleNavigation={toggleNavigation}
        showMenu={isAuthenticated}
        logo={data?.accountLogo || accountLogo}
        isLoadingLogo={isLoading}
        ref={appBarRef}
      />
      <Navigation open={navigationShown} toggle={setNavigationShown} />
    </>
  );
}
