import { ChangeEvent, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { QuestionAnswerType } from '../../../common/utils/questionAnswerTypes';
import { InterviewQuestionProps } from '../../../types/InterviewQuestionTypes';
import useSetWatchQuestion from '../hooks/useSetWatchQuestion';

const StyledTextField = styled(TextField)(() => ({
  margin: '10px 0',
  width: '100%',
}));

const StyledTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  marginBottom: '8px',
}));

export default function OptionsQuestion({
  question,
  field,
  error,
  helperText,
  setValue,
  optionChangeCallback,
  sequenceOrder,
}: InterviewQuestionProps) {
  const optionRef = useRef<HTMLDivElement>(null);
  const initialLoad = useRef<boolean>(true);
  const savedData = field?.value;

  const { handleOnBlur } = useSetWatchQuestion({
    name: field?.name!,
    type: QuestionAnswerType.Options,
    required: !!question.isRequired,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;
    setValue(field?.name!, value);
    if (optionChangeCallback) {
      // set DOM attribute
      const selectedIndex = question.possibleAnswers.indexOf(event.target.value);
      optionRef.current?.setAttribute('data-selected-index', '' + (selectedIndex + 1)); // default option is in 1st position so need to adding 1
      // execute parent callback
      optionChangeCallback();
    }

    handleOnBlur();
  };

  const renderItems = () => {
    const { possibleAnswers, displayAnswers } = question;
    const items = [];
    for (let i = 0; i < possibleAnswers.length; i++) {
      const value = possibleAnswers[i];
      const text = displayAnswers?.length === possibleAnswers?.length
        ? `${displayAnswers?.[i] || value}`.trim()
        : value.trim();
      items.push(
        <MenuItem
          sx={{ whiteSpace: 'unset' }}
          key={value}
          value={value}
          data-selected-index={i + 1}
        >
          {text}
        </MenuItem>
      );
    }
    return items;
  };

  useEffect(() => {
    if (savedData && savedData !== '') {
      if (initialLoad.current) {
        initialLoad.current = false;
        setValue(field?.name!, savedData as string);
        if (optionChangeCallback) {
          // set DOM attribute
          const selectedIndex = question.possibleAnswers.indexOf(savedData);

          optionRef.current?.setAttribute('data-selected-index', '' + (selectedIndex + 1)); // default option is in 1st position so need to adding 1
          // execute parent callback
          optionChangeCallback();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field?.name, question.possibleAnswers, savedData, setValue]);

  return (
    <Box
      className={
        question.clientDisplayCondition
          ? 'js-has-client-display-condition'
          : 'js-question-answer-options'
      }
      ref={optionRef}
    >
      {question.questionPrompt && (
        <StyledTypography
          dangerouslySetInnerHTML={{
            __html: question.questionPrompt,
          }}
        ></StyledTypography>
      )}
      <Typography
        dangerouslySetInnerHTML={{
          __html: question.questionText!,
        }}
      ></Typography>
      <StyledTextField
        {...field}
        className={`sequence-order-${sequenceOrder}`}
        error={error}
        helperText={helperText}
        select
        SelectProps={{ displayEmpty: true }}
        onChange={handleChange}
      >
        <MenuItem key="NONE" value="">
          {' '}
        </MenuItem>
        {renderItems()}
      </StyledTextField>
    </Box>
  );
}
