import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../app/hooks';
import { useGetPatientStatsQuery } from '../../app/services/account';
import { useGetInterviewStatsQuery } from '../../app/services/interview';
import { useGetMonitorStatsQuery } from '../../app/services/monitor';
import store, { RootState } from '../../app/store';
import { ReactComponent as AddPatientSvgIcon } from '../../assets/icons/add-patient.svg';
import { ReactComponent as EyeSvgIcon } from '../../assets/icons/eye.svg';
import { ReactComponent as IncompleteInterviewsSvgIcon } from '../../assets/icons/incomplete-interviews.svg';
import { ReactComponent as IncompleteMonitorsSvgIcon } from '../../assets/icons/incomplete-monitors.svg';
import { ReactComponent as PatientsSvgIcon } from '../../assets/icons/patients.svg';
import { ReactComponent as SuicidalPatientsSvgIcon } from '../../assets/icons/suicidal-patients.svg';
import { ReactComponent as TotalInterviewsSvgIcon } from '../../assets/icons/total-interviews.svg';
import { ReactComponent as TotalMonitorsSvgIcon } from '../../assets/icons/total-monitors.svg';
import ChartIcon from '../../assets/images/chart.png';
import PatientSearch from '../../common/components/PatientSearch';
import DashboardLayout from '../../common/layouts/Dashboard';
import { Role } from '../../common/utils/loginRole';
import { isQuickSightReportEnabled } from '../../common/utils/permissionUtils';
import { PatientStatsRequest } from '../../types/DashboardRequest';
import { PageProps } from '../../types/PageProps';
import { selectUser } from '../auth/authSlice';

interface IconTextProps {
  icon?: JSX.Element | undefined;
  text: string | undefined;
  total?: number | undefined;
  color?: string | undefined;
  backgroundColor: string | undefined;
  marginTop?: string | 'auto';
  disabled?: boolean;
  toggleNavigation?: () => void;
  isLoading?: boolean;
}

const statFontStyle = (theme: Theme) => ({
  fontSize: '32px',
  fontWeight: 600,

  [theme.breakpoints.up('md')]: {
    fontSize: '34px',
  },
});

const AddIcon = () => <AddPatientSvgIcon />;
const EyeIcon = () => <EyeSvgIcon />;
const PatientsIcon = () => <PatientsSvgIcon />;
const SuicidalPatientsIcon = () => <SuicidalPatientsSvgIcon />;
const TotalInterviewsIcon = () => <TotalInterviewsSvgIcon />;
const IncompleteInterviewsIcon = () => <IncompleteInterviewsSvgIcon />;
const IncompleteMonitorsIcon = () => <IncompleteMonitorsSvgIcon />;
const TotalMonitorsIcon = () => <TotalMonitorsSvgIcon />;

export default function DashboardPage({ breadcrumbs }: PageProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('md'));
  const currentUser = useAppSelector(selectUser);
  const rootState = store.getState() as RootState;
  const clinicaAccountId = rootState?.auth?.user?.clinicAccountId;
  const { data: patientStatistics, isLoading: isPatientLoading } = useGetPatientStatsQuery({
    clinicaAccountId,
  } as PatientStatsRequest);
  const { data: interviewStatistics, isLoading: isInterviewLoading } = useGetInterviewStatsQuery();
  const { data: monitorStatistics, isLoading: isMonitorLoading } = useGetMonitorStatsQuery();
  const showManagementReport =
    isQuickSightReportEnabled &&
    currentUser.showManagementReport &&
    (currentUser.roleId === Role.SuperAdmin || currentUser.roleId === Role.ClinicAdmin);
  const displayStatistics = (isLoading?: boolean, value?: number) => {
    return isLoading ? (
      <>
        <CircularProgress />
      </>
    ) : value === undefined ? (
      '-'
    ) : (
      value.toLocaleString()
    );
  };

  const IconBox = (icon: JSX.Element) => {
    return (
      <Box
        sx={{
          height: '34px',
          position: 'relative',
          width: '34px',
        }}
      >
        <Box
          sx={{
            background: '#FFFFFF',
            borderRadius: '5px',
            height: 1,
            opacity: '0.2',
            position: 'absolute',
            width: 1,
          }}
        ></Box>
        {icon}
      </Box>
    );
  };

  const IconBoxPatientsSummary = (icon: JSX.Element, backgroundColor: string) => {
    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: '42px',
          justifyContent: 'center',
          marginRight: '22px',
          width: '42px',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            background: `${backgroundColor}`,
            borderRadius: '21px',
            height: '42px',
            opacity: '0.1',
            position: 'absolute',
            width: '42px',
          }}
        ></Box>
        {icon}
      </Box>
    );
  };

  const IconBoxInterviewSummary = (icon: JSX.Element) => {
    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: '32px',
          justifyContent: 'center',
          width: '32px',
        }}
      >
        {icon}
      </Box>
    );
  };

  const IconAndTextBox = ({ icon, text, backgroundColor, toggleNavigation }: IconTextProps) => {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '48%',
        }}
      >
        <Button
          type="button"
          fullWidth
          variant="contained"
          startIcon={icon}
          sx={{
            my: 2,
            py: 2,
            textTransform: 'none',
            backgroundColor: { backgroundColor },
            '&:hover': {
              backgroundColor: { backgroundColor },
            },
          }}
          onClick={toggleNavigation}
        >
          {text}
        </Button>
      </Box>
    );
  };

  const PatientSummaryBox = ({
    icon,
    text,
    total,
    color,
    backgroundColor,
    marginTop,
    disabled,
    toggleNavigation,
    isLoading,
  }: IconTextProps) => {
    return (
      <Box
        sx={{
          display: 'flex',
          marginTop: isPhone ? { marginTop } : 0,
          width: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: 1,
          }}
        >
          <Button
            type="button"
            fullWidth
            variant="contained"
            disableRipple={disabled}
            sx={{
              borderRadius: '8px',
              color: '#000',
              height: '75px',
              py: 2,
              textTransform: 'none',
              backgroundColor: { backgroundColor },
              '&:hover': {
                backgroundColor: { backgroundColor },
              },
              cursor: disabled ? 'default' : 'pointer',
            }}
            onClick={toggleNavigation}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                marginLeft: '11px',
                marginRight: '11px',
                width: 1,
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  width: 1,
                }}
              >
                {icon}
                <Typography
                  sx={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {text}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '50%',
                }}
              >
                <Typography
                  sx={{
                    color: { color },
                    ...statFontStyle(theme),
                  }}
                >
                  {displayStatistics(isLoading, total)}
                </Typography>
              </Box>
            </Box>
          </Button>
        </Box>
      </Box>
    );
  };

  const ManagementReportLinkBox = ({
    text,
    backgroundColor,
    marginTop,
    disabled,
    toggleNavigation,
  }: IconTextProps) => {
    return (
      <Box
        sx={{
          display: 'flex',
          marginTop: isPhone ? { marginTop } : 0,
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: 1,
          }}
        >
          <Button
            type="button"
            fullWidth
            variant="contained"
            disableRipple={disabled}
            sx={{
              borderRadius: '8px',
              color: '#000',
              height: '75px',
              py: 2,
              textTransform: 'none',
              backgroundColor: { backgroundColor },
              '&:hover': {
                backgroundColor: { backgroundColor },
              },
              cursor: disabled ? 'default' : 'pointer',
            }}
            onClick={toggleNavigation}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                marginLeft: '11px',
                marginRight: '11px',
                width: 1,
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  width: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: '42px',
                    height: '42px',
                    marginRight: '20px',
                    borderRadius: '50%',
                    background: '#8fde584f',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img width="22px" height="22px" src={ChartIcon} alt="chart icon" />
                </Box>
                <Typography
                  sx={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {text}
                </Typography>
              </Box>
            </Box>
          </Button>
        </Box>
      </Box>
    );
  };

  const InterviewSummaryBox = ({
    icon,
    text,
    total,
    backgroundColor,
    marginTop,
    toggleNavigation,
    isLoading,
  }: IconTextProps) => {
    return (
      <Box
        sx={{
          display: 'flex',
          marginTop: { marginTop },
          width: isPhone ? '48%' : '23%',
        }}
      >
        <Button
          type="button"
          fullWidth
          variant="contained"
          disableRipple
          sx={{
            borderRadius: '8px',
            color: '#000',
            height: '75px',
            py: 2,
            textTransform: 'none',
            backgroundColor: { backgroundColor },
            '&:hover': {
              backgroundColor: { backgroundColor },
            },
            '&.MuiButton-root': {
              minHeight: '167px',
              minWidth: '167px',
            },
            cursor: 'default',
          }}
          onClick={toggleNavigation}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              width: 1,
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: 1,
              }}
            >
              {icon}
              <Typography
                sx={{
                  fontSize: '13px',
                  mt: 2,
                  whiteSpace: 'nowrap',
                  [theme.breakpoints.up('md')]: {
                    fontSize: '15px',
                  },
                }}
              >
                {text}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 1,
                width: 1,
              }}
            >
              <Typography sx={statFontStyle(theme)}>
                {displayStatistics(isLoading, total)}
              </Typography>
            </Box>
          </Box>
        </Button>
      </Box>
    );
  };

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{t('dashboard.title')}</title>
      </Helmet>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Typography
            ml={1}
            variant="h1"
            sx={{
              marginLeft: 0,
            }}
          >
            {t('dashboard.title')}
          </Typography>
        </Box>
      </Container>
      <Container
        maxWidth="xl"
        sx={{
          alignItems: 'center',
          backgroundColor: theme.xPalette.white,
          borderRadius: '15px',
          display: 'flex',
          flexDirection: isPhone ? 'column' : 'row',
          paddingTop: '6px',
          paddingBottom: '6px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            my: 2,
            width: isPhone ? 1 : 1 / 2,
          }}
        >
          <PatientSearch clinicaAccountId={clinicaAccountId} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            ml: isPhone ? 0 : 3,
            width: isPhone ? 1 : 1 / 2,
          }}
        >
          <IconAndTextBox
            icon={IconBox(AddIcon())}
            text={t('patient.addPatient.title')}
            backgroundColor={theme.palette.secondary.main}
            toggleNavigation={() => {
              navigate('add-patient');
            }}
          />
          <IconAndTextBox
            icon={IconBox(EyeIcon())}
            text={t('patient.viewPatient.title')}
            backgroundColor={theme.palette.primary.main}
            toggleNavigation={() => {
              navigate('/dashboard/patients');
            }}
          />
        </Box>
      </Container>
      <Container
        maxWidth="xl"
        sx={{
          backgroundColor: theme.xPalette.background,
          borderRadius: '15px',
          display: 'flex',
          marginTop: '20px',
          flexDirection: isPhone ? 'column' : 'row',
          gap: isPhone ? 0 : '20px',
          justifyContent: 'space-between',
          py: 2.75,
          width: 1,
        }}
      >
        <PatientSummaryBox
          icon={IconBoxPatientsSummary(PatientsIcon(), theme.palette.primary.main)}
          text={t('dashboard.accountStats.totalPatients.title')}
          total={patientStatistics?.totalPatients}
          color={theme.palette.primary.main}
          backgroundColor={theme.xPalette.white}
          toggleNavigation={() => {
            navigate('/dashboard/patients');
          }}
          isLoading={isPatientLoading}
        />
        <PatientSummaryBox
          icon={IconBoxPatientsSummary(SuicidalPatientsIcon(), theme.palette.error.main)}
          text={t('dashboard.accountStats.suicidalPatients.title')}
          total={patientStatistics?.suicidalPatients}
          color={theme.palette.error.main}
          backgroundColor={theme.xPalette.white}
          marginTop="10px"
          toggleNavigation={() => {
            navigate('/dashboard/suicide-alerts');
          }}
          isLoading={isPatientLoading}
        />
      </Container>
      {showManagementReport && (
        <Container
          maxWidth="xl"
          sx={{
            backgroundColor: theme.xPalette.background,
            borderRadius: '15px',
            display: 'flex',
            marginTop: '20px',
            flexDirection: isPhone ? 'column' : 'row',
            gap: isPhone ? 0 : '20px',
            justifyContent: 'center',
            py: 2.75,
            width: 1,
          }}
        >
          <ManagementReportLinkBox
            text={t('managementReport.title')}
            color={theme.palette.primary.main}
            backgroundColor={theme.xPalette.white}
            marginTop="10px"
            toggleNavigation={() => {
              navigate('/dashboard/management-report');
            }}
          />
        </Container>
      )}
      <Container
        maxWidth="xl"
        sx={{
          backgroundColor: theme.xPalette.background,
          borderRadius: '15px',
          marginTop: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row wrap',
            gap: '11px',
            justifyContent: 'space-between',
            my: 2.75,
            width: 1,
          }}
        >
          <InterviewSummaryBox
            icon={IconBoxInterviewSummary(TotalInterviewsIcon())}
            text={t('dashboard.accountStats.totalInterviews.title')}
            total={interviewStatistics?.totalInterviews}
            color={theme.palette.primary.main}
            backgroundColor={theme.xPalette.white}
            toggleNavigation={() => {
              navigate(''); //TODO: update route
            }}
            isLoading={isInterviewLoading}
          />
          <InterviewSummaryBox
            icon={IconBoxInterviewSummary(IncompleteInterviewsIcon())}
            text={t('dashboard.accountStats.incompleteInterviews.title')}
            total={interviewStatistics?.incompleteInterviews}
            color={theme.palette.primary.main}
            backgroundColor={theme.xPalette.white}
            toggleNavigation={() => {
              navigate(''); //TODO: update route
            }}
            isLoading={isInterviewLoading}
          />
          <InterviewSummaryBox
            icon={IconBoxInterviewSummary(TotalMonitorsIcon())}
            text={t('dashboard.accountStats.totalMonitors.title')}
            total={monitorStatistics?.totalMonitors}
            color={theme.palette.primary.main}
            backgroundColor={theme.xPalette.white}
            toggleNavigation={() => {
              navigate(''); //TODO: update route
            }}
            isLoading={isMonitorLoading}
          />
          <InterviewSummaryBox
            icon={IconBoxInterviewSummary(IncompleteMonitorsIcon())}
            text={t('dashboard.accountStats.incompleteMonitors.title')}
            total={monitorStatistics?.incompleteMonitors}
            color={theme.palette.primary.main}
            backgroundColor={theme.xPalette.white}
            toggleNavigation={() => {
              navigate(''); //TODO: update route
            }}
            isLoading={isMonitorLoading}
          />
        </Box>
      </Container>
    </DashboardLayout>
  );
}
