import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { tableCellClasses } from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  useLazyDownloadBillingUsageReportQuery,
  useQueryBillingUsageReportQuery,
} from '../../app/services/interview';
import CustomPagination from '../../common/components/CustomPagination';
import DataTable from '../../common/components/DataTable';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import UsageReportInfoCard from '../../common/components/UsageReportInfoCard';
import DashboardLayout from '../../common/layouts/Dashboard';
import { DefaultPageSize } from '../../common/utils/commonUtils';
import { getPageTitle } from '../../common/utils/pageUtils';
import { SortDirs } from '../../common/utils/sortInfo';
import { PageProps } from '../../types/PageProps';
import { ResponseError } from '../../types/ResponseError';
import {
  BillingUsageReportResponse,
  BillingUsageReportResponseItem,
} from '../../types/UsageReport';
import ErrorPage from '../error/ErrorPage';

export default function BillingUsageReportResult({ breadcrumbs }: PageProps) {
  const { state } = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const { reportData } = !!state && state;

  const tableRef = useRef<HTMLDivElement>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [displayedReportData, setDisplayedReportData] = useState<BillingUsageReportResponse | null>(
    null
  );
  const searchOptions = {
    clinicAccountId: searchParams.get('clinicAccountId') || '',
    reportType: searchParams.get('reportType') || '',
    fromDate: searchParams.get('fromDate') || '',
    toDate: searchParams.get('toDate') || '',
    page: searchParams.get('page') || '1',
    sortField: searchParams.get('sort') || 'end',
    sortDir: searchParams.get('dir') || SortDirs.Desc,
  };

  const baseParams =
    '?clinicAccountId=:clinicAccountId' +
    '&reportType=:reportType' +
    '&fromDate=:fromDate' +
    '&toDate=:toDate';

  const getNavBackUrl = () => {
    return generatePath(`/dashboard/billing-usage-report`);
  };

  const currentPage = parseInt(searchOptions.page);
  // Query data
  const usageReportQuery = {
    clinicAccountId: searchOptions.clinicAccountId,
    reportType: searchOptions.reportType,
    fromDate: searchOptions.fromDate || '',
    toDate: searchOptions.toDate || '',
    page: currentPage,
    pageSize: DefaultPageSize,
    sort: `${searchOptions.sortField} ${searchOptions.sortDir}`,
  };
  const {
    data: usageReportData,
    error,
    isLoading: isLoadingReport,
  } = useQueryBillingUsageReportQuery(
    usageReportQuery.clinicAccountId &&
      usageReportQuery.reportType &&
      usageReportQuery.fromDate &&
      usageReportQuery.toDate &&
      usageReportQuery.sort
      ? usageReportQuery
      : skipToken
  );

  const [triggerDownloadBillingUsageReport] = useLazyDownloadBillingUsageReportQuery();

  const showPagingInfo = totalPages > 1;

  useEffect(() => {
    let total = 0;
    if (usageReportData) {
      total = usageReportData.total;
      setDisplayedReportData(usageReportData);
      if (usageReportData.assessments.length === 0) {
        setPageErrors([t('report.error.noDataFound')]);
      }
    } else if (reportData) {
      total = reportData.total;
      setDisplayedReportData(reportData);
    }
    setTotalPages(Math.ceil(total / DefaultPageSize));
  }, [usageReportData, reportData, t]);

  const updateSearchUrl = (updatedData: Partial<typeof searchOptions>) => {
    const params = {
      ...searchOptions,
      ...updatedData,
    };

    const url = generatePath(
      `/dashboard/billing-usage-report/output/${baseParams}&page=:page&sort=:sortField&dir=:sortDir`,
      params
    );
    navigate(url, { replace: true });
  };

  const handleTableSorting = (sortField: string) => {
    if (sortField === searchOptions.sortField) {
      const newDir = searchOptions.sortDir === SortDirs.Asc ? SortDirs.Desc : SortDirs.Asc;
      updateSearchUrl({ sortDir: newDir });
    } else {
      updateSearchUrl({
        sortField,
        sortDir: SortDirs.Desc,
      });
    }
  };

  const onDownLoadReport = async () => {
    setPageErrors([]);
    try {
      triggerDownloadBillingUsageReport({
        clinicAccountId: usageReportQuery.clinicAccountId,
        reportType: usageReportQuery.reportType,
        fromDate: usageReportQuery.fromDate || '',
        toDate: usageReportQuery.toDate || '',
      });
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  const handlePageChange = (_: React.ChangeEvent<unknown>, newPage: number) => {
    updateSearchUrl({ page: newPage.toString() });
  };

  const getDateTime = (dateTime?: string) => {
    if (!dateTime) return '';

    const [date, ...timeParts] = dateTime.split(' ');
    const time = timeParts.join(' ');

    return (
      <>
        {date}
        <br />
        {time}
      </>
    );
  };

  const renderDesktopData = (data: BillingUsageReportResponseItem[]) => {
    return (
      <DataTable
        sort={{ field: searchOptions.sortField, dir: searchOptions.sortDir }}
        onSorted={handleTableSorting}
        commonCellStyles={{
          [`&.${tableCellClasses.head}`]: {
            padding: '16px 8px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
          },
          [`&.${tableCellClasses.body}`]: {
            padding: '16px 8px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
          },
          [`&.${tableCellClasses.body}:first-of-type`]: {
            maxWidth: 'none',
            wordBreak: 'normal',
          },
        }}
        headers={[
          { text: t('billingUsageReport.items.start'), sortKey: 'start' },
          { text: t('billingUsageReport.items.end'), sortKey: 'end' },
          { text: t('billingUsageReport.items.duration') },
          { text: t('billingUsageReport.items.measurement'), sortKey: 'measurement' },
          { text: t('billingUsageReport.items.category') },
          { text: t('billingUsageReport.items.billingType'), sortKey: 'billingType' },
          { text: t('billingUsageReport.items.sequence') },
          { text: t('billingUsageReport.items.site'), sortKey: 'site' },
          { text: t('billingUsageReport.items.interviewer'), sortKey: 'interviewer' },
          { text: t('billingUsageReport.items.patientId') },
        ]}
        items={data.map((item: BillingUsageReportResponseItem) => {
          return [
            {
              component: (
                <Typography sx={{ width: '120px' }}>{getDateTime(item.timeBegan)}</Typography>
              ),
            },
            {
              component: (
                <Typography sx={{ width: '120px' }}>{getDateTime(item.timeEnded)}</Typography>
              ),
            },
            {
              component: <Typography>{item.length}</Typography>,
            },
            {
              component: <Typography sx={{ width: '180px' }}>{item.measurement}</Typography>,
            },
            {
              component: <Typography sx={{ width: '120px' }}>{item.category}</Typography>,
            },
            {
              component: <Typography sx={{ width: '180px' }}>{item.billingType}</Typography>,
            },
            {
              component: <Typography>{item.sequence}</Typography>,
            },
            {
              component: <Typography sx={{ width: '140px' }}>{item.siteName}</Typography>,
            },
            { component: <Typography>{item.interviewer}</Typography> },
            { component: <Typography>{item.patient}</Typography> },
          ];
        })}
      />
    );
  };

  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }
  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('billingUsageReport.output'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            py: 2,
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          }}
          ref={tableRef}
        >
          <PageHeader
            sx={{ mb: 2, mx: isDesktop ? 3 : 2 }}
            headerText={t('billingUsageReport.output')}
            backUrl={getNavBackUrl()}
          />
        </Box>

        {isLoadingReport ? (
          <Loading />
        ) : (
          <>
            <Box ref={tableRef}>
              <RoundedContainer sx={{ py: 2, mb: 2 }}>
                <UsageReportInfoCard
                  clinicAccountId={displayedReportData?.clinicAccountId || ''}
                  clinicAccountName={displayedReportData?.clinicAccountName || ''}
                  reportType={searchOptions.reportType}
                  fromDate={searchOptions.fromDate || ''}
                  toDate={searchOptions.toDate || ''}
                  total={displayedReportData?.total || 0}
                  onDownLoadReport={onDownLoadReport}
                  title={t('billingUsageReport.outputTitle')}
                />
              </RoundedContainer>

              {displayedReportData && (
                <>
                  <Box sx={{ width: '100%' }}>
                    {displayedReportData?.assessments?.length && (
                      <>
                        {renderDesktopData(displayedReportData?.assessments)}
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            py: showPagingInfo ? 4 : 2,
                            borderBottomLeftRadius: '15px',
                            borderBottomRightRadius: '15px',
                            background: theme.xPalette.white,
                          }}
                        >
                          {showPagingInfo && (
                            <CustomPagination
                              changePage={handlePageChange}
                              currentPage={currentPage}
                              pages={totalPages}
                              pageRef={tableRef}
                            />
                          )}
                        </Box>
                      </>
                    )}
                  </Box>
                </>
              )}
            </Box>
            {pageErrors?.length > 0 &&
              pageErrors.map((error: string) => (
                <Alert key={error} severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              ))}
          </>
        )}
      </Container>
    </DashboardLayout>
  );
}
