import { ChangeEvent, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useAppSelector } from '../../../../../app/hooks';
import { QuestionAnswerType } from '../../../../../common/utils/questionAnswerTypes';
import { InterviewQuestionProps } from '../../../../../types/InterviewQuestionTypes';
import useSetWatchQuestion from '../../../hooks/useSetWatchQuestion';
import { selectResetQuestion } from '../../../interviewSlice';

const StyledTypography = styled(Typography)(() => ({
  marginBottom: '8px',
}));

export const StyledFormLabel = styled(FormControlLabel)(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
  paddingTop: '10px',
  flexDirection: 'row',
  margin: 0,
  width: '25%',
  [theme.breakpoints.down('lg')]: {
    width: '30%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const StyledFormGroup = styled(FormGroup)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: '100%',
  marginLeft: '8px',
}));

export const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  padding: '3px 8px',
  '&.Mui-checked': {
    color: theme.xPalette.completed.color,
  },
}));

export default function MinimizeMultiOptionsQuestion({
  question,
  field,
  error,
  helperText,
  setValue,
  optionChangeCallback,
  sequenceOrder,
}: InterviewQuestionProps) {
  const [multiOptions, setMultiOptions] = useState<string[]>([]);
  const debounce = useRef<boolean>(true);
  const isResetQuestion = useAppSelector<boolean>(selectResetQuestion);

  const { handleOnBlur } = useSetWatchQuestion({
    name: field?.name!,
    type: QuestionAnswerType.MultiOptions,
    required: !!question.isRequired,
  });

  const renderItems = () => {
    const { possibleAnswers, displayAnswers } = question;
    const items = [];
    for (let i = 0; i < possibleAnswers.length; i++) {
      const value = possibleAnswers[i];
      const text = displayAnswers?.length === possibleAnswers?.length
          ? `${displayAnswers?.[i] || value}`.trim()
          : value.trim()
      items.push(
        <StyledFormLabel
          checked={multiOptions.includes(value)}
          key={value}
          value={value}
          control={<StyledCheckBox />}
          label={text}
        />
      );
    }
    return items;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    let data = [];
    const checkExistValue = multiOptions.includes(value);
    if (checkExistValue) {
      data = [...multiOptions.filter((item) => item !== value)];
    } else {
      data = [...multiOptions, value];
    }

    setMultiOptions(data);
    setValue(field?.name!, data.join('|'));
    if (optionChangeCallback) {
      optionChangeCallback();
    }

    handleOnBlur();
  };

  useEffect(() => {
    if (field?.value && typeof field.value === 'string' && debounce.current) {
      debounce.current = false;
      setMultiOptions(field.value.split('|'));
      setValue(field?.name, field.value);
      if (optionChangeCallback) {
        optionChangeCallback();
      }
    }
  }, [field?.name, field?.value, multiOptions, optionChangeCallback, setValue]);

  useEffect(() => {
    if (isResetQuestion) {
      setMultiOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetQuestion]);

  return (
    <Box sx={{ my: 2 }}>
      {question.questionPrompt && (
        <StyledTypography
          dangerouslySetInnerHTML={{
            __html: question.questionPrompt,
          }}
        ></StyledTypography>
      )}
      <StyledTypography
        dangerouslySetInnerHTML={{
          __html: question.questionText!,
        }}
      ></StyledTypography>
      <FormControl sx={{ width: '100%' }} onChange={handleChange} error={error} variant="standard">
        <StyledFormGroup
          {...field}
          className={`sequence-order-${sequenceOrder}`}
          sx={{ flexDirection: question.name === 'N5' ? 'column' : 'row' }}
        >
          {renderItems()}
        </StyledFormGroup>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
}
