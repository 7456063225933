import { useCallback, useEffect, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Alert from '@mui/material/Alert';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../app/hooks';
import AutoCompleteWithChips from '../../common/components/AutoCompleteWithChips';
import RoundedContainer from '../../common/components/RoundedContainer';
import checkNullData from '../../common/utils/checkNullData';
import { Status } from '../../common/utils/commonUtils';
import { USCountryCode, countryType, sortedCountryCodes } from '../../common/utils/countries';
import { formatDate } from '../../common/utils/dateTimeUtils';
import { Role } from '../../common/utils/loginRole';
import { Genders, Races } from '../../common/utils/patientInfo';
import { PatientStatus } from '../../common/utils/patientStatus';
import { usStates } from '../../common/utils/usStates';
import {
  PhoneNumberRequired,
  validateEmail,
  validatePhoneNumber,
} from '../../common/utils/validationUtils';
import { ClinicAccountItems } from '../../types/ClinicAccountType';
import { ClinicSiteItems } from '../../types/ClinicSiteType';
import { PatientInfo } from '../../types/PatientRequest';
import { ResponseError } from '../../types/ResponseError';
import { Provider } from '../../types/UserTypes';
import { selectUser } from '../auth/authSlice';

interface PatientFormProps {
  patientId?: string;
  data?: PatientInfo;
  clinicAccounts?: ClinicAccountItems[];
  clinicSites?: ClinicSiteItems[];
  onFormSubmit: (
    data: PatientInfo,
    patientId?: string,
    successCallback?: () => void
  ) => Promise<void>;
  onResetMfa: () => Promise<void>;
  isSendIntakePacket?: boolean;
  setIsSendIntakePacket: (isSendIntakePacket: boolean) => void;
}

const defaultFormValues: PatientInfo = {
  clinicAccountId: '',
  clinicSiteId: 0,
  patientId: '',
  firstName: '',
  lastName: '',
  externalId: '',
  email: '',
  cellPhoneNumber: '',
  homePhoneNumber: '',
  workPhoneNumber: '',
  birthdate: null,
  gender: '',
  race: '',
  addressLine1: '',
  addressLine2: '',
  country: '',
  city: '',
  state: '',
  zip: '',
  sendIntakePacket: false,
  providerId: undefined,
  smsOptIn: false,
  optInPatientReminders: true,
  reminderEmails: [],
  reminderPhones: [],
};

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.MuiCheckbox-root': {
    width: '45px',
    height: '45px',
  },
  '&.Mui-checked': {
    color: theme.palette.secondary.main,
  },
  '&.MuiCheckbox-indeterminate': {
    color: theme.palette.secondary.main,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 30,
  },
}));

const StyleTextField = styled(TextField)(({ theme }) => ({
  [`&.MuiFormControl-root`]: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '49%',
    },
  },
}));

const StyleAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: '49%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export default function AddOrEditForm({
  patientId,
  data,
  clinicAccounts,
  clinicSites,
  onFormSubmit,
  onResetMfa,
  isSendIntakePacket,
  setIsSendIntakePacket,
}: PatientFormProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [stateLabel, setStateLabel] = useState<string>(t('patient.patientInfo.state'));
  const [isUSA, setIsUSA] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<string>('');
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [isClinicSitesLoaded, setIsClinicSitesLoaded] = useState<boolean>(false);
  const [clinicSiteData, setClinicSiteData] = useState<ClinicSiteItems[]>([]);
  const [providerData, setProviderData] = useState<Provider[]>([]);
  const [invalidSiteId, setInvalidSite] = useState<number>(0);
  const currentUser = useAppSelector(selectUser);
  const [isDisabledSendIntakePacket, setIsDisabledSendIntakePacket] = useState<boolean>(true);
  const [smsOptInValue, setSmsOptInValue] = useState<boolean>(false);
  const [disabledSmsOptIn, setDisabledSmsOptIn] = useState<boolean>(false);
  const [accountSmsEnabled, setAccountSmsEnabled] = useState<boolean>(false);
  const [isSendPatientReminders, setIsSendPatientReminders] = useState<boolean>(true);
  const [extendedEmail, setExtendedEmail] = useState<string>('');
  const [extendedPhone, setExtendedPhone] = useState<string>('');

  const validationSchema = yup
    .object({
      clinicAccountId: yup
        .string()
        .trim()
        .test('validate-account', t('patient.error.blankAccount'), function (clinicAccountId) {
          return clinicAccountId !== '';
        }),
      clinicSiteId: yup
        .string()
        .test('validate-site', t('patient.error.blankSite'), function (clinicSiteId) {
          return parseInt(clinicSiteId || '0') ? true : false;
        }),
      firstName: yup.string(),
      lastName: yup.string(),
      externalId: yup.string(),
      email: yup.string().email(t('patient.error.invalidEmail')),
      cellPhoneNumber: yup
        .string()
        .trim()
        .test(
          'validate-cell-phone',
          t('patient.error.cellPhoneNumberTooShort', {
            minLength: PhoneNumberRequired.MinLengthPhoneNumber,
          }),
          function (cellPhoneNumber) {
            return validatePhoneNumber(cellPhoneNumber);
          }
        ),
      homePhoneNumber: yup
        .string()
        .trim()
        .test(
          'validate-home-phone',
          t('patient.error.homePhoneNumberTooShort', {
            minLength: PhoneNumberRequired.MinLengthPhoneNumber,
          }),
          function (homePhoneNumber) {
            return validatePhoneNumber(homePhoneNumber);
          }
        ),
      workPhoneNumber: yup
        .string()
        .trim()
        .test(
          'validate-work-phone',
          t('patient.error.workPhoneNumberTooShort', {
            minLength: PhoneNumberRequired.MinLengthPhoneNumber,
          }),
          function (workPhoneNumber) {
            return validatePhoneNumber(workPhoneNumber);
          }
        ),
      dob: yup.date().nullable(),
      gender: yup.string(),
      race: yup.string(),
      addressLine1: yup.string(),
      addressLine2: yup.string(),
      country: yup.string(),
      city: yup.string(),
      state: yup.string(),
      zipCode: yup.string(),
      sendIntakePacket: yup.boolean().nullable(),
      showIntakePacketPopup: yup.boolean().nullable(),
      smsOptIn: yup.boolean().nullable(),
      providerId: yup.string().when('sendIntakePacket', {
        is: true,
        then: yup.string().required(t('patient.error.blankProvider')),
      }),
      reminderEmails: yup.array(yup.string().email()).nullable(),
      reminderPhones: yup
        .array(
          yup.string().test(
            'validate-cell-phone-reminders',
            t('patient.error.cellPhoneNumberTooShort', {
              minLength: PhoneNumberRequired.MinLengthPhoneNumber,
            }),
            function (cellPhoneNumber) {
              return validatePhoneNumber(cellPhoneNumber);
            }
          )
        )
        .nullable(),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    reset,
  } = useForm<PatientInfo>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const populateSite = useCallback(
    (clinicAccountId: string) => {
      let clinicSiteData = clinicSites?.filter((x) => x.clinicAccountId === clinicAccountId) || [];
      if (
        patientId &&
        data !== undefined &&
        (currentUser?.roleId === Role.SuperAdmin ||
          currentUser?.roleId === Role.ClinicAdmin ||
          currentUser?.roleId === Role.MultiUserProvider ||
          currentUser?.roleId === Role.MultiSiteAdmin)
      ) {
        const foundPatientSite = clinicSiteData.find((x) => x.id === data.clinicSiteId);
        if (!foundPatientSite) {
          // save site id that user has no permission on patient's site id
          setInvalidSite(data.clinicSiteId);
          clinicSiteData.push({
            id: data.clinicSiteId,
            name: data.clinicSiteName,
            status: data.clinicSiteStatus,
          } as ClinicSiteItems);
        }
        clinicSiteData.sort((a: ClinicSiteItems, b: ClinicSiteItems) =>
          a.name.localeCompare(b.name)
        );
      }
      setClinicSiteData(clinicSiteData);
    },
    [clinicSites, currentUser, data, patientId]
  );

  const getSortedProviders = (clinicSite?: ClinicSiteItems) => {
    return clinicSite
      ? [...(clinicSite.usersInSite || [])].sort(
          (a: Provider, b: Provider) =>
            a.lastName?.localeCompare(b.lastName) || a.firstName?.localeCompare(b.firstName)
        )
      : [];
  };

  useEffect(() => {
    const account = clinicAccounts
      ? clinicAccounts.find((item) => item.id === data?.clinicAccountId)
      : null;
    if (account && account.smsEnabled) {
      setAccountSmsEnabled(account?.smsEnabled ? account?.smsEnabled : false);
    } else {
      const clinicAccountId = getValues('clinicAccountId');
      const accountSelected = clinicAccounts
        ? clinicAccounts.find((item) => item.id === clinicAccountId)
        : null;
      setAccountSmsEnabled(accountSelected?.smsEnabled ? accountSelected?.smsEnabled : false);
    }
    setValue('smsOptIn', data ? data.smsOptIn : false);
    const automateIntakePacket = account ? account.automateIntakePacket : null;
    const clinicSite = clinicSiteData?.find((item) => item.id === data?.clinicSiteId);
    const email = getValues('email');
    if (patientId) {
      const smsOptIn = data ? data?.smsOptIn : false;
      setSmsOptInValue(smsOptIn);
      setValue('smsOptIn', smsOptIn);
      setDisabledSmsOptIn(data?.cellPhoneNumber ? false : true);
    } else {
      setSmsOptInValue(false);
      setValue('smsOptIn', false);
      setDisabledSmsOptIn(true);
    }
    const cellPhone = getValues('cellPhoneNumber');
    const smsOptInField = getValues('smsOptIn');
    if (cellPhone) {
      setDisabledSmsOptIn(false);
    }

    if (
      (!patientId && data === undefined) ||
      (patientId &&
        data !== undefined &&
        !data.providerId &&
        (!automateIntakePacket ||
          !clinicSite ||
          clinicSite.status !== Status.ACTIVE ||
          !clinicSite?.intakePacketInterviews ||
          clinicSite?.intakePacketInterviews.length === 0 ||
          (!email && (!cellPhone || (cellPhone && !smsOptInField)))))
    ) {
      setIsDisabledSendIntakePacket(true);
      setIsSendIntakePacket(false);
      setValue('sendIntakePacket', false);
      setProviderData([]);
    } else if (
      data !== undefined &&
      (data.providerId ||
        (!data.providerId &&
          automateIntakePacket &&
          clinicSite?.intakePacketInterviews &&
          clinicSite?.intakePacketInterviews.length > 0 &&
          (email || (cellPhone && smsOptInValue))))
    ) {
      setIsDisabledSendIntakePacket(false);
      const clinicSite = clinicSiteData?.find((item) => item.id === data.clinicSiteId);
      setProviderData(getSortedProviders(clinicSite));
      if (!patientId) {
        setIsSendIntakePacket(true);
        setValue('sendIntakePacket', true);
      } else {
        setIsSendIntakePacket(false);
        setValue('sendIntakePacket', false);
      }
      if (data.providerId) {
        setValue('providerId', data.providerId);
      } else {
        const providerIdValue =
          clinicSite && clinicSite.intakePacketDefaultProvider
            ? parseInt(clinicSite.intakePacketDefaultProvider)
            : undefined;
        setValue('providerId', providerIdValue);
      }
    }
    // eslint-disable-next-line
  }, [clinicAccounts, clinicSiteData, getValues, patientId, data, setValue]);

  // bind controls based on selected country code
  useEffect(() => {
    if (countryCode) {
      setIsUSA(countryCode === USCountryCode);
      const { adminDistrict } = sortedCountryCodes[countryCode];
      setStateLabel(adminDistrict);
      setValue('country', countryCode);
    }
  }, [countryCode, setValue, isUSA]);

  // initial data load
  useEffect(() => {
    if (!isDataLoaded && patientId && data !== undefined) {
      setIsDataLoaded(true);
      const bindEditControls = async () => {
        Object.keys(defaultFormValues).forEach((field: any) => {
          setValue(field, checkNullData(data[field as keyof PatientInfo]));

          if (data.country) {
            setStateLabel(sortedCountryCodes[data.country as keyof countryType].adminDistrict);
            setCountryCode(data.country);
          }

          if (data.email) {
            setExtendedEmail(data.email);
          }

          if (data.cellPhoneNumber) {
            setExtendedPhone(data.cellPhoneNumber);
          }
        });
        setIsSendPatientReminders(!!data.optInPatientReminders);
        populateSite(data.clinicAccountId);
        setValue('clinicSiteId', data.clinicSiteId);
      };
      bindEditControls();
    }
  }, [patientId, isDataLoaded, clinicAccounts, setValue, data, populateSite]);

  useEffect(() => {
    if (!isClinicSitesLoaded && clinicSites) {
      setIsClinicSitesLoaded(true);
      let clinicAccountId = currentUser?.clinicAccountId;
      if (patientId && data !== undefined) {
        clinicAccountId = data.clinicAccountId;
        populateSite(clinicAccountId);
        setValue('clinicSiteId', data.clinicSiteId);
      } else {
        if (currentUser?.roleId !== Role.SuperAdmin) {
          populateSite(clinicAccountId);
        }
      }

      if (currentUser?.roleId !== Role.SuperAdmin) {
        setValue('clinicAccountId', currentUser?.clinicAccountId);
        if (
          currentUser?.roleId === Role.SiteAdmin ||
          currentUser?.roleId === Role.Interviewer ||
          currentUser?.roleId === Role.SingleUserProvider
        ) {
          if (patientId && data !== undefined) {
            setValue('clinicSiteId', data.clinicSiteId);
          } else {
            setValue('clinicSiteId', clinicSites.length && clinicSites[0].id);
          }
        }
      }
    }
  }, [
    isClinicSitesLoaded,
    isDataLoaded,
    clinicSites,
    patientId,
    data,
    currentUser?.roleId,
    currentUser?.clinicAccountId,
    setValue,
    populateSite,
  ]);

  const handleAccountChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('clinicAccountId', selectedValue);
    setValue('clinicSiteId', 0);
    populateSite(selectedValue);
    if (selectedValue) {
      const clinicAccount = clinicAccounts?.find((item) => item.id === selectedValue);
      setAccountSmsEnabled(clinicAccount?.smsEnabled ? clinicAccount.smsEnabled : false);
      if (clinicAccount && !clinicAccount.automateIntakePacket) {
        setIsDisabledSendIntakePacket(true);
        setIsSendIntakePacket(false);
        setValue('sendIntakePacket', false);
      }
    } else {
      setAccountSmsEnabled(false);
    }
  };

  const handleSiteChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('clinicSiteId', selectedValue);
    const clinicAccountId = getValues('clinicAccountId');
    const account = clinicAccounts
      ? clinicAccounts.find((item) => item.id === clinicAccountId)
      : null;
    const automateIntakePacket = account ? account.automateIntakePacket : null;
    const clinicSite = clinicSiteData?.find((item) => item.id === selectedValue);
    const emailValue = getValues('email');
    const smsOptInField = getValues('smsOptIn');
    const cellPhone = getValues('cellPhoneNumber');
    const checkEmail = validateEmail(emailValue);
    if (
      !automateIntakePacket ||
      (!checkEmail && !cellPhone) ||
      (!checkEmail && !smsOptInField) ||
      (!checkEmail && cellPhone && !smsOptInField) ||
      !clinicSite ||
      clinicSite.status !== Status.ACTIVE ||
      (clinicSite &&
        (!clinicSite.intakePacketInterviews || clinicSite?.intakePacketInterviews.length === 0))
    ) {
      setIsDisabledSendIntakePacket(true);
      setIsSendIntakePacket(false);
      setValue('sendIntakePacket', false);
      setProviderData([]);
    } else {
      setIsDisabledSendIntakePacket(false);
      if (!patientId) {
        setIsSendIntakePacket(true);
        setValue('sendIntakePacket', true);
      } else {
        setIsSendIntakePacket(false);
        setValue('sendIntakePacket', false);
      }
      setProviderData(getSortedProviders(clinicSite));
      const providerIdValue =
        clinicSite && clinicSite.intakePacketDefaultProvider
          ? parseInt(clinicSite.intakePacketDefaultProvider)
          : undefined;
      setValue('providerId', providerIdValue);
    }
  };

  const handleProviderChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : undefined;
    setValue('providerId', selectedValue);
  };

  const handleEmailChange = (event: any) => {
    const valueEmail = event.target.value;
    setValue('email', valueEmail);
    setExtendedEmail(valueEmail);
    const checkEmail = validateEmail(valueEmail);
    const clinicSiteId = getValues('clinicSiteId');
    const clinicSite = clinicSiteData?.find((item) => item.id === clinicSiteId);
    const clinicAccountId = getValues('clinicAccountId');
    const smsOptInField = getValues('smsOptIn');
    const cellPhone = getValues('cellPhoneNumber');
    const account = clinicAccounts
      ? clinicAccounts.find((item) => item.id === clinicAccountId)
      : null;
    const automateIntakePacket = account ? account.automateIntakePacket : null;
    if (
      !automateIntakePacket ||
      (!checkEmail && !cellPhone) ||
      (!checkEmail && cellPhone && !smsOptInField) ||
      (!checkEmail && cellPhone && !account?.smsEnabled) ||
      (checkEmail &&
        (!clinicSite ||
          clinicSite.status !== Status.ACTIVE ||
          (clinicSite &&
            (!clinicSite.intakePacketInterviews ||
              clinicSite?.intakePacketInterviews.length === 0))))
    ) {
      setIsDisabledSendIntakePacket(true);
      setIsSendIntakePacket(false);
      setValue('sendIntakePacket', false);
      setProviderData([]);
    } else {
      setIsDisabledSendIntakePacket(false);
      if (!patientId) {
        setIsSendIntakePacket(true);
        setValue('sendIntakePacket', true);
      } else {
        setIsSendIntakePacket(false);
        setValue('sendIntakePacket', false);
      }
      setProviderData(getSortedProviders(clinicSite));
      const providerIdValue =
        clinicSite && clinicSite.intakePacketDefaultProvider
          ? parseInt(clinicSite.intakePacketDefaultProvider)
          : undefined;
      setValue('providerId', providerIdValue);
    }
  };

  const handleCellPhoneChange = (event: any) => {
    const valueCellPhone = event.target.value;
    setValue('cellPhoneNumber', valueCellPhone);
    setExtendedPhone(valueCellPhone);
    if (valueCellPhone) {
      setDisabledSmsOptIn(false);
      setValue('smsOptIn', true);
      setSmsOptInValue(true);
    } else {
      setDisabledSmsOptIn(true);
      setValue('smsOptIn', false);
      setSmsOptInValue(false);
    }
    const smsOptInField = getValues('smsOptIn');
    const email = getValues('email');
    const clinicSiteId = getValues('clinicSiteId');
    const clinicSite = clinicSiteData?.find((item) => item.id === clinicSiteId);
    const clinicAccountId = getValues('clinicAccountId');
    const account = clinicAccounts
      ? clinicAccounts.find((item) => item.id === clinicAccountId)
      : null;
    const automateIntakePacket = account ? account.automateIntakePacket : null;
    if (
      !automateIntakePacket ||
      (!valueCellPhone && !email) ||
      (valueCellPhone && !email && !account?.smsEnabled) ||
      (valueCellPhone && !smsOptInField && !email) ||
      !clinicSite ||
      clinicSite.status !== Status.ACTIVE ||
      (clinicSite &&
        (!clinicSite.intakePacketInterviews || clinicSite?.intakePacketInterviews.length === 0))
    ) {
      setIsDisabledSendIntakePacket(true);
      setIsSendIntakePacket(false);
      setValue('sendIntakePacket', false);
      setProviderData([]);
    } else {
      setIsDisabledSendIntakePacket(false);
      if (!patientId) {
        setIsSendIntakePacket(true);
        setValue('sendIntakePacket', true);
      } else {
        setIsSendIntakePacket(false);
        setValue('sendIntakePacket', false);
      }
      setProviderData(getSortedProviders(clinicSite));
      const providerIdValue =
        clinicSite && clinicSite.intakePacketDefaultProvider
          ? parseInt(clinicSite.intakePacketDefaultProvider)
          : undefined;
      setValue('providerId', providerIdValue);
    }
  };

  const handleSmsOptInChange = (event: any) => {
    const valueSmsOptIn = event.target.checked;
    setValue('smsOptIn', valueSmsOptIn);
    setSmsOptInValue(valueSmsOptIn);

    const cellPhone = getValues('cellPhoneNumber');
    const email = getValues('email');
    const clinicSiteId = getValues('clinicSiteId');
    const clinicSite = clinicSiteData?.find((item) => item.id === clinicSiteId);
    const clinicAccountId = getValues('clinicAccountId');
    const account = clinicAccounts
      ? clinicAccounts.find((item) => item.id === clinicAccountId)
      : null;
    const automateIntakePacket = account ? account.automateIntakePacket : null;
    if (
      !automateIntakePacket ||
      (!cellPhone && !email) ||
      (!valueSmsOptIn && !email) ||
      !clinicSite ||
      clinicSite.status !== Status.ACTIVE ||
      (clinicSite &&
        (!clinicSite.intakePacketInterviews || clinicSite?.intakePacketInterviews.length === 0))
    ) {
      setIsDisabledSendIntakePacket(true);
      setIsSendIntakePacket(false);
      setValue('sendIntakePacket', false);
      setProviderData([]);
    } else {
      setIsDisabledSendIntakePacket(false);
      if (!patientId) {
        setIsSendIntakePacket(true);
        setValue('sendIntakePacket', true);
      } else {
        setIsSendIntakePacket(false);
        setValue('sendIntakePacket', false);
      }
      setProviderData(getSortedProviders(clinicSite));
      const providerIdValue =
        clinicSite && clinicSite.intakePacketDefaultProvider
          ? parseInt(clinicSite.intakePacketDefaultProvider)
          : undefined;
      setValue('providerId', providerIdValue);
    }
  };
  const handleCountryChange = (e: any, values: any) => {
    const selectedValue = values ? values.code : '';
    // reset state when change from US to other or vice versa
    const isSelectingUSA = selectedValue === USCountryCode;
    if (isUSA !== isSelectingUSA) {
      setValue('state', '');
    }

    setCountryCode(selectedValue);
  };

  const handleStateChange = (e: any, values: any) => {
    const selectedState = values ? values.code : '';
    setValue('state', selectedState);
  };

  const filterAccountOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getAccountOptionLabel(option),
  });

  const filterSiteOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getSiteOptionLabel(option),
  });

  const filterProviderOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getSiteOptionLabel(option),
  });

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => option.name,
  });

  const getSiteOptionLabel = (option: any) => `${option.name}`;
  const getAccountOptionLabel = (option: any) => `${option.name} (${option.id})`;

  const handleResetPatientMfa = async () => {
    try {
      await onResetMfa();
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  const onSubmit = async (data: PatientInfo) => {
    data = { ...data, birthdate: data.birthdate ? formatDate(data.birthdate) : '' };
    try {
      await onFormSubmit(data, patientId, () => {
        if (!patientId) {
          reset();
        } else {
          // if editing
          if (invalidSiteId && invalidSiteId !== parseInt(data.clinicSiteId.toString())) {
            let filteredClinicSiteData =
              clinicSiteData?.filter((x) => x.id !== invalidSiteId) || [];
            setClinicSiteData(filteredClinicSiteData);
          }
        }

        setIsSendIntakePacket(false);
        setValue('sendIntakePacket', false);
        setPageErrors([]);
      });
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        '& .MuiContainer-root': {
          mt: 2,
          py: 3,
        },
      }}
    >
      <RoundedContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          {!patientId && currentUser.roleId === Role.SuperAdmin && (
            <Controller
              name="clinicAccountId"
              control={control}
              render={({ fieldState, formState, field: { value }, ...props }) => (
                <StyleAutocomplete
                  {...props}
                  data-testid="mui-component-select-clinic-account"
                  options={clinicAccounts || []}
                  getOptionLabel={getAccountOptionLabel}
                  onChange={handleAccountChange}
                  value={value ? clinicAccounts?.find((item) => item.id === value) : null}
                  filterOptions={filterAccountOptions}
                  renderOption={(props, option: any) => {
                    return (
                      <li {...props} key={option.id} value={option.id}>
                        {getAccountOptionLabel(option)}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.clinicAccountId}
                      helperText={errors.clinicAccountId?.message}
                      margin="normal"
                      fullWidth
                      label={t('patient.patientInfo.account')}
                    />
                  )}
                />
              )}
            />
          )}
          {(currentUser?.roleId === Role.SuperAdmin ||
            currentUser?.roleId === Role.ClinicAdmin ||
            currentUser?.roleId === Role.MultiSiteAdmin ||
            currentUser?.roleId === Role.MultiUserProvider) && (
            <Controller
              name="clinicSiteId"
              control={control}
              render={({ fieldState, formState, field: { value }, ...props }) => (
                <StyleAutocomplete
                  {...props}
                  data-testid="mui-component-select-site"
                  options={clinicSiteData!}
                  getOptionLabel={(option: any) => (option.name ? option.name : '')}
                  onChange={handleSiteChange}
                  value={value ? clinicSiteData?.find((item) => item.id === value) || null : null}
                  filterOptions={filterSiteOptions}
                  renderOption={(props, option: any) => {
                    return (
                      <li {...props} key={option.id} value={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.clinicSiteId}
                      helperText={errors.clinicSiteId?.message}
                      margin="normal"
                      fullWidth
                      label={t('patient.patientInfo.site')}
                    />
                  )}
                  sx={{
                    width: !patientId && currentUser?.roleId === Role.SuperAdmin ? '49%' : '100%',
                    [theme.breakpoints.down('md')]: {
                      width: '100%',
                    },
                  }}
                />
              )}
            />
          )}
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                margin="normal"
                fullWidth
                label={t('patient.patientInfo.firstName')}
                variant="outlined"
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                margin="normal"
                fullWidth
                label={t('patient.patientInfo.lastName')}
                variant="outlined"
              />
            )}
          />
          <Controller
            name="externalId"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                error={!!errors.externalId}
                helperText={errors.externalId?.message}
                margin="normal"
                fullWidth
                label={t('patient.patientInfo.externalId')}
                variant="outlined"
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                error={!!errors.email}
                helperText={errors.email?.message}
                margin="normal"
                fullWidth
                label={t('patient.patientInfo.email')}
                variant="outlined"
                onChange={handleEmailChange}
              />
            )}
          />
          <Controller
            name="cellPhoneNumber"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                error={!!errors.cellPhoneNumber}
                helperText={errors.cellPhoneNumber?.message}
                margin="normal"
                fullWidth
                label={t('patient.patientInfo.cellPhone')}
                variant="outlined"
                onChange={handleCellPhoneChange}
              />
            )}
          />
          <Controller
            name="homePhoneNumber"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                error={!!errors.homePhoneNumber}
                helperText={errors.homePhoneNumber?.message}
                margin="normal"
                fullWidth
                label={t('patient.patientInfo.homePhone')}
                variant="outlined"
              />
            )}
          />
          <Controller
            name="workPhoneNumber"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                error={!!errors.workPhoneNumber}
                helperText={errors.workPhoneNumber?.message}
                margin="normal"
                fullWidth
                label={t('patient.patientInfo.workPhone')}
                variant="outlined"
              />
            )}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              columnGap: 1,
              width: matches ? '100%' : '49%',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="birthdate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    disableFuture
                    inputFormat="MM/DD/YYYY"
                    renderInput={(params) => (
                      <StyleTextField
                        {...params}
                        error={!!errors.birthdate}
                        helperText={errors.birthdate?.message}
                        margin="normal"
                        fullWidth
                        label={t('patient.patientInfo.dob')}
                        variant="outlined"
                      />
                    )}
                  />
                )}
              />
            </LocalizationProvider>

            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  error={!!errors.gender}
                  helperText={errors.gender?.message}
                  margin="normal"
                  select
                  fullWidth
                  label={t('patient.patientInfo.gender')}
                  variant="outlined"
                >
                  {Genders.map((gender) => (
                    <MenuItem key={gender.value} value={gender.value}>
                      {gender.name}
                    </MenuItem>
                  ))}
                </StyleTextField>
              )}
            />
          </Box>
          <Controller
            name="race"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                error={!!errors.race}
                helperText={errors.race?.message}
                margin="normal"
                select
                fullWidth
                label={t('patient.patientInfo.race')}
                variant="outlined"
              >
                {Races.map((race) => (
                  <MenuItem key={race} value={race}>
                    {race}
                  </MenuItem>
                ))}
              </StyleTextField>
            )}
          />

          <Controller
            name="country"
            control={control}
            render={({ fieldState, formState, field: { value }, ...props }) => (
              <StyleAutocomplete
                {...props}
                data-testid="mui-component-select-country"
                options={Object.values(sortedCountryCodes)}
                getOptionLabel={(option: any) => (option.name ? option.name : '')}
                onChange={handleCountryChange}
                value={value ? sortedCountryCodes[value] : null}
                filterOptions={filterOptions}
                renderOption={(props, option: any) => {
                  return (
                    <li {...props} key={option.code} value={option.code}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.country}
                    helperText={errors.country?.message}
                    margin="normal"
                    fullWidth
                    label={t('patient.patientInfo.country')}
                  />
                )}
              />
            )}
          />

          <Controller
            name="addressLine1"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                error={!!errors.addressLine1}
                helperText={errors.addressLine1?.message}
                margin="normal"
                fullWidth
                label={t('patient.patientInfo.addressLine1')}
                variant="outlined"
              />
            )}
          />
          <Controller
            name="addressLine2"
            control={control}
            render={({ field }) => (
              <StyleTextField
                {...field}
                error={!!errors.addressLine2}
                helperText={errors.addressLine2?.message}
                margin="normal"
                fullWidth
                label={t('patient.patientInfo.addressLine2')}
                variant="outlined"
              />
            )}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: matches ? 'column' : 'row',
              justifyContent: 'space-between',
              width: matches ? '100%' : '49%',
            }}
          >
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  error={!!errors.city}
                  helperText={errors.city?.message}
                  margin="normal"
                  fullWidth
                  label={t('patient.patientInfo.city')}
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="state"
              control={control}
              render={({ fieldState, formState, field, ...props }) =>
                isUSA ? (
                  <StyleAutocomplete
                    {...props}
                    data-testid="mui-component-select-state"
                    options={usStates}
                    getOptionLabel={(option: any) => (option.name ? option.name : '')}
                    onChange={handleStateChange}
                    value={
                      field.value ? usStates.find((state) => state.code === field.value) : null
                    }
                    filterOptions={filterOptions}
                    renderOption={(props, option: any) => {
                      return (
                        <li {...props} key={option.code} value={option.code}>
                          {option.name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors.state}
                        helperText={errors.state?.message}
                        margin="normal"
                        fullWidth
                        label={stateLabel}
                      />
                    )}
                  />
                ) : (
                  <StyleTextField
                    {...field}
                    error={!!errors.state}
                    helperText={errors.state?.message}
                    margin="normal"
                    fullWidth
                    label={stateLabel}
                    variant="outlined"
                  />
                )
              }
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: matches ? 'column' : 'row',
              justifyContent: 'initial',
              width: matches ? '100%' : '49%',
            }}
          >
            <Controller
              name="zip"
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  error={!!errors.zip}
                  helperText={errors.zip?.message}
                  margin="normal"
                  fullWidth
                  label={t('patient.patientInfo.zipCode')}
                  variant="outlined"
                />
              )}
            />
            {accountSmsEnabled && (
              <Controller
                name="smsOptIn"
                control={control}
                render={() => (
                  <FormControlLabel
                    sx={{
                      paddingLeft: matches ? '0px' : '15px',
                    }}
                    control={<StyledCheckbox />}
                    checked={smsOptInValue}
                    value={smsOptInValue}
                    onChange={handleSmsOptInChange}
                    label={`${t('patient.patientInfo.smsOptIn')}`}
                    disabled={disabledSmsOptIn}
                  />
                )}
              />
            )}
          </Box>
        </Box>
      </RoundedContainer>

      <RoundedContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: matches ? 'column' : 'row',
            justifyContent: 'space-between',
            width: '50%',
          }}
        >
          <Controller
            name="optInPatientReminders"
            control={control}
            render={({ field }) => (
              <FormGroup>
                <FormControlLabel
                  {...field}
                  control={<StyledCheckbox />}
                  checked={isSendPatientReminders}
                  value={isSendPatientReminders}
                  onChange={(event: any) => {
                    const isChecked = event.target.checked;
                    setIsSendPatientReminders(isChecked);
                    setValue('optInPatientReminders', isChecked);
                  }}
                  label={`${t('patient.patientInfo.optInPatientReminders')}`}
                />
              </FormGroup>
            )}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: matches ? 'column' : 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Controller
            name="reminderEmails"
            control={control}
            render={({ field }) => (
              <AutoCompleteWithChips
                data={[...(data?.reminderEmails ?? [])]}
                extendedItem={extendedEmail}
                field={field}
                matches={matches}
                setValue={setValue}
                disabled={!isSendPatientReminders}
                label={t('patient.patientInfo.reminderEmails')}
                placeholder={t('patient.patientInfo.reminderEmails')}
                error={!!errors.reminderEmails}
                helperText={
                  (!!errors.reminderEmails &&
                    (errors?.reminderEmails as unknown as FieldError)?.message) ||
                  (errors?.reminderEmails &&
                    errors?.reminderEmails?.length > 0 &&
                    t('patient.error.invalidEmail'))
                }
              />
            )}
          />

          <Controller
            name="reminderPhones"
            control={control}
            render={({ field }) => (
              <AutoCompleteWithChips
                data={[...(data?.reminderPhones || [])]}
                extendedItem={extendedPhone}
                field={field}
                matches={matches}
                setValue={setValue}
                disabled={!isSendPatientReminders}
                label={t('patient.patientInfo.reminderPhones')}
                placeholder={t('patient.patientInfo.reminderPhones')}
                error={!!errors.reminderPhones}
                helperText={
                  (!!errors.reminderPhones &&
                    (errors?.reminderPhones as unknown as FieldError)?.message) ||
                  (errors?.reminderPhones &&
                    errors?.reminderPhones?.length > 0 &&
                    t('patient.error.invalidReminderPhones'))
                }
              />
            )}
          />
        </Box>
      </RoundedContainer>

      <RoundedContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: matches ? 'column' : 'row',
            justifyContent: 'space-between',
            width: '50%',
          }}
        >
          <Controller
            name="sendIntakePacket"
            control={control}
            render={({ field }) => (
              <FormGroup {...field}>
                <FormControlLabel
                  control={<StyledCheckbox />}
                  checked={isSendIntakePacket}
                  value={isSendIntakePacket}
                  onChange={(event: any) => {
                    const isChecked = event.target.checked;
                    setIsSendIntakePacket(isChecked);
                    if (!isChecked) {
                      setValue('sendIntakePacket', isChecked);
                      setValue('providerId', undefined);
                    }
                  }}
                  disabled={isDisabledSendIntakePacket}
                  label={`${t('patient.patientInfo.sendIntakePacket')}`}
                />
              </FormGroup>
            )}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: matches ? 'column' : 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Controller
            name="providerId"
            control={control}
            render={({ fieldState, formState, field: { value }, ...props }) => (
              <StyleAutocomplete
                {...props}
                data-testid="mui-component-select-provider"
                options={providerData!}
                getOptionLabel={(option: any) =>
                  option.firstName && option.lastName
                    ? `${option.lastName}, ${option.firstName}`
                    : ''
                }
                onChange={handleProviderChange}
                disabled={!isSendIntakePacket}
                value={value ? providerData?.find((item) => item.id === value) || null : null}
                filterOptions={filterProviderOptions}
                renderOption={(props, option: any) => {
                  return (
                    <li {...props} key={option.id} value={option.id}>
                      {`${option.lastName}, ${option.firstName}`}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={isSendIntakePacket ? !!errors.providerId : false}
                    helperText={isSendIntakePacket ? errors.providerId?.message : ''}
                    margin="normal"
                    fullWidth
                    label={t('patient.patientInfo.provider')}
                  />
                )}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
            pt: 2,
          }}
        >
          {!matches && <Divider sx={{ width: '100%' }} />}

          {pageErrors.length > 0 &&
            pageErrors.map((error) => (
              <Alert key={error} severity="error" sx={{ width: '100%', mt: 1 }}>
                {error || 'this is errors'}
              </Alert>
            ))}
          {data?.patientStatus === PatientStatus.Active && data?.mfaEnabled && (
            <Link
              sx={{ fontSize: 16, mt: 2 }}
              data-testid="reset-mfa"
              type="button"
              component="button"
              variant="body1"
              onClick={() => handleResetPatientMfa()}
            >
              {t('mfa.forms.reset')}
            </Link>
          )}
          <Button
            type="submit"
            data-testid="submit-button"
            fullWidth
            variant="contained"
            sx={{
              width: matches ? '100%' : '24%',
              mt: 2,
              py: 2,
              float: 'right',
            }}
          >
            {patientId
              ? t('patient.editPatient.updatePatient')
              : t('patient.addPatient.createPatient')}
          </Button>
        </Box>
      </RoundedContainer>
    </Box>
  );
}
