import { AssessmentType } from '../common/utils/assessmentType';
import { MeasurementPacket } from './InterviewRequest';
import { Provider } from './UserTypes';

export enum AssessmentMode {
  Clinician = 'clinician',
  Patient = 'patient',
  Schedule = 'schedule',
  ExternalPatient = 'externalPatient',
  ExternalClinician = 'externalClinician',
}

export interface LatestInterviewsItem {
  id: number;
  name: string;
  status: string;
  createdAt: Date;
  completedAt: Date;
  trial: boolean;
  createdTs: number;
  reducedTypeId: number;
  editExpireTimeStart?: Date;
  assessmentMode?: AssessmentMode;
}
export interface InterviewType {
  interviewTypeId: number;
  name: string;
  language: string;
  status: string;
  timeframeForLanguages: string;
  reportType: string;
  languages: string;
  enableSchedule?: boolean;
  order: number;
  isNotSendEmailToClinician?: boolean;
  isSignature?: boolean;
}

export interface InterviewTypesResponse {
  interviewTypes: InterviewType[];
  hasRestrictedTimeframes: boolean;
  allowTrial: boolean;
  patientSMSOptIn: boolean;
  patientHasEmail: boolean;
  userRole: string;
}

export interface PatientBasicInfo {
  name: string;
  patientId: string;
  externalId: string;
}

export interface LatestInterviewsResponse {
  items: LatestInterviewsItem[];
  totalItems: number;
}

export interface LatestInterviewsRequest {
  patientId: string;
  page: number;
  pageSize: number;
  interviewResultIds?: number[];
  interviewTypeId?: number;
}

export interface InterviewTypeRequest {
  patientId: string;
}

export interface PatientDetailRequest {
  patientId: string;
  status?: string;
  isWriteLog?: boolean;
}

export interface PatientNoteRequest {
  patientId: string;
  title: string;
  body: string;
}
export interface CreatePatientNoteResponse {
  patientId: string;
  title: string;
  body: string;
  id: number;
}
export interface PatientNote {
  author: string;
  title: string;
  body: string;
  dateAdded: Date;
  id: number;
}
export interface PatientNoteResponse {
  patientNotes: PatientNote[];
}

export interface PatientEditRequest {
  patientId: string;
  data?: PatientInfo;
}

export interface FirstLastInterviewsRequest {
  patientId: string;
}

export interface FirstLastInterviewsResponse {
  first: LatestInterviewsItem;
  last: LatestInterviewsItem;
}

export interface PatientInfo {
  clinicAccountId: string;
  clinicSiteId: number;
  clinicSiteName?: string;
  clinicSiteStatus?: string;
  patientId: string;
  firstName: string;
  lastName: string;
  externalId: string;
  email: string;
  cellPhoneNumber: string;
  homePhoneNumber: string;
  workPhoneNumber: string;
  birthdate: Date | any;
  gender: string;
  race: string;
  addressLine1: string;
  addressLine2: string;
  country: string;
  city: string;
  state: string;
  zip: string;
  patientStatus?: string;
  mfaEnabled?: boolean;
  providerId?: number;
  sendIntakePacket?: boolean;
  smsOptIn: boolean;
  timezone?: string;
  optInPatientReminders?: boolean;
  reminderEmails?: string[];
  reminderPhones?: string[];
}

export interface InterviewInfo {
  patientId: string;
  interview: string;
  interviewMode: string;
  measurementPackets?: MeasurementPacket[];
  language: string;
  timeFrames: string;
  trialInterview: boolean;
  interval: string;
  startDate: Date | any;
  endDate: Date | any;
  providerId: number;
}

export interface Packet {
  interviewId: string;
  order: number;
}

export interface MonitorInfo {
  patientId: string;
  language: string;
  monitor: string;
  monitorMode: string;
  interval: string;
  startDate: Date | any;
  endDate: Date | any;
  providerId: number;
}

export interface LatestMonitorsRequest {
  patientId: string;
  page: number;
  pageSize: number;
  monitorResultIds?: number[];
  monitorTypeId?: number;
}

export interface LatestMonitorsItem {
  id: number;
  name: string;
  status: string;
  createdAt: Date;
  completedAt: Date;
  createdTs: number;
  reducedTypeId: number;
  assessmentMode?: AssessmentMode;
}
export interface LatestMonitorsResponse {
  items: LatestMonitorsItem[];
  totalItems: number;
}

export interface FirstLastMonitorsRequest {
  patientId: string;
}

export interface FirstLastMonitorsResponse {
  first: LatestMonitorsItem;
  last: LatestMonitorsItem;
}

export interface LatestAssessmentItem {
  id: number;
  name: string;
  status: string;
  createdAt: Date;
  completedAt: Date;
  type: AssessmentType;
  trial?: boolean;
  createdTs: number;
  reducedTypeId: number;
  hasInterval?: boolean;
  canEditReport?: boolean;
  editExpireTimeStart?: Date;
  isSignature?: boolean;
  assessmentMode?: AssessmentMode;
}

export type CustomPatient = {
  id: string;
  firstName?: string;
  lastName?: string;
  externalId?: string;
};

export interface GetPatientResponse {
  patients: CustomPatient[];
}

// Patient List
export interface PatientListItem {
  id: string;
  firstName?: string;
  lastName?: string;
  externalId?: string;
  clinicAccountId: string;
  clinicAccountName: string;
  clinicSiteId: number;
  clinicSiteName: string;
  assessmentName?: string;
  status?: string;
  type: AssessmentType;
  createdAt?: Date;
  startedAt?: Date;
  completedAt?: Date;
  reducedTypeId?: number;
  trial?: boolean;
  hasInterval?: boolean;
  name?: string;
  latestAssessment?: LatestAssessmentItem;
  assessmentId?: number;
}

export interface PatientListResponse {
  patients: PatientListItem[];
  totalItems: number;
}
export interface PatientListRequest {
  search?: string;
  page?: number;
  pageSize?: number;
  sort?: string;
  status?: string;
}

export interface LatestAssessmentByPatientItem extends LatestAssessmentItem {
  patientId: string;
}

export interface LatestInterviewResultsByPatientResponse {
  interviewResults?: LatestAssessmentByPatientItem[];
}

export interface LatestInterviewsByPatientsResponse {
  interviewResults: LatestAssessmentByPatientItem[];
  totalItems: number;
}

export interface LatestInterviewsByPatientsRequest {
  patientIds: string[];
}

export interface LatestMonitorsByPatientsResponse {
  monitorResults: LatestAssessmentByPatientItem[];
  totalItems: number;
}

export interface LatestMonitorsByPatientsRequest {
  patientIds: string[];
}

export interface LatestAssessmentRequest {
  patientId: string;
  assessmentType: AssessmentType;
  assessmentTypeId?: number;
  page: number;
  pageSize: number;
}

export interface AssessmentItem {
  id: number;
  name: string;
  status: string;
  createdAt: Date;
  completedAt: Date;
  trial: boolean;
  createdTs: number;
  reducedTypeId: number;
  totalScore?: number;
  stdScore?: number;
  hasInterval?: boolean;
  editExpireTimeStart?: Date;
  isSignature?: boolean;
}

export interface LatestAssessmentResponse {
  items: AssessmentItem[];
  totalItems: number;
}

export interface ClinicalFollowUpsRequest {
  patientId: string;
  interviewTestId?: number;
}

export interface CheckHasClinicalFollowUpsResponse {
  hasFollowUps: boolean;
  interviewStatus?: string;
}

export interface ClinicalFollowUpsResponse {
  patientId: string;
  nextSteps: NextStepType[];
  metScoringLineItems: string[];
}

export interface NextStepType {
  id: number;
  text: string;
  followUpText: string;
  type: number;
  action: number | string;
  language: string;
  interviewTestIds: number[];
}

export interface UpdateFollowUpStatusRequest {
  patientId: string;
  id: number;
  status: string;
}

export interface CommonPatientRequest {
  patientId: string;
  checkDiagnoses?: boolean;
  patientDiagnosisId?: number;
}

export interface CommonItem {
  id: number;
  name: string;
  availableTimeframes: string[];
}

export interface DiagnosesResponse {
  diagnoses: CommonItem[];
  otherDiagnosesTimeFrames: string[];
}

export interface PatientDiagnosisInfo {
  patientDiagnosisId: number;
  patientId: string;
  patientName: string;
  patientDiagnosisStatus: string;
  diagnosisId: number;
  diagnosisName: string;
  displayName?: string;
  timeFrame: string;
  availableTimeframes: string[];
  otherDiagnosis: string;
  createdAt: string;
}

export interface DiagnosisItemsResponse {
  patientDiagnoses: PatientDiagnosisInfo[];
}

export interface DiagnosisInfoRequest {
  patientId: string;
  patientDiagnosisId: number;
}

export interface AddOrEditPatientDiagnosisRequest {
  patientId: string;
  patientDiagnosisId?: number | null;
  diagnosis?: number | null;
  timeFrame: string | null;
  otherDiagnosis?: string | null;
}

export interface AddOrEditPatientDiagnosisResponse {
  message: string;
}

export interface ImportPatientData {
  clinicAccount: string;
  file: File | undefined;
}

export interface ImportPatientResponse {
  message: string;
}

export interface ProviderRequest {
  patientId: string;
}

export interface ProviderResponse {
  providers: Provider[];
  defaultProviderId?: number;
}

export interface SendPatientReportToEHR {
  patientId: string;
}

export interface GenerateManagementReport {
  reportSheets?: { id: string; name: string }[];
}

export interface UnlockReportRequest {
  interviewResultId: number;
  patientId: string;
}

export interface UnlockReportResponse {
  interviewResultId: number;
  editExpireTimeStart: Date;
}

export interface TransferAssessmentRequest {
  patientSrc: string;
  patientDest: string;
  interviewResultId?: number;
  monitorResultId?: number;
}
