import { useSearchParams } from 'react-router-dom';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';

//TODO: Delete this page later
export default function TestiFramePage() {
  const [searchParams] = useSearchParams();
  const iframeSrc = searchParams.get('src');
  const [receivedMessage, setReceivedMessage] = useState<any>();

  const handleMessage = (event: any) => {
    if (event.data && event.data.type === 'finishedInterview') {
      console.log("Received your message:", event.data);
      setReceivedMessage(JSON.stringify(event.data));
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <DashboardLayout showBreadcrumb={false} sessionExpiration={false}>
      <RoundedContainer
        sx={{
          py: 3,
          '& .MuiBox-root': {
            py: 1,
          },
        }}
      >
        {receivedMessage &&
          <>
            <Typography fontWeight="bold">
              Received message:
            </Typography>
            <Typography>
              {receivedMessage}
            </Typography>
          </>
        }
        {iframeSrc ? (
          <iframe
            title={'title'}
            src={iframeSrc}
            style={{ height: '100vh', width: '100%' }}
          ></iframe>
        ) : (
          <>
            No iframe src, please add "?src=your_iframe_src" at the end of the url above. For example:
            ".../dashboard/test-iframe?src=https://yourpage"
          </>
        )}
      </RoundedContainer>
    </DashboardLayout>
  );
}
