import { forwardRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjsTimezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import DatePicker, { DatePickerProps } from '@mui/lab/DatePicker';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import theme from '../../app/theme';
import { utcTimeZones } from '../utils/dateTimeUtils';

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(dayjsTimezone);

interface Props extends Partial<DatePickerProps> {
  sx?: SxProps<Theme>;
  textFieldSx?: any;
  name: string;
  control: any;
  error: boolean;
  helperText: string;
  label: string;
  matches?: boolean;
  hideClearButton?: boolean;
  timezone?: string;
}

const commonTextFieldStyle = {
  [`&.MuiFormControl-root`]: {
    width: '100%',
  },
};

const CommonDatePicker = forwardRef<HTMLDivElement, Props>(
  (
    {
      name,
      control,
      error,
      helperText,
      label,
      textFieldSx,
      disabled,
      hideClearButton,
      timezone,
      ...datePickerProps
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState<boolean>(false);
    const convertValue = (value?: any, timezone?: string) => {
      if (value) {
        if (timezone) {
          return utcTimeZones.includes(timezone) ? dayjs.utc(value) : dayjs.tz(value, timezone);
        }
        return value;
      }
      return null;
    };
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            ref={ref}
            PaperProps={{
              sx: {
                position: 'relative',
              },
            }}
            value={convertValue(field.value, timezone)}
            inputFormat="MM/DD/YYYY"
            open={isOpen}
            onClose={() => setOpen(false)}
            disabled={disabled}
            showToolbar
            ToolbarComponent={(props: any) => {
              return (
                <Toolbar
                  sx={{
                    position: 'absolute',
                    minHeight: '39px !important',
                    bottom: 0,
                    left: 0,
                    zIndex: 99,
                    alignItems: 'start',
                  }}
                >
                  {props.children}
                  {!hideClearButton && (
                    <IconButton
                      sx={{
                        color: theme.palette.primary.main,
                        fontWeight: '500',
                        textTransform: 'uppercase',
                        fontSize: '0.875rem',
                        lineHeight: '1.75',
                        padding: '0px 8px',
                        borderRadius: 'unset',
                      }}
                      size="small"
                      onClick={() => {
                        field.onChange(null);
                        props.setOpenView(false);
                        setOpen(false);
                      }}
                    >
                      {t('treatmentPlans.clear')}
                    </IconButton>
                  )}
                </Toolbar>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={
                  textFieldSx
                    ? {
                        ...textFieldSx,
                      }
                    : { ...commonTextFieldStyle }
                }
                onClick={() => {
                  if (!disabled) {
                    if (!isOpen) {
                      const currentValue = field.value;
                      if (!currentValue) {
                        let currentDate;
                        if (timezone) {
                          currentDate = utcTimeZones.includes(timezone)
                            ? dayjs().utc()
                            : dayjs().tz(timezone);
                        } else {
                          currentDate = dayjs();
                        }
                        field.onChange(currentDate);
                      }
                    }
                    setOpen(true);
                  }
                }}
                error={!!error}
                helperText={helperText}
                margin="normal"
                fullWidth
                label={label}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
              />
            )}
            {...datePickerProps}
          />
        )}
      />
    );
  }
);
export default CommonDatePicker;
