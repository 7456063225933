import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { buildRedirectUrl } from '../../app/routes';
import { useRequestInterviewWithAuthTokenMutation } from '../../app/services/interview';
import LoadingPage from '../../common/components/LoadingPage';
import { ResponseError } from '../../types/ResponseError';
import ErrorPage from '../error/ErrorPage';

export default function IframePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { authToken, siteId, interviewTypeId, externalId } = useParams();
  const [requestInterviewWithAuthToken] = useRequestInterviewWithAuthTokenMutation();
  const notMissingInfo = authToken && siteId && interviewTypeId && externalId;

  const [pageErrors, setPageErrors] = useState<string[]>([]);

  useEffect(() => {
    const requestIframe = async () => {
      if (!notMissingInfo) {
        return;
      }

      try {
        const res = await requestInterviewWithAuthToken({
          authToken,
          siteId: Number(siteId),
          externalId,
          interviewTypeId: Number(interviewTypeId),
        }).unwrap();

        if (res?.patientConductUrl) {
          navigate(
            buildRedirectUrl(
              `${res?.patientConductUrl}?isFromIframe=true&interviewType=${
                res?.interviewType || ''
              }`
            )
          );
        } else {
          console.error(t('error.noConductUrl'));
          setPageErrors([t('error.noConductUrl')]);
        }
      } catch (e) {
        const {
          data: { error },
        } = e as ResponseError;
        setPageErrors(error);
      }
    };

    requestIframe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!notMissingInfo || !pageErrors || pageErrors.length) {
    return <ErrorPage hideHomeLink />;
  }

  return <LoadingPage />;
}
