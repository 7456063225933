import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch } from '../../app/hooks';
import { useLazyDownloadMasterUsageReportQuery } from '../../app/services/interview';
import { PageStatus, setPageStatus } from '../../common/slices/globalSlice';
import { masterReportTypes } from '../../common/utils/reportTypes';
import { MasterUsageReportSearch } from '../../types/MasterUsageReport';
import { ResponseError } from '../../types/ResponseError';
import { UsageReportSearch } from '../../types/UsageReport';

let defaultFormValues: MasterUsageReportSearch = {
  reportType: '',
  fromDate: undefined,
  toDate: undefined,
};

const StyleAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: '49%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const StyleDesktopDatePicker = styled(DesktopDatePicker)(({ theme }) => ({
  width: '49%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const validationDate = ({ fromDate, toDate }: { fromDate?: Date; toDate?: Date }) => {
  if ((fromDate && !toDate) || (!fromDate && toDate)) {
    return true;
  }
  if (fromDate && toDate) {
    const fromDateFormat = new Date(new Date(fromDate).toISOString().split('T')[0]);
    const toDateFormat = new Date(new Date(toDate).toISOString().split('T')[0]);
    if (fromDateFormat.getTime() > toDateFormat.getTime()) {
      return false;
    }
  }
  return true;
};

export default function MasterUsageReportFilterForm() {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const currentDate = dayjs(new Date()).format('MM/DD/YYYY');

  const [trigger, { isLoading, error: downloadError }] = useLazyDownloadMasterUsageReportQuery();
  const downloadMasterUsageReport = trigger;

  const isValidInputDate = (inputDate: string) => dayjs(inputDate, 'MM/DD/YYYY').isValid();

  const searchOptions = {
    reportType: searchParams.get('reportType') || '',
    fromDate: searchParams.get('fromDate') || currentDate,
    toDate: searchParams.get('toDate') || currentDate,
  };

  defaultFormValues = {
    reportType: searchOptions.reportType,
    fromDate: isValidInputDate(searchOptions.fromDate)
      ? new Date(searchOptions.fromDate)
      : undefined,
    toDate: isValidInputDate(searchOptions.fromDate) ? new Date(searchOptions.toDate) : undefined,
  };
  const [fromDate, setFromDate] = useState<string>(
    isValidInputDate(searchOptions.fromDate) ? searchOptions.fromDate : ''
  );
  const [toDate, setToDate] = useState<string>(
    isValidInputDate(searchOptions.toDate) ? searchOptions.toDate : ''
  );
  const [fromDateError, setFromDateError] = useState<string>('');
  const [toDateError, setToDateError] = useState<string>('');

  useEffect(() => {
    if (isLoading) {
      dispatch(setPageStatus({ pageStatus: PageStatus.Loading }));
    } else {
      dispatch(setPageStatus({ pageStatus: PageStatus.Idle }));
    }
  }, [dispatch, isLoading]);

  useEffect(() => {
    if ((downloadError as any)?.data?.error?.length) {
      setPageErrors((downloadError as any)?.data?.error);
    } else {
      setPageErrors([]);
    }
  }, [downloadError]);

  const validationSchema = yup
    .object({
      reportType: yup.string().required(t('masterUsageReport.error.blankReportType')),
      fromDate: yup
        .date()
        .required(t('masterUsageReport.error.blankFromDate'))
        .typeError(t('masterUsageReport.error.blankFromDate'))
        .test('from-date', '', function (fromDateValue) {
          const toDateValue = getValues('toDate') || undefined;
          const isValidDate = isValidInputDate(fromDate);
          if (!isValidDate && fromDateValue) {
            return false;
          }
          if (fromDateValue) {
            const isValidDate = validationDate({
              fromDate: fromDateValue,
              toDate: toDateValue,
            });

            if (!isValidDate) {
              setFromDateError(t('masterUsageReport.error.inValidDateRange'));
              return false;
            }
          }
          setFromDateError('');
          return true;
        })
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr)),

      toDate: yup
        .date()
        .required(t('masterUsageReport.error.blankToDate'))
        .typeError(t('masterUsageReport.error.blankToDate'))
        .test('from-date', '', function (toDateValue) {
          const fromDateValue = getValues('fromDate') || undefined;
          const isValidDate = isValidInputDate(toDate);
          if (!isValidDate && toDateValue) {
            return false;
          }
          if (toDateValue) {
            const isValidDate = validationDate({
              fromDate: fromDateValue,
              toDate: toDateValue,
            });

            if (!isValidDate) {
              setToDateError(t('masterUsageReport.error.inValidToDateRange'));
              return false;
            }
          }
          setToDateError('');
          return true;
        })
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr)),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm<MasterUsageReportSearch>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const handleReportTypeChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('reportType', selectedValue);
  };

  const getReportTypeOptionLabel = (option: any) => `${option.name}`;

  const handleReportFromDateChange = (newDateValue: any, inputValue: any) => {
    if (newDateValue) {
      const isValidDate = dayjs(newDateValue).isValid();
      if (!isValidDate) {
        setFromDate(inputValue);
        setFromDateError(t('masterUsageReport.error.inValidFromDate'));
      } else {
        const dateString = dayjs(newDateValue).format('MM/DD/YYYY');
        setValue('fromDate', new Date(dateString));
        setFromDate(dateString);
        setFromDateError('');
      }
    } else {
      setValue('fromDate', newDateValue);
    }
  };

  const handleReportToDateChange = (newDateValue: any, inputValue: any) => {
    if (newDateValue) {
      const isValidDate = dayjs(newDateValue).isValid();
      if (!isValidDate) {
        setToDate(inputValue);
        setToDateError(t('masterUsageReport.error.inValidToDate'));
      } else {
        const dateString = dayjs(newDateValue).format('MM/DD/YYYY');
        setValue('toDate', new Date(dateString));
        setToDate(dateString);
        setToDateError('');
      }
    } else {
      setValue('toDate', newDateValue);
    }
  };

  const onSubmit = async (data: UsageReportSearch) => {
    setPageErrors([]);
    try {
      downloadMasterUsageReport({
        reportType: data.reportType,
        fromDate,
        toDate,
      });
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        px: matches ? 0 : 3,
        pt: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Controller
          name="reportType"
          control={control}
          render={({ fieldState, formState, field: { value }, ...props }) => (
            <StyleAutocomplete
              {...props}
              data-testid="mui-component-select-report-type"
              options={masterReportTypes || []}
              getOptionLabel={getReportTypeOptionLabel}
              onChange={handleReportTypeChange}
              value={value ? masterReportTypes.find((item) => item.id === value) || [] : null}
              renderOption={(props, option: any) => {
                return (
                  <li {...props} key={option.id} value={option.id}>
                    {getReportTypeOptionLabel(option)}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors.reportType}
                  helperText={errors.reportType?.message}
                  margin="normal"
                  fullWidth
                  label={t('masterUsageReport.reportType')}
                />
              )}
              sx={{
                width: '100%',
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                },
              }}
            />
          )}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '49%',
              [theme.breakpoints.down('md')]: {
                width: '100%',
              },
            }}
          >
            <Controller
              name="fromDate"
              control={control}
              render={({ field }) => (
                <StyleDesktopDatePicker
                  {...field}
                  label={t('masterUsageReport.from')}
                  inputFormat="MM/DD/YYYY"
                  value={fromDate}
                  onChange={handleReportFromDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      data-testid="mui-component-from-date"
                      sx={{
                        width: '100%',
                      }}
                      error={!!errors.fromDate || !!fromDateError}
                      helperText={errors.fromDate?.message}
                      margin="normal"
                      variant="outlined"
                      aria-describedby="from-date-helper-text"
                    />
                  )}
                />
              )}
            />
            <FormHelperText id="from-date-helper-text" error={true}>
              {fromDateError}
            </FormHelperText>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '49%',
              [theme.breakpoints.down('md')]: {
                width: '100%',
              },
            }}
          >
            <Controller
              name="toDate"
              control={control}
              render={({ field }) => (
                <StyleDesktopDatePicker
                  {...field}
                  label={t('masterUsageReport.to')}
                  inputFormat="MM/DD/YYYY"
                  value={toDate}
                  onChange={handleReportToDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      data-testid="mui-component-to-date"
                      sx={{
                        width: '100%',
                      }}
                      error={!!errors.toDate || !!toDateError}
                      helperText={errors.toDate?.message}
                      margin="normal"
                      variant="outlined"
                      aria-describedby="to-date-helper-text"
                    />
                  )}
                />
              )}
            />
            <FormHelperText id="to-date-helper-text" error={true}>
              {toDateError}
            </FormHelperText>
          </Box>
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          width: '100%',
          float: 'right',
          pb: 3,
        }}
      >
        {!matches && <Divider sx={{ py: 1 }} />}

        {pageErrors &&
          pageErrors.length > 0 &&
          pageErrors.map((error) => (
            <Alert key={error} severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          ))}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            width: matches ? '100%' : '24%',
            my: 2,
            py: 2,
            float: 'right',
          }}
        >
          {t('masterUsageReport.downloadReport')}
        </Button>
      </Box>
    </Box>
  );
}
