import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { BreadcrumbType } from '../../common/utils/editBreadcrums';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import BillingUsageReportForm from './BillingUsageReportForm';

export default function BillingUsageReportPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const newBreadcrumb: BreadcrumbType = {
    text: t('dashboard.title'),
    link: `/dashboard`,
  };

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('billingUsageReport.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 2,
            px: matches ? 2 : 3,
          }}
        >
          <PageHeader headerText={t('billingUsageReport.title')} backUrl={newBreadcrumb.link} />
        </Box>
      </Container>
      <RoundedContainer>
        <BillingUsageReportForm />
      </RoundedContainer>
    </DashboardLayout>
  );
}
