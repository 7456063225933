import { JSXElementConstructor, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { t } from 'i18next';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useAppSelector } from '../../../../app/hooks';
import {
  useRequestInterviewByTokenMutation,
  useRequestInterviewMutation,
} from '../../../../app/services/interview';
import theme from '../../../../app/theme';
import LocalPopupModal from '../../../../common/components/LocalPopupModal';
import ModuleList from '../../../../common/components/ModuleList';
import RoundedContainer from '../../../../common/components/RoundedContainer';
import { InterviewMode } from '../../../../common/utils/interviewsMode';
import { AnswerUnit, QuestionAnswerType } from '../../../../common/utils/questionAnswerTypes';
import { ContentPopup } from '../../../../types/CommonType';
import {
  InterviewAnswerValueDto,
  InterviewFormatDto,
  SequenceItemDto,
} from '../../../../types/InterviewQuestionTypes';
import {
  ConductInterviewProps,
  errorAnswerMessagesType,
  InterviewResponse,
} from '../../../../types/InterviewRequest';
import { PatientMonitorData } from '../../../../types/MonitorTypes';
import { PopupContent } from '../../../../types/PopupType';
import ClinicianNotesLink from '../../../clinician-notes/ClinicianNotesLink';
import InterviewSummaryError from '../../../error/InterviewSummaryError';
import { selectPatientMonitorData } from '../../../monitor/monitorSlide';
import usePaginationQuestionByGroup from '../../hooks/usePaginationQuestionByGroup';
import {
  PatientInterviewData,
  selectAnswerUnit,
  selectCurrentModuleId,
  selectErrorAnswerQuestions,
  selectIncompleteModuleId,
  selectInterview,
  selectInterviewMode,
  selectInterviewType,
  selectIsEditInterview,
  selectPatientInterviewData,
  selectPreviousAnswers,
  setCurrentInterview,
} from '../../interviewSlice';
import {
  getQuestionControlName,
  addQuestionHead,
  isNotChangeQuestion,
  convertObject,
  removeUndefinedValues,
} from '../../interviewUtils';
import ExtendedInterviewQuestion from '../ExtendedInterviewQuestion';
import BinaryGroup from './groupQuestions/BinaryGroup';
import MultiOptionsGroup from './groupQuestions/MultiOptionsGroup';
import OptionGroup from './groupQuestions/OptionGroup';
import TextGroup from './groupQuestions/TextGroup';
import MinimizeInfoNoAnswer from './questions/MinimizeInfoNoAnswer';

const StyledButton = styled(Button)(({ theme }) => ({
  width: '50%',
  padding: '12px 0px',
  borderRadius: '10px',
  [theme.breakpoints.up('md')]: {
    width: '20%',
    minWidth: '200px',
  },
}));

const componentGroupQuestionMap: { [type: string]: JSXElementConstructor<any> } = {
  [QuestionAnswerType.Text]: TextGroup,
  [QuestionAnswerType.Binary]: BinaryGroup,
  [QuestionAnswerType.Options]: OptionGroup,
  [QuestionAnswerType.MultiOptions]: MultiOptionsGroup,
};

export default function MiniMinimizeInterview({
  errors,
  control,
  setValue,
  getValues,
  defaultValues,
  currentSequenceItems,
  pageErrors,
  resetAllFields,
  handleSubmit,
  triggerValidation,
  scrollToTop,
  clearErrors,
  queueStillRunning,
}: ConductInterviewProps) {
  const errorAnswerMessages = useAppSelector<errorAnswerMessagesType>(selectErrorAnswerQuestions);
  const answerUnit = useAppSelector<AnswerUnit>(selectAnswerUnit);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { interviewResultId: inputInterviewResultId } = useParams();
  const currentInterview = useAppSelector<InterviewFormatDto>(selectInterview);
  const [requestInterview] = useRequestInterviewMutation();
  const [requestInterviewByToken] = useRequestInterviewByTokenMutation();
  const patientInterviewData = useAppSelector<PatientInterviewData>(selectPatientInterviewData);
  const patientMonitorData = useAppSelector<PatientMonitorData>(selectPatientMonitorData);
  const interviewMode = useAppSelector<string>(selectInterviewMode);
  const interviewType = useAppSelector<string>(selectInterviewType);
  const currentModuleId = useAppSelector<number>(selectCurrentModuleId);
  const incompleteModuleId = useAppSelector<number>(selectIncompleteModuleId);
  const interviewEdited = useAppSelector<boolean>(selectIsEditInterview);
  const previousAnswers =
    useAppSelector<Record<number, InterviewAnswerValueDto>>(selectPreviousAnswers);
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<any>({});
  const [tempModuleId, setTempModuleId] = useState<number | undefined>(undefined);
  const currentModule = (currentInterview?.modules && currentInterview.modules[0]) || {};

  useEffect(() => {
    if (previousAnswers) {
      const initial = convertObject(previousAnswers);
      setInitialData(initial);
    }
  }, [previousAnswers]);

  const contentPopup: ContentPopup = {
    title: t('interview.form.warningPopup.title'),
    description: t('interview.form.warningPopup.description'),
    btnOk: t('interview.form.warningPopup.btnOk'),
    btnClose: t('interview.form.warningPopup.btnClose'),
  } as PopupContent;

  const interviewResultId =
    parseInt(inputInterviewResultId!) || patientInterviewData.interviewTestId;

  const isEditInterview = pathname?.includes('edit-interview');
  const isClinicianInterview = interviewMode === InterviewMode.Clinician || isEditInterview;

  const {
    currentGroup,
    handleNextPage,
    handlePreviousPage,
    isLastPage,
    currentPage,
    currentControlNames,
    resetCurrentPage,
  } = usePaginationQuestionByGroup(currentSequenceItems || []);

  const closePopup = async () => {
    setIsOpenPopup(false);
  };

  const handleModuleChange = async (moduleId: number) => {
    const values = removeUndefinedValues(getValues());
    if (
      (currentModule.id! !== incompleteModuleId || interviewEdited) &&
      !isNotChangeQuestion(values, initialData)
    ) {
      setTempModuleId(moduleId);
      showWarningPopup();
    } else {
      let interviewResponse: InterviewResponse | null = null;
      if (isClinicianInterview && interviewType !== InterviewMode.ExternalClinician) {
        interviewResponse = await requestInterview({
          interviewTestId: interviewResultId,
          moduleId: moduleId,
        }).unwrap();
      } else if (patientInterviewData || patientMonitorData) {
        interviewResponse = await requestInterviewByToken({
          accessToken: patientInterviewData?.accessToken || patientMonitorData?.accessToken,
          moduleId: moduleId,
        }).unwrap();
      }
      if (interviewResponse) {
        resetAllFields();
        dispatch(setCurrentInterview({ interviewData: interviewResponse }));
        resetCurrentPage();
        scrollToTop?.();
      }
    }
  };

  const handleSubmitMinimizeInterview = async () => {
    if (isLastPage) {
      handleSubmit();
      resetCurrentPage();
    } else if (await triggerValidation(currentControlNames)) {
      clearErrors?.();
      handleNextPage();
      scrollToTop?.();
    }
    scrollToTop?.();
  };

  const onGoToModule = async (successCallback?: () => void) => {
    setIsOpenPopup(false);
    let interviewResponse: InterviewResponse | null = null;

    if (isClinicianInterview && interviewType !== InterviewMode.ExternalClinician) {
      interviewResponse = await requestInterview({
        interviewTestId: interviewResultId,
        moduleId: tempModuleId,
      }).unwrap();
    } else if (patientInterviewData || patientMonitorData) {
      interviewResponse = await requestInterviewByToken({
        accessToken: patientInterviewData?.accessToken || patientMonitorData?.accessToken,
        moduleId: tempModuleId,
      }).unwrap();
    }

    if (interviewResponse) {
      resetAllFields();
      dispatch(setCurrentInterview({ interviewData: interviewResponse }));
      resetCurrentPage();
    }

    if (successCallback) {
      successCallback();
    }
  };

  const renderInterviewQuestion = (item: SequenceItemDto, isFromGroup?: boolean) => {
    const controlName = getQuestionControlName(item.questionId);
    const error = errors[controlName];
    const responseError = errorAnswerMessages && errorAnswerMessages[item.questionId];

    return (
      <Controller
        key={controlName}
        name={controlName}
        control={control}
        defaultValue={defaultValues[controlName] || ''}
        render={({ field }) => (
          <ExtendedInterviewQuestion
            isFromGroup={isFromGroup}
            questionId={item.questionId}
            question={item.question}
            error={!!error || !!responseError}
            helperText={error?.message || responseError}
            field={field}
            setValue={setValue}
            getValues={getValues}
            answerUnit={answerUnit}
            optionChangeCallback={undefined}
            stsQuestionRelative={undefined}
            disabled={undefined}
            isParentQuestion={false}
            checkListChangeCallback={undefined}
            allQuestions={[]}
            isStsSdsQuestion={false}
            sequenceOrder={item.sequenceOrder}
            isMinimalInterview={true}
          />
        )}
      />
    );
  };

  const renderInfoNoAnswer = (item: SequenceItemDto) => {
    return <MinimizeInfoNoAnswer {...item} />;
  };

  const showWarningPopup = async () => {
    setIsOpenPopup(true);
  };

  return (
    <>
      <ModuleList
        activeModule={currentModule.id}
        currentModule={currentModuleId}
        inCompleteModule={incompleteModuleId}
        moduleLettersForScoring={currentInterview.moduleLettersForScoring}
        modulesForScoring={currentInterview.modulesForScoring}
        skippedModules={currentInterview.skippedModules}
        handleModuleChange={handleModuleChange}
      />
      {currentGroup && currentGroup.length !== 0 ? (
        <Box sx={{ mb: 2 }}>
          {currentGroup
            ?.filter((item: SequenceItemDto | SequenceItemDto[]) => {
              if (Array.isArray(item)) {
                return item.some(
                  (subItem) =>
                    subItem.question &&
                    subItem.question.questionAnswerType !== QuestionAnswerType.InfoNoAnswer
                );
              } else {
                return item.question;
              }
            })
            .map((item: SequenceItemDto | SequenceItemDto[]) => {
              if (Array.isArray(item)) {
                const GroupQuestionComponent =
                  componentGroupQuestionMap[item[0].question.questionAnswerType];
                let updatedItem = item[0];
                if (updatedItem.question.questionAnswerType !== QuestionAnswerType.MultiOptions) {
                  updatedItem = addQuestionHead(item[0], isClinicianInterview);
                }
                const newItems = [updatedItem, ...item.slice(1)];
                return (
                  <GroupQuestionComponent
                    item={newItems}
                    renderInterviewQuestion={renderInterviewQuestion}
                  />
                );
              } else {
                if (item.question.questionAnswerType === QuestionAnswerType.InfoNoAnswer) {
                  return (
                    <RoundedContainer key={item.questionId} sx={{ py: 2, mb: 2 }}>
                      {renderInfoNoAnswer(item)}
                    </RoundedContainer>
                  );
                } else if (item.question.questionAnswerType !== QuestionAnswerType.Formula) {
                  let updatedItem = item;
                  updatedItem = addQuestionHead(item, isClinicianInterview, true);
                  return (
                    <RoundedContainer key={`question-${item.questionId}`} sx={{ py: 2, mb: 2 }}>
                      {renderInterviewQuestion(updatedItem)}
                      {isClinicianInterview && (
                        <ClinicianNotesLink
                          interviewTestId={interviewResultId}
                          questionIds={[item.questionId]}
                        ></ClinicianNotesLink>
                      )}
                    </RoundedContainer>
                  );
                } else {
                  return <></>;
                }
              }
            })}
        </Box>
      ) : (
        <RoundedContainer sx={{ py: 2, mb: 2 }}>
          {t('interview.form.errors.noQuestionDisplay')}
        </RoundedContainer>
      )}

      <RoundedContainer
        sx={{
          py: 2,
          mb: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {pageErrors.length > 0 &&
          pageErrors.map((error: string) => (
            <Alert key={error} severity="error" sx={{ my: 2 }}>
              {error}
            </Alert>
          ))}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 1,
          }}
        >
          <InterviewSummaryError errors={errors}></InterviewSummaryError>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'row',
              columnGap: 1,
              [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                columnGap: 1,
              },
            }}
          >
            {!!currentPage && (
              <StyledButton
                sx={{
                  my: 1,
                  width: '100%',
                }}
                disabled={queueStillRunning}
                variant="contained"
                onClick={() => {
                  clearErrors?.();
                  handlePreviousPage();
                  scrollToTop?.();
                }}
              >
                {t('interview.form.previous')}
              </StyledButton>
            )}
            <StyledButton
              sx={{
                my: 1,
                width: '100%',
              }}
              disabled={queueStillRunning}
              type="button"
              variant="contained"
              onClick={handleSubmitMinimizeInterview}
            >
              {queueStillRunning
                ? t('interview.form.saving')
                : currentModule.id ===
                  currentInterview.modulesForScoring[currentInterview.modulesForScoring.length - 1]
                ? isLastPage
                  ? t('interview.form.submit')
                  : t('interview.form.next')
                : t('interview.form.next')}
            </StyledButton>
          </Box>
          <LocalPopupModal
            isOpen={isOpenPopup}
            contentPopup={contentPopup}
            onSuccess={onGoToModule}
            onClose={closePopup}
          />
        </Box>
      </RoundedContainer>
    </>
  );
}
