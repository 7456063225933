import { buildAccountUrl, QueryCacheDuration, rootApi, TagTypes } from '.';
import { BillingSettingsRequest, BillingSettingsResponse } from '../../types/BillingType';

const api = rootApi
  .enhanceEndpoints({
    addTagTypes: [TagTypes.Site],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getBillingSettings: builder.query<BillingSettingsResponse, BillingSettingsRequest>({
        query: (params) => ({
          url: buildAccountUrl(`/v1/account/site/billing-settings`),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),
    }),
    overrideExisting: true,
  });

export const { useGetBillingSettingsQuery } = api;
