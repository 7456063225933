import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {
  useGetClinicAccountsQuery,
  useImportUserMutation,
  useGetSitesByUsersQuery,
} from '../../app/services/account';
import { useToast } from '../../app/toast';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { addBreadcrumb, BreadcrumbType } from '../../common/utils/editBreadcrums';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import { ImportUserRequest } from '../../types/UserTypes';
import ImportForm from './ImportForm';

export default function ImportUserPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const [importUser] = useImportUserMutation();

  const { data: accountData, isLoading: loadAccountsData } = useGetClinicAccountsQuery(
    undefined,
    {}
  );
  const { data: siteData, isLoading: loadSiteData } = useGetSitesByUsersQuery(undefined, {});

  const { state } = useLocation();
  const { navBackUrl } = !!state && state;
  const newBreadcrumb: BreadcrumbType = {
    text: t('user.manageUsers.title'),
    link: navBackUrl || `/dashboard/users`,
  };

  const onFormSubmit = async (data: ImportUserRequest, successCallback?: () => void) => {
    await importUser(data).unwrap();

    navigate('/dashboard/users');

    if (successCallback) {
      successCallback();
    }

    toast.publish(t('user.manageUsers.importSuccess'), 'success', 5000);
  };

  return (
    <DashboardLayout breadcrumbs={addBreadcrumb(breadcrumbs!, newBreadcrumb)}>
      <Helmet>
        <title>{getPageTitle(t('user.manageUsers.importUser'))}</title>
      </Helmet>

      <Container maxWidth="xl">
        <PageHeader
          sx={{ mt: 1, mb: 2 }}
          headerText={t('user.manageUsers.importUser')}
          backUrl={navBackUrl || `/dashboard/users`}
        />
      </Container>

      <RoundedContainer>
        {loadAccountsData && loadSiteData ? (
          <Box sx={{ my: 2 }}>
            <Loading />
          </Box>
        ) : (
          <ImportForm
            siteData={siteData?.siteData}
            clinicAccounts={accountData?.clinicAccounts}
            onFormSubmit={onFormSubmit}
          />
        )}
      </RoundedContainer>
    </DashboardLayout>
  );
}
