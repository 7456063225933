import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../app/hooks';
import { selectIsFromIframe } from '../../features/auth/authSlice';
import { PageBreadcrumb } from '../../types/PageBreadcrumb';
import { BaseProps } from '../../types/PageProps';
import Breadcrumb from '../components/Breadcrumb';
import CompletedFollowupModal from '../components/CompletedFollowupModal';
import { GlobalLoading } from '../components/GlobalLoading';
import Header from '../components/Header';
import SessionExpirationModal from '../components/SessionExpirationModal';
import { selectShowGlobalLoading } from '../slices/globalSlice';
import { pageHeightStyle } from '../utils/commonStyles';
import BaseLayout from './Base';

interface Props extends BaseProps {
  breadcrumbs?: PageBreadcrumb[];
  showBreadcrumb?: boolean;
  showHeader?: boolean;
  accountLogo?: string;
  sessionExpiration?: boolean;
}

export default function DashboardLayout({
  children,
  breadcrumbs,
  accountLogo,
  showBreadcrumb = true,
  showCopyright = true,
  showHeader = true,
  background = 'inherit',
  sessionExpiration = true,
}: Props) {
  const showGlobalLoading = useAppSelector(selectShowGlobalLoading);
  const isFromIframe = useAppSelector(selectIsFromIframe);
  const showBreadcrumbComponent = !isFromIframe && showBreadcrumb;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const appBarHeight = isSmallScreen ? 56 : 64;
  return (
    <BaseLayout showCopyright={showCopyright} background={background}>
      {showHeader && <Header accountLogo={accountLogo} />}
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          ...pageHeightStyle(theme),
        })}
      >
        {showBreadcrumbComponent && (
          <Container maxWidth="xl" sx={{ my: 2 }}>
            <Breadcrumb data={breadcrumbs} />
          </Container>
        )}
        {showGlobalLoading && <GlobalLoading appBarHeight={appBarHeight} />}
        {children}
      </Box>
      {sessionExpiration && !isFromIframe && <SessionExpirationModal />}
      <CompletedFollowupModal />
    </BaseLayout>
  );
}
