import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { styled, useTheme } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  useRequestMonitorByTokenMutation,
  useRequestMonitorMutation,
  useSubmitMonitorAnswersMutation,
  useSubmitMonitorAnswersByTokenMutation,
} from '../../app/services/monitor';
import { useGetPatientQuery } from '../../app/services/patient';
import PatientInfoCard from '../../common/components/PatientInfoCard';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { addBreadcrumb, BreadcrumbType } from '../../common/utils/editBreadcrums';
import { HttpStatusCode } from '../../common/utils/httpStatusCode';
import { checkCompareCondition } from '../../common/utils/monitorAnswerComparison';
import { MonitorModeName } from '../../common/utils/monitorsMode';
import { getPageTitle } from '../../common/utils/pageUtils';
import { QuestionAnswerType } from '../../common/utils/questionAnswerTypes';
import { PATIENT_ASSESSMENT_EXPIRE_TIME_MILLISECONDS } from '../../common/utils/securityConfig';
import {
  ClientDisplayConditionType,
  MonitorFormatDto,
  MonitorModuleType,
  MonitorSubmissionResponse,
  MonitorTypeResponse,
  PatientMonitorData,
  SequenceMonitorItemType,
  MonitorAnswerDto,
  MonitorSubmissionByTokenRequest,
  MonitorErrorAnswerMessagesType,
} from '../../types/MonitorTypes';
import { PageProps } from '../../types/PageProps';
import { MonitorQuestionResponseError, ResponseError } from '../../types/ResponseError';
import { selectIsAuthenticated } from '../auth/authSlice';
import ErrorPage from '../error/ErrorPage';
import usePatientSessionExpiration from '../interview/hooks/usePatientSessionExpiration';
import {
  PatientInterviewData,
  selectPatientInterviewData,
  clearPatientInterviewData,
} from '../interview/interviewSlice';
import { getQuestionControlName } from '../interview/interviewUtils';
import MonitorModule from './MonitorModule';
import {
  clearCurrentMonitor,
  clearPatientMonitorData,
  selectMonitor,
  selectMonitorMode,
  selectPatientMonitorData,
  setCurrentMonitor,
  setPatientMonitorData,
  setErrorFromMonitorResponse,
  selectErrorAnswerMessages,
  selectAssessmentUrl,
} from './monitorSlide';

const StyledButton = styled(Button)(({ theme }) => ({
  width: '50%',
  padding: '12px 0px',
  borderRadius: '10px',
  [theme.breakpoints.up('md')]: {
    width: '20%',
    minWidth: '200px',
  },
}));

const buildDefaultValueAndValidationObject = (
  t: TFunction,
  currentMonitorModules?: MonitorModuleType[]
) => {
  const validationData: any = {};
  const defaultValues: any = {};

  currentMonitorModules &&
    currentMonitorModules.forEach((currentModule: MonitorModuleType) => {
      const { sequenceOrders } = currentModule;
      sequenceOrders &&
        sequenceOrders.forEach((monitorItem: SequenceMonitorItemType) => {
          const { question, questionId } = monitorItem;
          const key = getQuestionControlName(questionId!);
          defaultValues[key] = '';

          if (
            question?.isRequired &&
            question?.questionAnswerType !== QuestionAnswerType.InfoNoAnswer &&
            question?.questionAnswerType !== QuestionAnswerType.Formula
          ) {
            validationData[key] = yup
              .string()
              .required(t('monitor.MonitorInstructionsPage.errors.blankAnswer'));
          }

          if (question?.questionAnswerType === QuestionAnswerType.Questionnaire) {
            defaultValues[key] = {
              answer: '',
              questionId: question?.id,
              subQuestions: [],
            };
            validationData[key] = yup
              .object({
                answer: yup.string().required(),
                questionId: yup.number().required(),
                subQuestions: yup.array().required(),
              })
              .test(
                'questionnaire-validate',
                t('monitor.MonitorInstructionsPage.errors.blankAnswer'),
                function ({ answer }) {
                  if (question.isRequired && (!answer || answer === 'NaN')) {
                    return false;
                  }
                  return true;
                }
              )
              .required()
              .nullable();
          }
        });
    });

  return { validationData, defaultValues };
};

const convertSubmitMonitorAnswers = (
  monitorResultId: number,
  submitAnswers: any,
  currentQuestionModules?: any
): any => {
  const answers: MonitorAnswerDto[] = [];

  const newCurrentSequenceMonitorItems = currentQuestionModules.reduce(
    (preModule: any, curModule: MonitorModuleType) => {
      return [...preModule, ...curModule.sequenceOrders!];
    },
    []
  );

  newCurrentSequenceMonitorItems?.forEach((monitorQuestions: any) => {
    const submitAnswer = submitAnswers[getQuestionControlName(monitorQuestions.question.id)];
    const { questionId, answer, subQuestions } = submitAnswer;

    let monitorQuestion: any = {};
    // Type 'object' meaning questionnaire
    if (typeof submitAnswer === 'object') {
      monitorQuestion = { questionId, answer: parseInt(answer), subQuestions };
    } else {
      monitorQuestion = { questionId: monitorQuestions.question.id, answer: submitAnswer };
    }

    if (monitorQuestion && monitorQuestion.answer !== '') {
      answers.push(monitorQuestion);
    }
  });

  return {
    monitorTestId: monitorResultId,
    answers,
  };
};

export default function ConductMonitorPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { patientId, monitorResultId: inputMonitorResultId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const monitorMode = useAppSelector<string>(selectMonitorMode);
  const isClinicianMonitor = monitorMode === MonitorModeName.Clinician;
  const newBreadcrumb: BreadcrumbType = {
    text: 'Patient Dashboard',
    link: `/dashboard/patient/${patientId}`,
  };
  const shouldRequestMonitorRef = useRef(true);

  const isAuthenticated = useAppSelector<boolean>(selectIsAuthenticated);
  const assessmentUrl = useAppSelector<string>(selectAssessmentUrl);
  const [requestMonitor] = useRequestMonitorMutation();
  const [submitMonitorAnswers] = useSubmitMonitorAnswersMutation();
  const [submitMonitorAnswersByToken] = useSubmitMonitorAnswersByTokenMutation();
  const patientInterviewData = useAppSelector<PatientInterviewData>(selectPatientInterviewData);
  const patientMonitorData = useAppSelector<PatientMonitorData>(selectPatientMonitorData);
  const [requestMonitorByToken] = useRequestMonitorByTokenMutation();
  const currentMonitor = useAppSelector<MonitorFormatDto>(selectMonitor) || {};
  const errorAnswerMessages =
    useAppSelector<MonitorErrorAnswerMessagesType>(selectErrorAnswerMessages) || {};

  const currentMonitorModules = currentMonitor?.modules;

  const monitorResultId = parseInt(inputMonitorResultId!) || patientMonitorData.monitorTestId;

  const [dataLoadError, setDataLoadError] = useState<boolean>(false);
  const [relativeAnswers, setRelativeAnswers] = useState<any>({});
  const [currentQuestionModules, setCurrentModules] = useState<any>([]);
  const [isMonitorCompleted, setMonitorCompleted] = useState<boolean>(false);
  const [nextInterviewResultId, setNextInterviewResultId] = useState<number>(0);
  const [nextTrackerResultId, setNextTrackerResultId] = useState<number>(0);
  const [currentReportType, setCurrentReportType] = useState<string>('');
  const errorSummary = t('monitor.error.summary');

  usePatientSessionExpiration({
    isClinician: isClinicianMonitor,
    assessmentUrl,
  });

  const { data: patientInfo, error: patientGetError } = useGetPatientQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  const { validationData, defaultValues } = buildDefaultValueAndValidationObject(
    t,
    currentQuestionModules
  );
  const validationSchema = yup.object(validationData).required();

  const {
    formState: { errors },
    control,
    handleSubmit,
    setValue,
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const setListResultIds = (interviewResultId: string | number) => {
    const savedListResultIds = localStorage.getItem('listResultIds');
    let parsedListResultIds = savedListResultIds ? JSON.parse(savedListResultIds) : [];
    if (!parsedListResultIds?.length) {
      parsedListResultIds = [];
      parsedListResultIds.push(interviewResultId);
    } else if (!parsedListResultIds.includes(interviewResultId)) {
      parsedListResultIds.push(interviewResultId);
    }
    localStorage.setItem('listResultIds', JSON.stringify(parsedListResultIds));
  };

  useEffect(() => {
    setListResultIds(monitorResultId?.toString());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (shouldRequestMonitorRef.current) {
      const bindMonitorData = async () => {
        let monitorResponse: MonitorTypeResponse | null = null;
        try {
          if (isClinicianMonitor) {
            monitorResponse = await requestMonitor({
              monitorTestId: monitorResultId,
              // monitorTestId: 2, // TODO: this hash code number only for mock API testing, remove when finished.
            }).unwrap();
          } else if (patientMonitorData || patientInterviewData) {
            monitorResponse = await requestMonitorByToken({
              accessToken: patientMonitorData?.accessToken || patientInterviewData?.accessToken,
            }).unwrap();
          }

          shouldRequestMonitorRef.current = false;

          if (monitorResponse) {
            dispatch(setCurrentMonitor({ monitorData: monitorResponse }));
          }
        } catch (e) {
          if ((e as ResponseError).status === HttpStatusCode.BadRequest) {
            setDataLoadError(true);
          }
        }
      };

      bindMonitorData();
    }
  }, [
    dispatch,
    isClinicianMonitor,
    monitorResultId,
    patientMonitorData,
    patientInterviewData,
    requestMonitor,
    requestMonitorByToken,
  ]);

  const checkRelativeQuestion = (questionValuesCompare: {
    moduleId: number;
    questionId: number;
    questionAnswer: string | number;
  }) => {
    setRelativeAnswers({
      ...relativeAnswers,
      [questionValuesCompare.moduleId]: {
        ...relativeAnswers[questionValuesCompare.moduleId],
        [questionValuesCompare.questionId]: questionValuesCompare.questionAnswer,
      },
    });
  };

  useEffect(() => {
    const checkRenderRelativeQuestions = () => {
      const newCurrentModules =
        currentMonitorModules &&
        currentMonitorModules.filter((monitorModule: MonitorModuleType) => {
          const { clientDisplayCondition } = monitorModule;
          let condition = false;

          if (clientDisplayCondition && clientDisplayCondition.length < 1) {
            condition = true;
          }

          if (clientDisplayCondition && clientDisplayCondition.length > 0) {
            clientDisplayCondition.forEach((clientCondition: ClientDisplayConditionType) => {
              condition = checkCompareCondition(
                clientCondition,
                relativeAnswers[clientCondition.dependOnModuleId!]
              );
            });
            if (!condition) {
              delete relativeAnswers[monitorModule.id!];
            }
            return condition;
          }
          return condition;
        });
      setCurrentModules(newCurrentModules);
    };

    if (Object.keys(errors).length > 0) {
      setPageErrors([errorSummary]);
    }

    checkRenderRelativeQuestions();
  }, [currentMonitorModules, errors, relativeAnswers, errorSummary]);

  const renderMonitorQuestion = (monitorModule: MonitorModuleType) => {
    const { id, prompt, sequenceOrders } = monitorModule;

    let relativeAnswerOnly = {};
    if (relativeAnswers) {
      for (const key in relativeAnswers) {
        relativeAnswerOnly = { ...relativeAnswerOnly, ...relativeAnswers[key] };
      }
    }

    return (
      <MonitorModule
        key={id}
        sequenceOrders={sequenceOrders}
        moduleName={prompt}
        control={control}
        errors={errors}
        setValue={setValue}
        moduleId={id}
        checkRelativeQuestion={checkRelativeQuestion}
        defaultValues={defaultValues}
        responseErrors={errorAnswerMessages}
        isClinicianMonitor={isClinicianMonitor}
        relativeAnswers={relativeAnswerOnly}
      />
    );
  };

  const onSubmit = async (data: any) => {
    const submitData = {
      ...convertSubmitMonitorAnswers(monitorResultId, data, currentQuestionModules),
      patientId,
    };

    try {
      let submissionResponse: MonitorSubmissionResponse = {};
      if (isClinicianMonitor) {
        submissionResponse = await submitMonitorAnswers(submitData).unwrap();
      } else {
        submissionResponse = await submitMonitorAnswersByToken({
          ...submitData,
          accessToken: patientMonitorData?.accessToken || patientInterviewData?.accessToken,
        } as MonitorSubmissionByTokenRequest).unwrap();

        dispatch(clearPatientInterviewData());
        dispatch(
          setPatientMonitorData({
            data: {
              ...patientMonitorData,
              lastActivityTs: Date.now(),
              accessToken:
                submissionResponse.nextPatientMonitorToken ||
                submissionResponse.nextPatientInterviewToken ||
                submissionResponse.patientMonitorToken ||
                '',
            },
          })
        );
      }

      if (submissionResponse.success) {
        dispatch(clearCurrentMonitor());
        if (!currentReportType && submissionResponse?.interviewReportType) {
          setCurrentReportType(submissionResponse.interviewReportType);
        }

        if (submissionResponse.nextInterviewResultId) {
          setListResultIds(submissionResponse.nextInterviewResultId.toString());
          setNextInterviewResultId(submissionResponse.nextInterviewResultId);
        } else if (submissionResponse.nextTrackerResultId) {
          setListResultIds(submissionResponse.nextTrackerResultId.toString());
          setNextTrackerResultId(submissionResponse.nextTrackerResultId);
        }

        setMonitorCompleted(true);
        setPageErrors([]);
      }
    } catch (e) {
      const {
        data: { error },
      } = e as MonitorQuestionResponseError;

      const monitorQuestionErrors = error as any;

      if (monitorQuestionErrors.every((error: any) => error.questionId)) {
        dispatch(setErrorFromMonitorResponse({ errorQuestions: monitorQuestionErrors }));
        setPageErrors([t('monitor.error.summary')]);
      } else {
        setPageErrors(monitorQuestionErrors);
      }
    }
  };

  if (isMonitorCompleted) {
    let nextPageUrl = null;
    if (nextInterviewResultId) {
      nextPageUrl = patientId
        ? `/current-interview/continue-next-interview/${currentReportType}/${patientId}/${nextInterviewResultId}`
        : `/current-interview/continue-next-interview/${currentReportType}/${nextInterviewResultId}`;
    } else if (nextTrackerResultId) {
      nextPageUrl = patientId
        ? `/current-monitor/continue-next-monitor/${patientId}/${nextTrackerResultId}`
        : `/current-monitor/continue-next-monitor/${nextTrackerResultId}`;
    } else {
      nextPageUrl = isClinicianMonitor
        ? `/dashboard/current-monitor/monitor-complete/${patientId}/${monitorResultId}`
        : `/current-monitor/complete/${monitorResultId}`;
    }

    return <Navigate to={nextPageUrl}></Navigate>;
  }

  const savedListResultIds = localStorage.getItem('listResultIds');
  const parsedListResultIds = savedListResultIds ? JSON.parse(savedListResultIds) : [];
  const isValidResultId =
    !parsedListResultIds || parsedListResultIds.includes(inputMonitorResultId?.toString());

  if (
    dataLoadError ||
    (!isAuthenticated &&
      (!isValidResultId ||
        (!patientInterviewData?.accessToken && !patientMonitorData?.accessToken)))
  ) {
    return <ErrorPage statusCode={HttpStatusCode.NotFound} />;
  }

  if (
    !isClinicianMonitor &&
    patientMonitorData &&
    (!patientMonitorData.lastActivityTs ||
      patientMonitorData.lastActivityTs + PATIENT_ASSESSMENT_EXPIRE_TIME_MILLISECONDS < Date.now())
  ) {
    dispatch(clearPatientMonitorData());
    dispatch(clearPatientInterviewData());
    localStorage.removeItem('listResultIds');
    navigate('/login');
    return <></>;
  }

  if (patientGetError) {
    return <ErrorPage statusCode={(patientGetError as FetchBaseQueryError).status} />;
  }

  return (
    <DashboardLayout
      breadcrumbs={addBreadcrumb(breadcrumbs!, newBreadcrumb)}
      showBreadcrumb={isClinicianMonitor}
      sessionExpiration={isClinicianMonitor}
    >
      <Helmet>
        <title>{getPageTitle(t('monitor.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        {isClinicianMonitor && (
          <RoundedContainer sx={{ py: 2 }}>
            <PatientInfoCard data={patientInfo!} />
          </RoundedContainer>
        )}
        <Box sx={{ mt: 2 }} component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
          {currentQuestionModules &&
            currentQuestionModules.map((monitorModule: MonitorModuleType) => {
              return (
                <RoundedContainer key={monitorModule.id} sx={{ py: 2, mb: 2 }}>
                  {renderMonitorQuestion(monitorModule)}
                </RoundedContainer>
              );
            })}
          <RoundedContainer
            sx={{
              py: 2,
              mb: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {pageErrors.length > 0 &&
              pageErrors.map((error) => (
                <Alert key={error} severity="error" sx={{ my: 2 }}>
                  {error}
                </Alert>
              ))}
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'row',
                columnGap: 1,
                [theme.breakpoints.up('md')]: {
                  flexDirection: 'row',
                  columnGap: 1,
                },
              }}
            >
              <StyledButton
                sx={{
                  my: 1,
                  width: '100%',
                }}
                type="submit"
                variant="contained"
              >
                {t('monitor.form.submit')}
              </StyledButton>
            </Box>
          </RoundedContainer>
        </Box>
      </Container>
    </DashboardLayout>
  );
}
