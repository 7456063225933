export const convertLbsToKg = (pounds: any): number => {
  const convertValue = 0.45359237;
  const poundsVal = Number(pounds);

  if (isNaN(poundsVal)) {
    return 0;
  }

  // Only show one decimal place
  return Number((poundsVal * convertValue).toFixed(1));
};

export const convertKgToLbs = (kilograms: any): number => {
  const convertValue = 0.45359237;
  const kgVal = Number(kilograms);

  if (isNaN(kgVal)) {
    return 0;
  }

  // Return whole numbers only
  return Math.round(kgVal / convertValue);
};

const roundToFirstDecimalPlace = (num: number) => {
  let integerPart = Math.floor(num);
  let decimalPart = Math.round((num - integerPart) * 10) / 10;
  if (decimalPart < 0.3) {
      return integerPart + 0.0;
  }
  else if (decimalPart < 0.8) {
      return integerPart + 0.5;
  }
  else {
      return integerPart + 1.0;
  }
}

export const convertCmToFeetInches = (centimeters: any): { feet: number; inches: number } => {
  var centimetersNum = parseInt(centimeters);

  const returnValue = {
    feet: 0,
    inches: 0,
  };

  if (!isNaN(centimetersNum)) {
    let inches = roundToFirstDecimalPlace(centimetersNum * 0.393700787);
    const feet = Math.floor(inches / 12);
    inches %= 12;

    returnValue.feet = feet;
    returnValue.inches = inches;
  }

  return returnValue;
};

export const convertFeetInchesToCm = (feet: any, inches: any, checkNaN?: boolean): string => {
  // The conversion value to convert cm to inches and vice versa.
  const cmToInchConversionTerm = 2.54;
  // Convert to an integer if a number
  const feetNum = parseFloat(feet);
  const inchesNum = parseFloat(inches);

  if (checkNaN && isNaN(feetNum)) {
    return NaN.toString();
  }

  let cmValue = 0;
  if (!isNaN(feetNum)) {
    cmValue += feetNum * cmToInchConversionTerm * 12;
  }

  if (!isNaN(inchesNum)) {
    cmValue += inchesNum * cmToInchConversionTerm;
  }

  return Math.round(cmValue).toString();
};
