export const InterviewDownloadTypes: { [key: string]: string } = {
  incompleteInterview: 'Answers for Incomplete Interviews',
  completedInterview: 'Answers for Completed Interviews',
  scoredInterview: 'Scores for Completed Interviews',
};

export const MonitorDownloadTypes: { [key: string]: string } = {
  completedMonitor: 'Answers for Completed Monitor',
};

export const ExportAllUserTypes = {
  id: 'all',
  name: 'All',
}
