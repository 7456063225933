import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  useActivateOrDeactivateAccountMutation,
  useGetAccountListQuery,
} from '../../app/services/account';
import { useToast } from '../../app/toast';
import CustomPagination from '../../common/components/CustomPagination';
import DataList from '../../common/components/DataList';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import PopupModal from '../../common/components/PopupModal';
import RoundedContainer from '../../common/components/RoundedContainer';
import DeactivatePatientIcon from '../../common/components/icons/DeactivatePatientIcon';
import EditUserIcon from '../../common/components/icons/EditInterviewIcon';
import ReactivatePatientIcon from '../../common/components/icons/ReactivatePatientIcon';
import DashboardLayout from '../../common/layouts/Dashboard';
import {
  selectContentPopup,
  selectIsOpenPopup,
  setContentPopup,
  setIsOpenPopup,
} from '../../common/slices/globalSlice';
import { CallActionType } from '../../common/utils/callActionType';
import { Status } from '../../common/utils/commonUtils';
import { getPageTitle } from '../../common/utils/pageUtils';
import { canAddOrEditAccounts } from '../../common/utils/permissionUtils';
import { SortDirs, SortFields } from '../../common/utils/sortInfo';
import { UserTabStatus } from '../../common/utils/userListUtils';
import { AccountListItem } from '../../types/AccountType';
import { PageProps } from '../../types/PageProps';
import { PopupContent } from '../../types/PopupType';
import { selectUser } from '../auth/authSlice';
import ContextMenu from '../patient/ContextMenu';
import PatientSortMenu from '../patient/PatientSortMenu';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.xPalette.lightGrey,
    fontWeight: theme.typography.h1.fontWeight,
    backgroundColor: theme.xPalette.background,
    borderBottom: 0,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: theme.typography.h4.fontWeight,
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  width: '40%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const StyledTab = styled(Tab)(() => ({
  width: '50%',
}));

const StyledSearchBox = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: theme.xPalette.white,
  flexBasis: '100%',
  minWidth: '240px',
  [theme.breakpoints.down('sm')]: {
    flexBasis: 'unset',
    width: '74%',
    minWidth: 'unset',
  },
}));

const StyledAddAccountBox = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  lineHeight: '18px',
  width: '40%',
  minWidth: '120px',
  ml: 1,
  padding: 0,
  [theme.breakpoints.down('sm')]: {
    width: '96%',
    height: '56px',
    ml: 0,
  },
}));

const StyledAddWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '12px 0px',
  width: '100%',
  padding: '0px 8px',
  columnGap: '8px',
  [theme.breakpoints.up('md')]: {
    marginTop: 0,
    width: 'auto',
    flexBasis: '40%',
    maxWidth: '380px',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: '16px',
  },
}));

const defaultPage = 1;

export default function AccountListPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const toast = useToast();

  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageSize] = useState<number>(10);
  const [searchParams] = useSearchParams();
  const [displayAccountData, setDisplayAccountData] = useState<AccountListItem[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [navBackUrl, setNavBackUrl] = useState<string>('/dashboard/accounts');
  const [accountIdAction, setAccountIdAction] = useState<string>('');
  const [callAction, setCallAction] = useState<string>('');

  const isOpenPopup = useAppSelector(selectIsOpenPopup);
  const contentPopup = useAppSelector(selectContentPopup);
  const currentUser = useAppSelector(selectUser);

  const tableHeaders: string[] = Object.values(
    t('account.manageAccounts.tableHeader', {
      returnObjects: true,
    })
  );

  const deactivateAccountContentPopup = {
    title: t('account.manageAccounts.deactivateAccount.title'),
    description: t('account.manageAccounts.deactivateAccount.description'),
    btnOk: t('account.manageAccounts.deactivateAccount.btnOk'),
    btnClose: t('account.manageAccounts.deactivateAccount.btnClose'),
    toastMessage: t('account.manageAccounts.deactivateAccount.toastMessage'),
  } as PopupContent;

  const reactivateAccountContentPopup = {
    title: t('account.manageAccounts.reactivateAccount.title'),
    description: t('account.manageAccounts.reactivateAccount.description'),
    btnOk: t('account.manageAccounts.reactivateAccount.btnOk'),
    btnClose: t('account.manageAccounts.reactivateAccount.btnClose'),
    toastMessage: t('account.manageAccounts.reactivateAccount.toastMessage'),
  } as PopupContent;

  const [activateOrDeactivateAccount] = useActivateOrDeactivateAccountMutation();

  const searchTextboxRef = useRef<HTMLInputElement>(null);

  const searchOptions = {
    page: parseInt(searchParams.get('page') || '1'),
    search: searchParams.get('search') || '',
    status: searchParams.get('status') || Status.ACTIVE,
    sortField: searchParams.get('sort') || SortFields.Name,
    sortDir: searchParams.get('dir') || SortDirs.Asc,
  };

  const currentPage = searchOptions.page;
  const tableRef = useRef<HTMLDivElement>(null);

  const {
    data: accountData,
    isLoading: isLoadingAccount,
    refetch: refetchAccountList,
  } = useGetAccountListQuery({
    search: searchOptions.search,
    page: currentPage,
    pageSize: pageSize,
    status: searchOptions.status,
    sort: `${searchOptions.sortField} ${searchOptions.sortDir}`,
  });

  const StyledSortIconBox = styled(Box)(({ theme }) => ({
    display: 'inline',
    color: theme.palette.primary.main,
    verticalAlign: 'middle',
  }));

  const showDeactivateAccountPopup = async (accountId: string) => {
    dispatch(setContentPopup({ content: deactivateAccountContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
    setAccountIdAction(accountId);
    handleClose();
    setCallAction(CallActionType.Deactivate);
  };

  const showReactivateAccountPopup = async (accountId: string) => {
    dispatch(setContentPopup({ content: reactivateAccountContentPopup }));
    dispatch(setIsOpenPopup({ value: true }));
    setAccountIdAction(accountId);
    handleClose();
    setCallAction(CallActionType.Reactivate);
  };

  useEffect(() => {
    if (searchOptions.status === Status.DEACTIVATED) {
      setTabIndex(1);
    } else {
      setTabIndex(0);
    }
  }, [searchOptions.status]);

  useEffect(() => {
    if (accountData) {
      setDisplayAccountData(accountData.accounts);
      setTotalPages(Math.ceil(accountData.totalItems / pageSize));
    }
  }, [dispatch, pageSize, accountData]);

  const updateUrl = (
    page: number,
    tabIndex: number,
    search: string | undefined,
    sort: string,
    dir: string
  ) => {
    const updatedUrl = `/dashboard/accounts?page=${page}&search=${encodeURIComponent(
      search || ''
    )}&status=${UserTabStatus[tabIndex]}&sort=${sort}&dir=${dir}`;
    navigate(updatedUrl);
    setNavBackUrl(updatedUrl);
  };

  const handlePageChange = (value: number) => {
    updateUrl(
      value,
      tabIndex,
      searchOptions.search,
      searchOptions.sortField,
      searchOptions.sortDir
    );
  };

  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
    updateUrl(
      defaultPage,
      newIndex,
      searchTextboxRef.current?.value,
      searchOptions.sortField,
      searchOptions.sortDir
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = () => {
    const searchInput = searchTextboxRef.current?.value || '';
    updateUrl(defaultPage, tabIndex, searchInput, searchOptions.sortField, searchOptions.sortDir);
  };

  const handleSearchKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleMenuSorting = (sortField: string, sortDir: string) => {
    updateUrl(defaultPage, tabIndex, searchOptions.search, sortField, sortDir);
  };

  const onHeaderClicked = (header?: string) => {
    if (header) {
      updateUrl(
        defaultPage,
        tabIndex,
        searchOptions.search,
        header,
        header !== searchOptions.sortField || searchOptions.sortDir === SortDirs.Desc
          ? SortDirs.Asc
          : SortDirs.Desc
      );
    }
  };

  const handleActivateAccount = async (successCallback?: () => void) => {
    try {
      await activateOrDeactivateAccount({
        accountId: accountIdAction,
        status: Status.ACTIVE,
      }).unwrap();
      if (successCallback) {
        successCallback();
      }
      toast.publish(t('account.manageAccounts.reactivateAccount.success'), 'success');
      refetchAccountList();
      updateUrl(
        defaultPage,
        tabIndex,
        searchOptions.search,
        searchOptions.sortField,
        searchOptions.sortDir
      );
    } catch (e) {
      toast.publish(t('account.manageAccounts.reactivateAccount.fail'), 'error');
    }
  };

  const handleDeactivateAccount = async (successCallback?: () => void) => {
    try {
      await activateOrDeactivateAccount({
        accountId: accountIdAction,
        status: Status.DEACTIVATED,
      }).unwrap();
      if (successCallback) {
        successCallback();
      }
      toast.publish(t('account.manageAccounts.deactivateAccount.success'), 'success');
      refetchAccountList();
      updateUrl(
        defaultPage,
        tabIndex,
        searchOptions.search,
        searchOptions.sortField,
        searchOptions.sortDir
      );
    } catch (e) {
      toast.publish(t('account.manageAccounts.deactivateAccount.fail'), 'error');
    }
  };

  const renderContextMenu = (item: AccountListItem) => {
    const commonCondition = item.status !== Status.DEACTIVATED;
    const menuItems = [
      {
        isDisplayed: commonCondition && canAddOrEditAccounts(currentUser.roleId),
        label: t('account.manageAccounts.menuContext.editAccount'),
        icon: <EditUserIcon />,
        onClick: () => {
          navigate(`/dashboard/accounts/edit-account/${item.accountId}`, { state: { navBackUrl } });
        },
      },
      {
        isDisplayed:
          commonCondition && canAddOrEditAccounts(currentUser.roleId) && item.enabledBrandSettings,
        label: t('account.manageAccounts.menuContext.brandSettings'),
        icon: (
          <SettingsIcon
            fontSize="medium"
            sx={{
              width: '24px',
              height: '24px',
              color: '#0000008a',
            }}
          />
        ),
        onClick: () => {
          navigate(`/dashboard/accounts/edit-brand/${item.accountId}`, {
            state: { navBackUrl },
          });
        },
      },
      {
        isDisplayed: commonCondition && canAddOrEditAccounts(currentUser.roleId),
        label: t('account.manageAccounts.menuContext.clinicalFollowUps'),
        icon: (
          <SettingsIcon
            fontSize="medium"
            sx={{
              width: '24px',
              height: '24px',
              color: '#0000008a',
            }}
          />
        ),
        onClick: () => {
          navigate(`/dashboard/accounts/clinical-follow-ups/${item.accountId}`, {
            state: { navBackUrl },
          });
        },
      },
      {
        isDisplayed: canAddOrEditAccounts(currentUser.roleId),
        label: commonCondition
          ? t('account.manageAccounts.menuContext.deactivateAccount')
          : t('account.manageAccounts.menuContext.activateAccount'),
        icon: commonCondition ? <DeactivatePatientIcon /> : <ReactivatePatientIcon />,
        onClick: () =>
          commonCondition
            ? showDeactivateAccountPopup(item.accountId)
            : showReactivateAccountPopup(item.accountId),
      },
    ];

    const checkShowContextMenu = menuItems.some((item) => item.isDisplayed);
    return checkShowContextMenu ? (
      <ContextMenu
        id={`account-${item.accountId}`}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        menuItems={menuItems}
      />
    ) : (
      <></>
    );
  };

  const renderDesktopData = (data: AccountListItem[]) => {
    return (
      <TableContainer component={Paper} elevation={0} sx={{ borderRadius: '16px 16px 0 0' }}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => {
                const sortHeader = ['name'];
                const checkSortEvent =
                  searchOptions.sortDir === SortDirs.Asc &&
                  searchOptions.sortField === header.toLowerCase();

                const sortIcon = sortHeader.includes(header.toLowerCase()) ? (
                  <StyledSortIconBox>
                    {checkSortEvent ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </StyledSortIconBox>
                ) : (
                  <></>
                );
                return (
                  <StyledTableCell
                    key={`${header} ${index}`}
                    onClick={
                      sortHeader.includes(header.toLowerCase())
                        ? () => onHeaderClicked(header.toLowerCase())
                        : undefined
                    }
                  >
                    {header} {sortIcon}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: AccountListItem) => {
              const commonCondition = item.status !== Status.DEACTIVATED;
              return (
                <TableRow key={item.accountId}>
                  <StyledTableCell sx={{ wordBreak: 'break-all', minWidth: '100px' }}>
                    {item.accountId}
                  </StyledTableCell>
                  <StyledTableCell sx={{ maxWidth: '500px', minWidth: '150px' }}>
                    {commonCondition && canAddOrEditAccounts(currentUser.roleId) ? (
                      <Link
                        sx={{ fontSize: '1em', textAlign: 'left' }}
                        component="button"
                        onClick={() => {
                          navigate(`/dashboard/accounts/edit-account/${item.accountId}`, {
                            state: { navBackUrl },
                          });
                        }}
                      >
                        {`${item.name}`}
                      </Link>
                    ) : (
                      `${item.name}`
                    )}
                  </StyledTableCell>
                  <StyledTableCell sx={{ minWidth: '100px' }}>{item.type}</StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{
                      '& .MuiButtonBase-root': {
                        marginLeft: '10px',
                      },
                    }}
                  >
                    {renderContextMenu(item)}
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderMobileData = (data: AccountListItem[]) => {
    return (
      <DataList
        lineHeight={'auto'}
        data={data.map((item: AccountListItem) => {
          const commonCondition = item.status !== Status.DEACTIVATED;
          const listItems = [
            [
              <>{t('account.manageAccounts.tableHeader.number')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{item.accountId}</Box>,
            ],
            [
              <>{t('account.manageAccounts.tableHeader.type')}</>,
              <Box sx={{ wordBreak: 'break-word' }}>{item.type}</Box>,
            ],
          ];
          return {
            leftHeader:
              commonCondition && canAddOrEditAccounts(currentUser.roleId) ? (
                <Link
                  sx={{ fontSize: '20px', textAlign: 'left', fontWeight: '600' }}
                  component="button"
                  onClick={() => {
                    navigate(`/dashboard/accounts/edit-account/${item.accountId}`, {
                      state: { navBackUrl },
                    });
                  }}
                >
                  {`${item.name}`}
                </Link>
              ) : (
                <Typography variant="body1" fontWeight="bold" fontSize={20}>
                  {`${item.name}`}
                </Typography>
              ),
            rightHeader: renderContextMenu(item),
            items: [...listItems],
          };
        })}
        customStyle={{
          borderRadius: '16px 16px 0 0',
        }}
      />
    );
  };

  const isLoading = isLoadingAccount;

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('account.manageAccounts.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={!isDesktop}>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              display: 'flex',
              justifyContent: 'space-between',
            },
          }}
        >
          <PageHeader
            sx={{ mb: 2, mx: isDesktop ? 0 : 2 }}
            headerText={t('account.manageAccounts.title')}
            backUrl={'/dashboard'}
          />

          <StyledAddWrapper>
            <StyledSearchBox
              name="account-search-field"
              data-testid="account-search-field"
              inputRef={searchTextboxRef}
              onKeyUp={handleSearchKeyUp}
              defaultValue={searchOptions.search}
              placeholder={t('account.manageAccounts.searchAccount')}
              endAdornment={
                <IconButton type="submit" aria-label="search" onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              }
            />
            {!isDesktop && (
              <PatientSortMenu
                sx={{
                  width: '20%',
                  flexShrink: 0,
                  borderRadius: '8px',
                  minWidth: 'unset',
                }}
                sortField={searchOptions.sortField}
                sortDir={searchOptions.sortDir}
                onSorted={handleMenuSorting}
                isAccount={true}
              />
            )}
            <StyledAddAccountBox
              type="button"
              fullWidth
              variant="contained"
              onClick={() => {
                navigate('/dashboard/accounts/add-account', { state: { navBackUrl } });
              }}
            >
              {t('account.manageAccounts.addAccount')}
            </StyledAddAccountBox>
          </StyledAddWrapper>
        </Box>

        {isLoading ? (
          <Loading />
        ) : (
          <Box ref={tableRef}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2, mx: 2 }}>
              <StyledTabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="accounts-list-tabs"
              >
                <StyledTab
                  label={t('account.manageAccounts.tableTabs.activeTab')}
                  id="tab-1"
                  aria-controls="tabpanel-1"
                />
                <StyledTab
                  label={t('account.manageAccounts.tableTabs.deactivatedTab')}
                  id="tab-2"
                  aria-controls="tabpanel-2"
                />
              </StyledTabs>
            </Box>
            {displayAccountData.length === 0 ? (
              <RoundedContainer sx={{ mt: 2, p: 2 }}>
                <Typography>{t('account.manageAccounts.error.accountsNotFound')}</Typography>
              </RoundedContainer>
            ) : (
              <>
                {isDesktop
                  ? renderDesktopData(displayAccountData)
                  : renderMobileData(displayAccountData)}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    py: 4,
                    borderBottomLeftRadius: '15px',
                    borderBottomRightRadius: '15px',
                    background: theme.xPalette.white,
                    mt: 0,
                    [theme.breakpoints.down('sm')]: {
                      mt: 1,
                      py: 2,
                    },
                  }}
                >
                  <CustomPagination
                    changePage={(e, value) => handlePageChange(value)}
                    currentPage={searchOptions.page}
                    pages={totalPages}
                    pageRef={tableRef}
                  />
                </Box>
              </>
            )}
          </Box>
        )}
        <PopupModal
          isOpen={isOpenPopup}
          contentPopup={contentPopup}
          onClick={
            callAction === CallActionType.Deactivate
              ? handleDeactivateAccount
              : handleActivateAccount
          }
        />
      </Container>
    </DashboardLayout>
  );
}
