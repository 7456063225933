import { Role } from './loginRole';

export const canEditUser = (editUserRoleId: number, loginUserRoleId: number): boolean => {
  // Super admins can edit all users
  if (loginUserRoleId === Role.SuperAdmin) {
    return true;
  }

  if (
    loginUserRoleId === Role.ClinicAdmin &&
    (editUserRoleId === Role.MultiSiteAdmin ||
      editUserRoleId === Role.SiteAdmin ||
      editUserRoleId === Role.Interviewer ||
      editUserRoleId === Role.MultiUserProvider ||
      editUserRoleId === Role.SingleUserProvider)
  ) {
    return true;
  }

  if (
    loginUserRoleId === Role.MultiSiteAdmin &&
    (editUserRoleId === Role.SiteAdmin || editUserRoleId === Role.Interviewer)
  ) {
    return true;
  }

  if (
    (loginUserRoleId === Role.SiteAdmin || loginUserRoleId === Role.SingleUserProvider) &&
    editUserRoleId === Role.Interviewer
  ) {
    return true;
  }

  if (
    loginUserRoleId === Role.MultiUserProvider &&
    (editUserRoleId === Role.Interviewer || editUserRoleId === Role.SingleUserProvider)
  ) {
    return true;
  }

  return false;
};

export const canViewOrAddUser = (loginUserRoleId: number): boolean => {
  return (
    loginUserRoleId === Role.SuperAdmin ||
    loginUserRoleId === Role.ClinicAdmin ||
    loginUserRoleId === Role.MultiUserProvider ||
    loginUserRoleId === Role.MultiSiteAdmin ||
    loginUserRoleId === Role.SingleUserProvider ||
    loginUserRoleId === Role.SiteAdmin
  );
};
/**
 * Report
 */
export const canViewUsageReport = (loginUserRoleId: number): boolean => {
  return (
    loginUserRoleId === Role.SuperAdmin ||
    loginUserRoleId === Role.ClinicAdmin ||
    loginUserRoleId === Role.MultiUserProvider ||
    loginUserRoleId === Role.SingleUserProvider
  );
};
/**
 * Report
 */
export const canViewAuditLog = (loginUserRoleId: number): boolean => {
  return (
    loginUserRoleId === Role.SuperAdmin ||
    loginUserRoleId === Role.ClinicAdmin ||
    loginUserRoleId === Role.MultiUserProvider ||
    loginUserRoleId === Role.MultiSiteAdmin ||
    loginUserRoleId === Role.SingleUserProvider ||
    loginUserRoleId === Role.SiteAdmin
  );
};

/**
 * Site
 */

export const canViewSites = (loginUserRoleId: number): boolean => {
  return (
    loginUserRoleId === Role.SuperAdmin ||
    loginUserRoleId === Role.ClinicAdmin ||
    loginUserRoleId === Role.MultiUserProvider ||
    loginUserRoleId === Role.MultiSiteAdmin ||
    loginUserRoleId === Role.SingleUserProvider
  );
};

export const canAddSite = (loginUserRoleId: number): boolean => {
  return (
    loginUserRoleId === Role.SuperAdmin ||
    loginUserRoleId === Role.ClinicAdmin ||
    loginUserRoleId === Role.MultiUserProvider
  );
};

export const canEditSite = (loginUserRoleId: number) => {
  return (
    loginUserRoleId === Role.SuperAdmin ||
    loginUserRoleId === Role.ClinicAdmin ||
    loginUserRoleId === Role.MultiSiteAdmin ||
    loginUserRoleId === Role.MultiUserProvider ||
    loginUserRoleId === Role.SingleUserProvider
  );
};

/**
 * Add SSO Client
 */
export const canAddEditSsoClient = (loginUserRoleId: number): boolean => {
  return loginUserRoleId === Role.SuperAdmin;
};

/**
 * Account
 */

export const canViewAccounts = (loginUserRoleId: number): boolean => {
  return loginUserRoleId === Role.SuperAdmin;
};

export const canAddOrEditAccounts = (loginUserRoleId: number): boolean => {
  return loginUserRoleId === Role.SuperAdmin;
};

/**
 * Export
 */

export const canViewExportPage = (loginUserRoleId: number): boolean => {
  return (
    loginUserRoleId === Role.SuperAdmin ||
    loginUserRoleId === Role.ClinicAdmin ||
    loginUserRoleId === Role.MultiUserProvider ||
    loginUserRoleId === Role.SingleUserProvider
  );
};

/**
 *  Import Interviews
 */

export const canViewImportInterviewPage = (loginUserRoleId: number): boolean => {
  return loginUserRoleId === Role.SuperAdmin;
};

/**
 *  Interview Menu
 */

export const canViewInterviewMenu = (loginUserRoleId: number): boolean => {
  return loginUserRoleId === Role.SuperAdmin;
};

/**
 * Interviews
 */

export const canViewInterviewPage = (loginUserRoleId: number): boolean => {
  return loginUserRoleId === Role.SuperAdmin;
};

/**
 * Import
 */

export const canViewImportPatientPage = (loginUserRoleId: number): boolean => {
  return (
    loginUserRoleId === Role.SuperAdmin ||
    loginUserRoleId === Role.ClinicAdmin ||
    loginUserRoleId === Role.MultiUserProvider ||
    loginUserRoleId === Role.SingleUserProvider
  );
};

/**
 * Management Report
 */
export const canViewManagementReport = (
  loginUserRoleId: number,
  showManagementReport: boolean
): boolean => {
  return (
    showManagementReport &&
    (loginUserRoleId === Role.SuperAdmin || loginUserRoleId === Role.ClinicAdmin)
  );
};

/**
 * Data Structured Page
 */
export const canViewDataStructuredPage = (loginUserRoleId: number): boolean => {
  return (
    loginUserRoleId === Role.SuperAdmin ||
    loginUserRoleId === Role.ClinicAdmin ||
    loginUserRoleId === Role.SingleUserProvider ||
    loginUserRoleId === Role.MultiUserProvider
  );
};

/**
 * Rescore Interviews
 */
export const canViewRescoreInterviewPage = (loginUserRoleId: number): boolean => {
  return loginUserRoleId === Role.SuperAdmin;
};

/**
 * Clinical Follow-Ups
 */
export const canViewClinicalFollowupsPage = (loginUserRoleId: number): boolean => {
  return (
    loginUserRoleId === Role.ClinicAdmin ||
    loginUserRoleId === Role.SuperAdmin ||
    loginUserRoleId === Role.MultiSiteAdmin
  );
};

/**
 * menu Clinical Follow-Ups
 */
export const canViewMenuClinicalFollowupsPage = (loginUserRoleId: number): boolean => {
  return loginUserRoleId === Role.ClinicAdmin || loginUserRoleId === Role.MultiSiteAdmin;
};

/**
 * Account Settings
 */
export const canViewAccountSettingsPage = (loginUserRoleId: number): boolean => {
  return (
    loginUserRoleId === Role.SuperAdmin ||
    loginUserRoleId === Role.ClinicAdmin ||
    loginUserRoleId === Role.MultiUserProvider
  );
};

export const canAddEditTemplate = (loginUserRoleId: number): boolean => {
  return loginUserRoleId === Role.SuperAdmin;
};

/**
 * Interview and Monitor builder
 */
export const canViewBuilder = (loginUserRoleId: number): boolean => {
  return loginUserRoleId === Role.SuperAdmin;
};

/**
 * Billing Report
 */
export const canViewBillingReport = (loginUserRoleId: number): boolean => {
  return loginUserRoleId === Role.SuperAdmin || loginUserRoleId === Role.ClinicAdmin;
};

/**
 * Master Usage Report
 */
export const canViewMasterUsageReport = (loginUserRoleId: number): boolean => {
  return loginUserRoleId === Role.SuperAdmin;
};

export const canResetCache = (loginUserRoleId: number): boolean => {
  return loginUserRoleId === Role.SuperAdmin;
};

export const canViewApiKey = (loginUserRoleId: number): boolean => {
  return loginUserRoleId === Role.SuperAdmin || loginUserRoleId === Role.ClinicAdmin;
};

export const canViewBySuperAdmin = (loginUserRoleId: number): boolean => {
  return loginUserRoleId === Role.SuperAdmin;
};

export const isQuickSightReportEnabled = process.env.REACT_APP_QUICKSIGHT_REPORT_ENABLED === 'true';
