import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { InterviewQuestionProps } from '../../../types/InterviewQuestionTypes';

export const StyledRadio = styled(Radio)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    fontSize: 32,
  },
  '&.Mui-checked': {
    width: 32,
    height: 32,
    color: theme.xPalette.completed.color,
    backgroundColor: theme.xPalette.white,
    marginRight: '8px',
  },
  '&.Mui-checked + span': {
    color: theme.xPalette.white,
  },
}));

export enum BinaryValue {
  Yes = 'yes',
  No = 'no',
}

export const radioStyle = {
  flex: 1,
  maxWidth: '50%',
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: '8px',
  paddingLeft: '16px',
  margin: 0,
  '&.MuiFormControlLabel-root:first-of-type': {
    marginRight: '4px',
  },
};

export const StyledFormLabelYes = styled(FormControlLabel)(({ theme, itemScope }) => ({
  ...radioStyle,
  border: `1px solid ${theme.xPalette.disabled}`,
  '&.MuiFormControlLabel-root': {
    backgroundColor: itemScope ? theme.xPalette.completed.color : '',
  },
}));

export const StyledFormLabelNo = styled(FormControlLabel)(({ theme, itemScope }) => ({
  ...radioStyle,
  border: `1px solid ${theme.xPalette.disabled}`,
  '&.MuiFormControlLabel-root': {
    backgroundColor: itemScope ? theme.xPalette.completed.color : '',
  },
}));

export const StyledFormControl = styled(FormControl)(({ theme, error }) => ({
  margin: '16px 0px',
  width: '40%',
  '&.MuiFormControl-root .MuiFormGroup-root .MuiFormControlLabel-root': {
    borderColor: error ? theme.palette.error.main : '',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const StyledTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  marginBottom: '8px',
}));

export default function BinaryQuestion({
  question,
  field,
  error,
  helperText,
  isStsSdsQuestion,
}: InterviewQuestionProps) {
  const renderAnswers = (BinaryValue: string) => {
    const { displayAnswers, possibleAnswers } = question;

    let items: any = {};
    for (let i = 0; i < possibleAnswers.length; i++) {
      const value = possibleAnswers[i];
      const text = displayAnswers?.length === possibleAnswers?.length
        ? `${displayAnswers?.[i] || value}`.trim()
        : value.trim();
      items = { ...items, [value]: text };
    }

    return items[BinaryValue];
  };

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      {isStsSdsQuestion && (
        <StyledTypography
          dangerouslySetInnerHTML={{
            __html: question.subTitle
              ? `${question.name}:`
              : question.title
              ? `${question.title}:`
              : '',
          }}
        ></StyledTypography>
      )}
      {question.questionPrompt && (
        <StyledTypography
          dangerouslySetInnerHTML={{
            __html: question.questionPrompt,
          }}
        ></StyledTypography>
      )}
      <Typography
        dangerouslySetInnerHTML={{
          __html: question.questionText!,
        }}
      ></Typography>
      <StyledFormControl
        error={error}
        variant="standard"
      >
        <RadioGroup {...field} row>
          <StyledFormLabelNo
            value={question.possibleAnswers[1]}
            itemScope={field?.value === question.possibleAnswers[1]}
            labelPlacement="start"
            control={<StyledRadio checkedIcon={<CheckIcon />} />}
            label={renderAnswers(question.possibleAnswers[1])}
          />
          <StyledFormLabelYes
            value={question.possibleAnswers[0]}
            itemScope={field?.value === question.possibleAnswers[0]}
            labelPlacement="start"
            control={<StyledRadio checkedIcon={<CheckIcon />} />}
            label={renderAnswers(question.possibleAnswers[0])}
          />
        </RadioGroup>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </StyledFormControl>
    </Box>
  );
}
