import { useState } from 'react';
import { AssessmentMode } from '../../../../common/utils/assessmentMode';
import { convertKgToLbs } from '../../../../common/utils/commonCalculate';
import { DefaultLanguage } from '../../../../common/utils/languages';
import { QuestionAnswerType } from '../../../../common/utils/questionAnswerTypes';
import { ItemType } from '../../../../common/utils/reportTypes';
import { newlinesToBr } from '../../../../common/utils/reportUtils';
import { capitalizeFirstLetter } from '../../../../common/utils/stringUtils';
import { JSONForStsAnswer, QuestionAnswer } from '../../../../types/ReportType';
import { InterviewReportType } from '../../../interview/interviewUtils';

//TODO: move this file to another folder
export default function useCustomQuestionInfo({
  initialState,
  moduleType,
  subModuleType,
  assessmentType,
}: {
  initialState: QuestionAnswer;
  moduleType?: string;
  subModuleType?: string;
  assessmentType?: string;
  itemType?: string;
  language?: string;
}): {
  questionDisplay: any;
  promptDisplay: string;
  scoreForQuestion: string;
  answerValue: any;
  questionNameDisplay: string;
  questionTitle: string;
  arrayAnswerValue: JSONForStsAnswer[];
} {
  const [questionInfo] = useState(initialState);

  const {
    shouldFillPromptWAnswerValue,
    shouldFillQuestionWAnswerValue,
    shouldFillWithTimeframe,
    questionAnswer,
    timeframe,
    isConvertToPound,
    fillPlaceholder,
    questionPrompt,
    questionAnswerType,
    questionText,
    displayReportScore,
    questionScore,
    questionName,
    title,
    itemType,
    language,
    relatedQuestionIdForAnswerValues,
    relatedQuestionForAnswerValues,
  } = questionInfo;

  let answerValue: any = questionAnswer;

  if (shouldFillPromptWAnswerValue || shouldFillQuestionWAnswerValue) {
    answerValue = questionAnswer?.toString() || '';
    if (isConvertToPound && questionAnswerType === QuestionAnswerType.Weight) {
      answerValue = convertKgToLbs(Number(questionAnswer)).toString();
    }
  }

  if (!answerValue && !!fillPlaceholder) {
    answerValue = fillPlaceholder;
  }

  if (
    questionAnswer &&
    moduleType === InterviewReportType.Monitor &&
    questionAnswerType === QuestionAnswerType.Questionnaire
  ) {
    try {
      if (
        questionAnswer &&
        questionAnswer[0] === '[' &&
        questionAnswer[questionAnswer.length - 1] === ']'
      ) {
        answerValue = JSON.parse(questionAnswer);
        if (answerValue[0] !== '') {
          answerValue[0] = answerValue[0].split('|');
        }
      }
    } catch (e) {
      answerValue = questionAnswer;
    }
  }

  if (questionAnswer && questionAnswerType === QuestionAnswerType.MultiSelectList) {
    answerValue = questionAnswer.split('|');
  }

  let arrayAnswerValue = [];
  if (questionAnswer && questionAnswerType === QuestionAnswerType.StsEvent) {
    answerValue = questionAnswer?.toString() || '';
    arrayAnswerValue = JSON.parse(answerValue);
  }

  if (questionAnswer && questionAnswerType === QuestionAnswerType.YbocsCheckList) {
    answerValue = questionAnswer;

    if (questionAnswer.startsWith('|')) {
      answerValue = answerValue.replace('|', '');
    }

    answerValue = answerValue.replace(/\|/g, ',');
    answerValue = answerValue.split(',');

    if (answerValue && answerValue.length > 0) {
      answerValue = answerValue.sort().map((text: string) => capitalizeFirstLetter(text));
    }
  }

  if (
    questionAnswer &&
    (moduleType === InterviewReportType.YBOCS || moduleType === InterviewReportType.CYBOCS) &&
    (questionAnswerType === QuestionAnswerType.Text ||
      questionAnswerType === QuestionAnswerType.YbocsCheckOther)
  ) {
    answerValue = questionAnswer;
    answerValue = answerValue.replace('|', '');
    answerValue = answerValue.replace(/\|/g, ', ');
    answerValue = newlinesToBr(answerValue);
  }

  if (questionAnswer && questionAnswerType === QuestionAnswerType.Grid) {
    try {
      answerValue = JSON.parse(questionAnswer || '{}');
    } catch (e) {
      answerValue = questionAnswer;
    }
  }

  //------------------------------------------------------------------------------

  let promptDisplay: string = '';
  if (!!questionPrompt) {
    promptDisplay = questionPrompt;
    if (
      questionPrompt &&
      (subModuleType === InterviewReportType.PHQ ||
        questionAnswerType === QuestionAnswerType.Options ||
        questionAnswerType === QuestionAnswerType.MultiOptions ||
        questionAnswerType === QuestionAnswerType.Binary ||
        questionAnswerType === QuestionAnswerType.Number)
    ) {
      try {
        promptDisplay = Object.values(JSON.parse(questionPrompt))[0] as string;
      } catch (error) {
        promptDisplay = questionPrompt;
      }
    }

    if (shouldFillPromptWAnswerValue) {
      if (shouldFillQuestionWAnswerValue && !!relatedQuestionIdForAnswerValues) {
        promptDisplay = promptDisplay.replace(/%s/g, relatedQuestionForAnswerValues || '');
      } else {
        promptDisplay = promptDisplay.replace(/%s/g, answerValue);
      }
    }

    if (shouldFillWithTimeframe && !!timeframe) {
      promptDisplay = promptDisplay.replace('(timeframe)', timeframe);
    } else {
      promptDisplay = promptDisplay.replace('(timeFrame)', '');
    }
  }

  //------------------------------------------------------------------------------

  let questionDisplay: string = questionText || '';
  if (
    questionText &&
    (subModuleType === InterviewReportType.PHQ ||
      questionAnswerType === QuestionAnswerType.Options ||
      questionAnswerType === QuestionAnswerType.MultiOptions ||
      questionAnswerType === QuestionAnswerType.Text ||
      questionAnswerType === QuestionAnswerType.Date ||
      questionAnswerType === QuestionAnswerType.Formula ||
      questionAnswerType === QuestionAnswerType.Binary ||
      questionAnswerType === QuestionAnswerType.InfoNoAnswer ||
      questionAnswerType === QuestionAnswerType.Number ||
      (moduleType === InterviewReportType.Monitor &&
        questionAnswerType === QuestionAnswerType.Questionnaire))
  ) {
    try {
      questionDisplay = Object.values(JSON.parse(questionText))[0] as string;
    } catch (e) {
      questionDisplay = questionText;
    }
  }

  if (questionText && questionAnswerType === QuestionAnswerType.Table) {
    try {
      questionDisplay = JSON.parse(questionText.replace(/\\r\\n/g, '') || '{}');
    } catch (e) {
      questionDisplay = questionText;
    }
  }

  if (questionText && questionAnswerType === QuestionAnswerType.Grid) {
    try {
      questionDisplay = JSON.parse(questionText.replace(/\\r\\n/g, '') || '');
    } catch (e) {
      questionDisplay = questionText;
    }
  }

  if (shouldFillQuestionWAnswerValue) {
    const isM7S7Question = title === 'M7' || title === 'S7';
    const pattern = isM7S7Question ? /\([^\\)]+\)/g : /%s/g;
    if (!!relatedQuestionIdForAnswerValues) {
      questionDisplay = questionDisplay.replace(pattern, relatedQuestionForAnswerValues || '');
    } else {
      questionDisplay = questionDisplay.replace(/%s/g, answerValue);
    }
  }

  if (shouldFillWithTimeframe && !!timeframe) {
    questionDisplay = questionDisplay.replace('(timeframe)', timeframe);
  }

  if (moduleType === InterviewReportType.Monitor) {
    questionDisplay = questionDisplay.replace('%r', '');
    if (assessmentType === AssessmentMode.Clinician) {
      questionDisplay = questionDisplay.replace('%**?*', '**?<b> *</b>');
      questionDisplay = questionDisplay.replace('%**', '**');
      questionDisplay = questionDisplay.replace('%*', '<b> *</b>');
    } else {
      questionDisplay = questionDisplay.replace('%**?*', '');
      questionDisplay = questionDisplay.replace('%**', '');
      questionDisplay = questionDisplay.replace('%*', '');
    }
  }

  //------------------------------------------------------------------------------

  let questionNameDisplay: string = questionName || '';
  if (
    questionName &&
    (subModuleType === InterviewReportType.PHQ ||
      questionAnswerType === QuestionAnswerType.TargetSymptomListText ||
      questionAnswerType === QuestionAnswerType.Options ||
      questionAnswerType === QuestionAnswerType.MultiOptions ||
      questionAnswerType === QuestionAnswerType.Text ||
      questionAnswerType === QuestionAnswerType.Formula ||
      questionAnswerType === QuestionAnswerType.MultiSelectList ||
      questionAnswerType === QuestionAnswerType.Binary)
  ) {
    try {
      questionNameDisplay = Object.values(JSON.parse(questionName))[0] as string;
    } catch (e) {
      questionNameDisplay = questionName;
    }
  }

  //------------------------------------------------------------------------------

  let scoreForQuestion: string = '';
  if (!isNaN(questionScore!) && displayReportScore) {
    scoreForQuestion = `${questionScore}`;
  }

  //------------------------------------------------------------------------------

  let questionTitle: string = title || '';
  if (
    questionNameDisplay &&
    language === DefaultLanguage.Universal &&
    itemType !== ItemType.ExitCondition &&
    moduleType !== InterviewReportType.YBOCS &&
    moduleType !== InterviewReportType.CYBOCS
  ) {
    questionTitle = questionNameDisplay;
  }

  return {
    questionDisplay,
    promptDisplay,
    scoreForQuestion,
    answerValue,
    arrayAnswerValue,
    questionNameDisplay,
    questionTitle,
  };
}
