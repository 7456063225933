import { JSXElementConstructor, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import { t } from 'i18next';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { isValidInterviewConductRoute } from '../../../app/routes';
import {
  useRequestInterviewByTokenMutation,
  useRequestInterviewMutation,
  useSubmitInterviewAnswersByTokenMutation,
  useSubmitInterviewAnswersMutation,
} from '../../../app/services/interview';
import { useGetPatientQuery } from '../../../app/services/patient';
import Loading from '../../../common/components/Loading';
import PatientInfoCard from '../../../common/components/PatientInfoCard';
import RoundedContainer from '../../../common/components/RoundedContainer';
import { convertFeetInchesToCm, convertLbsToKg } from '../../../common/utils/commonCalculate';
import { validateHeight } from '../../../common/utils/commonUtils';
import { HttpStatusCode } from '../../../common/utils/httpStatusCode';
import { InterviewMode } from '../../../common/utils/interviewsMode';
import { RouteReportType } from '../../../common/utils/pageUtils';
import { QuestionAnswerType } from '../../../common/utils/questionAnswerTypes';
import { PATIENT_ASSESSMENT_EXPIRE_TIME_MILLISECONDS } from '../../../common/utils/securityConfig';
import { RegexValidation } from '../../../common/utils/validationUtils';
import {
  InterviewAnswerDto,
  InterviewAnswerValueDto,
  InterviewFormatDto,
  SequenceItemDto,
} from '../../../types/InterviewQuestionTypes';
import {
  ConductInterviewProps,
  InterviewResponse,
  InterviewSubmissionByTokenRequest,
  InterviewSubmissionRequest,
  InterviewSubmissionResponse,
  WatchedQuestion,
} from '../../../types/InterviewRequest';
import { PatientMonitorData } from '../../../types/MonitorTypes';
import { QuestionError, QuestionResponseError, ResponseError } from '../../../types/ResponseError';
import { selectIsAuthenticated } from '../../auth/authSlice';
import ErrorPage from '../../error/ErrorPage';
import { clearPatientMonitorData, selectPatientMonitorData } from '../../monitor/monitorSlide';
import usePatientSessionExpiration from '../hooks/usePatientSessionExpiration';
import useSubmitSingleQuestion from '../hooks/useSubmitSingleQuestion';
import {
  clearCurrentInterview,
  clearPatientInterviewData,
  PatientInterviewData,
  selectAssessmentUrl,
  selectInterview,
  selectInterviewType,
  selectIsEditInterview,
  selectIsInProgressModule,
  selectPatientInterviewData,
  selectPreviousAnswers,
  selectQuestionType,
  selectTimeframeInDays,
  selectWatchQuestion,
  setCurrentInterview,
  setErrorFromResponse,
  setPatientInterviewData,
  updateCurrentInterview,
} from '../interviewSlice';
import {
  checkTimeframe,
  ClinicianNoteField,
  getQuestionControlName,
  InterviewReportType,
} from '../interviewUtils';
import MiniMinimizeInterview from './MiniMinimizeInterview/MiniMinimizeInterview';

const interviewTypeMap: { [type: string]: JSXElementConstructor<ConductInterviewProps> } = {
  [RouteReportType.QUESTION]: MiniMinimizeInterview,
};

const normalizeAnswerValue = (answer: InterviewAnswerValueDto) => {
  if (answer) {
    if (answer.value) return answer.value;
    if (answer.valueCentimeters) return answer.valueCentimeters;
    if (answer.valueKilograms) return answer.valueKilograms;
    if (answer.valueInches || answer.valueFeet) {
      return convertFeetInchesToCm(answer.valueFeet || 0, answer.valueInches || 0, true);
    }
    if (answer.valuePounds) return convertLbsToKg(answer.valuePounds);
  }

  return answer;
};

const convertSubmitAnswers = (
  interviewResultId: number,
  submitAnswers: Record<string, any>,
  currentSequenceItems?: SequenceItemDto[]
): InterviewSubmissionRequest => {
  const answers: InterviewAnswerDto[] = [];

  currentSequenceItems?.forEach((item) => {
    const controlName = getQuestionControlName(item.questionId);
    const submitAnswer = submitAnswers[controlName];

    if (submitAnswer !== undefined && submitAnswer !== null) {
      const answer: InterviewAnswerDto = {
        questionId: item.questionId,
        answer: typeof submitAnswer === 'object' ? { ...submitAnswer } : { value: submitAnswer },
      };

      answers.push(answer);
    }
  });

  return {
    interviewTestId: interviewResultId,
    clinicianNotes: submitAnswers[ClinicianNoteField],
    answers,
  };
};

const getRelatedClientSideScripts = (
  sequenceItems: SequenceItemDto[]
): { script: string; questionId: number }[] => {
  return sequenceItems
    .filter((item) => !!item.question?.clientDisplayCondition)
    .map((item) => ({
      questionId: item.questionId,
      script: item.question?.clientDisplayCondition as string,
    }));
};

const buildDefaultValueAndValidationObject = (
  t: TFunction,
  currentSequenceItems?: SequenceItemDto[]
) => {
  const validationData: any = {};
  const defaultValues: any = {};

  if (currentSequenceItems) {
    currentSequenceItems.forEach(({ question, questionId }: SequenceItemDto) => {
      const key = getQuestionControlName(questionId);

      if (questionId) {
        defaultValues[key] = '';

        if (
          question?.isRequired &&
          question?.questionAnswerType !== QuestionAnswerType.InfoNoAnswer &&
          question?.questionAnswerType !== QuestionAnswerType.Formula
        ) {
          validationData[key] = yup.string().nullable().required(t('interview.error.blankAnswer'));
        }
        // TODO: move if statements to mapping object
        if (question?.questionAnswerType === QuestionAnswerType.Height) {
          defaultValues[key] = {
            valueFeet: '',
            valueInches: '',
            valueCentimeters: '',
          };
          validationData[key] = yup
            .object({
              valueFeet: yup.string().nullable(),
              valueInches: yup.string().nullable(),
              valueCentimeters: yup.string().nullable(),
            })
            .test(
              'is-optional-height',
              t('interview.error.blankAnswer'),
              function ({ valueCentimeters, valueFeet, valueInches }) {
                if (!valueCentimeters) {
                  if (!valueFeet || !valueInches) {
                    return false;
                  }
                }
                if (question.name === 'L1a' || question.name === 'R1a') {
                  return validateHeight(valueFeet, valueInches, valueCentimeters, this.createError) ?? true;
                }
                return true;
              }
            );
        }
        if (question?.questionAnswerType === QuestionAnswerType.Weight) {
          defaultValues[key] = {
            valuePounds: '',
            valueKilograms: '',
          };
          validationData[key] = yup
            .object({
              valuePounds: yup.string().nullable(),
              valueKilograms: yup.string().nullable(),
            })
            .test(
              'is-optional-weight',
              t('interview.error.blankAnswer'),
              function ({ valuePounds, valueKilograms }) {
                if (!valueKilograms) {
                  if (!valuePounds) {
                    return false;
                  }
                  return true;
                }
                return true;
              }
            );
        }
        if (question?.questionAnswerType === QuestionAnswerType.TimeDayMonth) {
          validationData[key] = yup
            .string()
            .matches(RegexValidation.NumberOrEmptyString, t('interview.error.numberOnly'));
        }
        if (question?.questionAnswerType === QuestionAnswerType.Number) {
          defaultValues[key] = 0;
          if (question?.enableTimeframeChecking) {
            validationData[key] = yup
              .number()
              .min(0, t('interview.error.positiveNumberOnly'))
              .typeError(t('interview.error.numberOnly'))
              .transform((value) => value || 0)
              .required(t('interview.error.blankAnswer'));
          } else {
            validationData[key] = yup
              .number()
              .typeError(t('interview.error.numberOnly'))
              .transform((value) => value || 0)
              .required(t('interview.error.blankAnswer'));
          }
        }
        if (question?.questionAnswerType === QuestionAnswerType.Grid) {
          let schema = question?.isRequired
            ? yup.mixed().required(t('interview.error.blankAnswer'))
            : undefined;
          validationData[key] = schema;
        }
        if (question?.questionAnswerType === QuestionAnswerType.Date) {
          defaultValues[key] = null;
        }
      }
    });
  }
  return { validationData, defaultValues };
};

const setListResultIds = (interviewResultId: string | number) => {
  const savedListResultIds = localStorage.getItem('listResultIds');
  let parsedListResultIds = savedListResultIds ? JSON.parse(savedListResultIds) : [];
  if (!parsedListResultIds?.length) {
    parsedListResultIds = [];
    parsedListResultIds.push(interviewResultId);
  } else if (!parsedListResultIds.includes(interviewResultId)) {
    parsedListResultIds.push(interviewResultId);
  }
  localStorage.setItem('listResultIds', JSON.stringify(parsedListResultIds));
};

const classifyQuestionWithDisplayCondition = (
  answers: Record<string, any>,
  currentSequenceItems: SequenceItemDto[]
): { showQuestions: SequenceItemDto[]; hideQuestions: string[] } => {
  const hideQuestions: string[] = [];
  let exitQuestion = true;

  const showQuestions = currentSequenceItems.filter((currentSequenceItem: SequenceItemDto) => {
    const {
      questionId,
      question,
      itemType,
      sequenceOrder,
      displayCondition: exitDisplayCondition,
      dependencyQuestions: exitDependencyQuestions,
    } = currentSequenceItem;
    let currentQuestionName = getQuestionControlName(questionId);

    if (!exitQuestion) {
      hideQuestions.push(currentQuestionName);
      return exitQuestion;
    }

    if (!questionId && itemType !== 'exitCondition') {
      return false;
    }

    if (itemType === 'exitCondition') {
      currentQuestionName = `exitCondition_${sequenceOrder}`;
    }

    let displayCondition;
    if (question?.displayCondition) {
      displayCondition = question.displayCondition;
    } else if (exitDisplayCondition) {
      displayCondition = exitDisplayCondition;
    } else if (!displayCondition) {
      return true;
    }

    const dependencyQuestions = question?.dependencyQuestions || exitDependencyQuestions || [];
    const replacementPattern = dependencyQuestions
      .map((dep) => `'<${dep.questionId}>'|<${dep.questionId}>`)
      .join('|');

    const replaceMatch = (match: string) => {
      const dependencyQuestion = dependencyQuestions.find(
        (dep) => `'<${dep.questionId}>'` === match || `<${dep.questionId}>` === match
      );

      if (dependencyQuestion) {
        let answer = answers[getQuestionControlName(dependencyQuestion.questionId)];

        return `'${
          normalizeAnswerValue(answer) ||
          normalizeAnswerValue(dependencyQuestion.defaultAnswer) ||
          ''
        }'`;
      }

      return match;
    };

    displayCondition = displayCondition.replace(new RegExp(replacementPattern, 'g'), replaceMatch);

    try {
      // eslint-disable-next-line no-new-func
      const shouldShowQuestion = new Function(`return ${displayCondition}`)();

      if (!shouldShowQuestion) {
        hideQuestions.push(currentQuestionName);
      }

      if (shouldShowQuestion && itemType === 'exitCondition') {
        exitQuestion = false;
        return exitQuestion;
      } else {
        exitQuestion = true;
      }

      return shouldShowQuestion;
    } catch (error) {
      console.error(
        `Error evaluating display condition for questionId ${currentQuestionName}:`,
        error
      );

      hideQuestions.push(currentQuestionName);
      return false;
    }
  });

  return { showQuestions, hideQuestions };
};

interface Props {
  interviewContainerRef: RefObject<HTMLDivElement>;
  isEditInterview: boolean;
  isClinicianInterview: boolean;
}

export default function ExtendedConductInterviewPage({
  interviewContainerRef,
  isEditInterview,
  isClinicianInterview,
}: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentInterview = useAppSelector<InterviewFormatDto>(selectInterview);
  const patientInterviewData = useAppSelector<PatientInterviewData>(selectPatientInterviewData);
  const patientMonitorData = useAppSelector<PatientMonitorData>(selectPatientMonitorData);
  const isAuthenticated = useAppSelector<boolean>(selectIsAuthenticated);
  const assessmentUrl = useAppSelector<string>(selectAssessmentUrl);
  const timeframeInDays = useAppSelector<number>(selectTimeframeInDays);
  const watchQuestion = useAppSelector<WatchedQuestion>(selectWatchQuestion);
  const isInProgressModule = useAppSelector<boolean>(selectIsInProgressModule);
  const previousAnswers =
    useAppSelector<Record<number, InterviewAnswerValueDto>>(selectPreviousAnswers);
  const questionTypeMap = useAppSelector<Record<number, string>>(selectQuestionType);
  const interviewEdited = useAppSelector<boolean>(selectIsEditInterview);
  const interviewType = useAppSelector<string>(selectInterviewType);

  const showYbocsModule = [InterviewReportType.YBOCS, InterviewReportType.CYBOCS].includes(
    currentInterview?.reportType!
  );

  const currentModule = (currentInterview?.modules && currentInterview.modules[0]) || {};

  const {
    patientId,
    interviewResultId: inputInterviewResultId,
    reportType: reportTypePath,
  } = useParams();
  const interviewResultId =
    parseInt(inputInterviewResultId!) || patientInterviewData.interviewTestId;

  const [requestInterview] = useRequestInterviewMutation();
  const [requestInterviewByToken] = useRequestInterviewByTokenMutation();
  const [submitInterviewAnswers] = useSubmitInterviewAnswersMutation();
  const [submitInterviewAnswersByToken] = useSubmitInterviewAnswersByTokenMutation();

  const [dataLoadError, setDataLoadError] = useState<boolean>(false);
  const [isInterviewCompleted, setInterviewCompleted] = useState<boolean>(false);
  const [nextInterviewResultId, setNextInterviewResultId] = useState<number>(0);
  const [nextTrackerResultId, setNextTrackerResultId] = useState<number>(0);
  const [currentReportType, setCurrentReportType] = useState<string>('');
  const [currentSequenceItems, setCurrentRenderSequenceItems] = useState<SequenceItemDto[]>([]);
  const [pageErrors, setPageErrors] = useState<string[]>([]);

  const previousHideQuestions = useRef<string[]>([]);
  const shouldRequestInterviewRef = useRef<boolean>(true);

  usePatientSessionExpiration({
    isClinician: isClinicianInterview,
    assessmentUrl,
  });

  const { data: patientInfo, error: patientGetError } = useGetPatientQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  const { validationData, defaultValues } = useMemo(() => {
    return buildDefaultValueAndValidationObject(t, currentSequenceItems);
  }, [currentSequenceItems]);

  const validationSchema = yup.object(validationData).required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    resetField,
    setValue,
    watch,
    getValues,
    reset,
    trigger,
    clearErrors,
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const registerAllFields = () => {
    reset();
  };

  const relatedClientSideScripts = getRelatedClientSideScripts(currentSequenceItems || []);
  const scrollToTop = () => {
    if (interviewContainerRef.current) {
      interviewContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const watchingAnswers = watch();

  const { queueStillRunning, addRequestToSubQueue } = useSubmitSingleQuestion({
    isInProgressModule,
    interviewResultId,
    currentSequenceItems,
    patientId,
    isClinicianInterview,
    patientInterviewData,
    patientMonitorData,
    submitInterviewAnswers,
    submitInterviewAnswersByToken,
    control,
    watchQuestion,
    interviewEdited,
  });

  const questionWithCondition = useMemo(() => {
    return classifyQuestionWithDisplayCondition(
      watchingAnswers,
      currentModule.sequenceOrders || []
    );
  }, [watchingAnswers, currentModule.sequenceOrders]);

  useEffect(() => {
    if (watchingAnswers) {
      if (
        JSON.stringify(questionWithCondition.showQuestions) !== JSON.stringify(currentSequenceItems)
      ) {
        setCurrentRenderSequenceItems(questionWithCondition.showQuestions);
      }

      if (
        JSON.stringify(questionWithCondition.hideQuestions) !==
        JSON.stringify(previousHideQuestions.current)
      ) {
        questionWithCondition.hideQuestions.forEach((questionName: string) => {
          resetField(questionName, { defaultValue: undefined });
        });
        previousHideQuestions.current = questionWithCondition.hideQuestions;
      }
    }
  }, [
    currentSequenceItems,
    questionWithCondition.hideQuestions,
    questionWithCondition.showQuestions,
    resetField,
    watchingAnswers,
  ]);

  useEffect(() => {
    setListResultIds(interviewResultId?.toString());
    if (patientInterviewData?.interviewTestId) {
      setListResultIds(patientInterviewData.interviewTestId?.toString());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (previousAnswers) {
      for (const questionId in previousAnswers) {
        const setFormValue = (
          controlId: string,
          questionAnswerType: string,
          previousAnswerValue: InterviewAnswerValueDto
        ) => {
          const customValueGetterMap = {
            [QuestionAnswerType.Height]: () => ({
              valueInches: previousAnswerValue.valueInches,
              valueFeet: previousAnswerValue.valueFeet,
              valueCentimeters: previousAnswerValue.valueCentimeters,
            }),
            [QuestionAnswerType.Weight]: () => ({
              valuePounds: previousAnswerValue.valuePounds,
              valueKilograms: previousAnswerValue.valueKilograms,
            }),
          };
          const getPreviousAnswerValue =
            customValueGetterMap[questionAnswerType] || (() => previousAnswerValue.value);
          setValue(controlId, getPreviousAnswerValue());
        };

        if (previousAnswers[questionId]) {
          setFormValue(
            getQuestionControlName(questionId),
            questionTypeMap[questionId],
            previousAnswers[questionId]
          );
        }
      }
    }
  }, [previousAnswers, questionTypeMap, setValue]);

  useEffect(() => {
    if (shouldRequestInterviewRef.current) {
      const bindInterviewData = async () => {
        let interviewResponse: InterviewResponse | null = null;
        try {
          if (isClinicianInterview) {
            interviewResponse = await requestInterview({
              interviewTestId: interviewResultId,
            }).unwrap();
          } else if (patientInterviewData || patientMonitorData) {
            interviewResponse = await requestInterviewByToken({
              accessToken: patientInterviewData?.accessToken || patientMonitorData?.accessToken,
            }).unwrap();
          }
          shouldRequestInterviewRef.current = false;

          if (interviewResponse) {
            dispatch(setCurrentInterview({ interviewData: interviewResponse }));
          }
        } catch (e) {
          if ((e as ResponseError).status === HttpStatusCode.BadRequest) {
            setDataLoadError(true);
          }
        }
      };
      bindInterviewData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRequestInterviewRef.current]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      dispatch(clearCurrentInterview());
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmissionResponse = async (submissionResponse: InterviewSubmissionResponse) => {
    if (submissionResponse.success) {
      if (submissionResponse.completed) {
        if (!currentReportType) {
          setCurrentReportType(currentInterview?.reportType!);
        }
        if (submissionResponse.nextInterviewResultId) {
          setListResultIds(submissionResponse.nextInterviewResultId.toString());
          setNextInterviewResultId(submissionResponse.nextInterviewResultId);
        } else if (submissionResponse.nextTrackerResultId) {
          setListResultIds(submissionResponse.nextTrackerResultId.toString());
          setNextTrackerResultId(submissionResponse.nextTrackerResultId);
        }
        setInterviewCompleted(true);
      } else {
        dispatch(
          updateCurrentInterview({
            interview: submissionResponse.interview,
            currentModuleId: submissionResponse.currentModuleId,
            incompleteModuleId: submissionResponse.incompleteModuleId,
          })
        );
        shouldRequestInterviewRef.current = true;
        scrollToTop();
      }
      setPageErrors([]);
    }
  };

  const handleErrorResponse = (errorResponse: QuestionError) => {
    const { questions } = errorResponse;
    if (questions) {
      dispatch(setErrorFromResponse({ errorQuestions: questions }));
    }
  };

  const onSubmit = async (data: any) => {
    const timeframeError = checkTimeframe(
      currentInterview,
      data,
      currentSequenceItems,
      timeframeInDays
    );

    if (timeframeError) {
      setPageErrors([timeframeError]);
      return;
    }

    const submitData = {
      ...convertSubmitAnswers(interviewResultId, data, currentSequenceItems),
      patientId,
    };

    addRequestToSubQueue(async () => {
      try {
        let submissionResponse: InterviewSubmissionResponse = {};
        if (isClinicianInterview) {
          submissionResponse = await submitInterviewAnswers(submitData).unwrap();
        } else {
          submissionResponse = await submitInterviewAnswersByToken({
            ...submitData,
            accessToken: patientInterviewData?.accessToken || patientMonitorData?.accessToken,
          } as InterviewSubmissionByTokenRequest).unwrap();

          // reset last actity timestamp & token
          dispatch(clearPatientMonitorData());
          dispatch(
            setPatientInterviewData({
              data: {
                ...patientInterviewData,
                lastActivityTs: Date.now(),
                accessToken:
                  submissionResponse.nextPatientInterviewToken ||
                  submissionResponse.nextPatientMonitorToken ||
                  submissionResponse.patientInterviewToken ||
                  '',
              },
            })
          );
        }

        handleSubmissionResponse(submissionResponse);
      } catch (e) {
        const {
          data: { error },
        } = e as QuestionResponseError;

        const questionError = error as any as QuestionError;
        handleErrorResponse(questionError);
        setPageErrors(questionError.interview || questionError || []);
      }
    });
  };

  const savedListResultIds = localStorage.getItem('listResultIds');
  const parsedListResultIds = savedListResultIds ? JSON.parse(savedListResultIds) : [];
  const isValidResultId =
    !parsedListResultIds || parsedListResultIds.includes(inputInterviewResultId);

  if (isInterviewCompleted) {
    let nextPageUrl = null;
    if (nextInterviewResultId) {
      nextPageUrl = patientId
        ? `/current-interview/continue-next-interview/${currentReportType}/${patientId}/${nextInterviewResultId}`
        : `/current-interview/continue-next-interview/${currentReportType}/${nextInterviewResultId}`;
    } else if (nextTrackerResultId) {
      nextPageUrl = patientId
        ? `/current-monitor/continue-next-monitor/${patientId}/${nextTrackerResultId}`
        : `/current-monitor/continue-next-monitor/${nextTrackerResultId}`;
    } else {
      nextPageUrl =
        isClinicianInterview || isEditInterview
          ? `/dashboard/current-interview/interview-complete/${patientId}/${interviewResultId}`
          : `/current-interview/complete/${interviewResultId}`;
    }
    return (
      <Navigate
        to={nextPageUrl}
        state={{ editInterview: isEditInterview, previousPathInterviewTestId: interviewResultId }}
      ></Navigate>
    );
  }

  const InterviewComponent = interviewTypeMap[reportTypePath || ''];
  if (!InterviewComponent) {
    return <Typography color="red">The conduct interview type is not implement yet!</Typography>;
  }

  if (
    !isValidInterviewConductRoute(reportTypePath!) ||
    dataLoadError ||
    (!isAuthenticated &&
      (!isValidResultId ||
        (!patientInterviewData?.accessToken && !patientMonitorData?.accessToken)))
  ) {
    return <ErrorPage statusCode={HttpStatusCode.NotFound} />;
  }

  if (
    !isClinicianInterview &&
    patientInterviewData &&
    (!patientInterviewData.lastActivityTs ||
      patientInterviewData.lastActivityTs + PATIENT_ASSESSMENT_EXPIRE_TIME_MILLISECONDS <
        Date.now())
  ) {
    dispatch(clearPatientInterviewData());
    dispatch(clearPatientMonitorData());
    localStorage.removeItem('listResultIds');
    navigate('/login');
    return <></>;
  }

  if (patientGetError) {
    return <ErrorPage statusCode={(patientGetError as FetchBaseQueryError).status} />;
  }

  return (
    <>
      {(isClinicianInterview ||
        (interviewType === InterviewMode.ExternalClinician && showYbocsModule)) && (
        <RoundedContainer sx={{ py: 2 }}>
          <PatientInfoCard data={patientInfo!} interview={currentInterview} />
        </RoundedContainer>
      )}

      {!currentInterview ? (
        <Loading />
      ) : (
        <Box sx={{ mt: 2 }} component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
          <InterviewComponent
            errors={errors}
            control={control}
            setValue={setValue}
            getValues={getValues}
            defaultValues={defaultValues}
            relatedClientSideScripts={relatedClientSideScripts}
            optionChangeCallback={undefined}
            parentQuestions={undefined}
            currentSequenceItems={currentSequenceItems}
            reportType={currentInterview.reportType}
            pageErrors={pageErrors}
            resetAllFields={registerAllFields}
            handleSubmit={() => {
              handleSubmit(onSubmit)();
            }}
            triggerValidation={trigger}
            scrollToTop={scrollToTop}
            clearErrors={clearErrors}
            queueStillRunning={queueStillRunning}
          />
        </Box>
      )}
    </>
  );
}
