import dayjs from 'dayjs';

const DATE_FORMAT = 'MM/DD/YYYY';

export const formatServerDate = (date: any): string => {
  if (!date) {
    return date;
  }
  // time was already converted in server and in UTC format -> just pick the date part and format it
  const adjustedDate = ('' + date).split('T')[0];
  return dayjs(adjustedDate).format(DATE_FORMAT);
};

export const formatDate = (date: any): string => {
  if (!date) return '';
  return dayjs(date).format(DATE_FORMAT);
};

export const formatServerDateAndTime = (date: any): string => {
  const adjustedTime = ('' + date).split('T')[1];
  const hours = adjustedTime && adjustedTime.split(':')[0];
  const minutes = adjustedTime && adjustedTime.split(':')[1];
  const minutesNumber = parseInt(minutes) ? parseInt(minutes) : 0;
  let hoursNumber = parseInt(hours) ? parseInt(hours) : 0;
  let ampm = hoursNumber >= 12 ? 'PM' : 'AM';
  hoursNumber = hoursNumber % 12;
  hoursNumber = hoursNumber ? hoursNumber : 12;
  const minutesText = minutesNumber < 10 ? '0' + minutesNumber.toString() : minutes;
  const strTime =
    (hoursNumber < 10 ? '0' + hoursNumber.toString() : hoursNumber.toString()) +
    ':' +
    minutesText +
    ' ' +
    ampm;

  return formatServerDate(date) + ' | ' + strTime;
};

export const getTotalTime = (startDate: any, endDate: any, duration?: any): string => {
  let totalTime = '0:00';
  if (duration) {
    return dayjs.duration(duration, 'seconds').format('H:mm:ss');
  }
  if (
    typeof startDate !== 'undefined' &&
    !!startDate &&
    typeof endDate !== 'undefined' &&
    !!endDate
  ) {
    const started = dayjs(startDate);
    const ended = dayjs(endDate);

    totalTime = dayjs.duration(ended.diff(started, 'seconds'), 'seconds').format('H:mm:ss');
  }

  return totalTime;
};

export const isValidDate = (inputDate: any): boolean => {
  return dayjs(inputDate, 'MM/DD/YYYY').isValid();
};

export const validDatePattern = [
  'YYYY/DD/MM',
  'YYYY/MM/DD',
  'MM-DD-YYYY',
  'MM/DD/YYYY',
  'DD/MM/YYYY',
];

export const validDateTimePattern = [
  'YYYY/DD/MM hh:mm:ss a',
  'YYYY/MM/DD hh:mm:ss a',
  'MM-DD-YYYY hh:mm:ss a',
  'MM/DD/YYYY hh:mm:ss a',
  'DD/MM/YYYY hh:mm:ss a',
  'YYYY/DD/MM hh:mm:ss',
  'YYYY/MM/DD hh:mm:ss',
  'MM-DD-YYYY hh:mm:ss',
  'MM/DD/YYYY hh:mm:ss',
  'DD/MM/YYYY hh:mm:ss',
  'YYYY/DD/MM hh:mm',
  'YYYY/MM/DD hh:mm',
  'MM-DD-YYYY hh:mm',
  'MM/DD/YYYY hh:mm',
  'DD/MM/YYYY hh:mm',
];

export const formatDateTimeByPattern = ({
  dateValue,
  pattern,
  validPatterns,
}: {
  dateValue: string;
  pattern?: string;
  validPatterns?: string[];
}) => {
  const validDateTimePatterns: string[] = validPatterns?.length
    ? validPatterns
    : [...validDatePattern, ...validDateTimePattern];

  if (pattern && validDateTimePatterns.includes(pattern)) {
    return dayjs(dateValue).format(pattern);
  }
  return dayjs(dateValue).format('MM/DD/YYYY'); //default format
};

export const utcTimeZones = [
  'Africa/Accra',
  'Atlantic/Reykjavik',
  'Europe/Dublin',
  'Europe/Lisbon',
  'Europe/London',
];
