import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ExportAllUserTypes } from '../../common/utils/exportUtils';
import { ClinicAccountItems } from '../../types/ClinicAccountType';
import { ResponseError } from '../../types/ResponseError';
import { ExportUserRequest } from '../../types/UserTypes';

interface ExportUserFormProps {
  clinicAccounts?: ClinicAccountItems[];
  onFormSubmit: (data: ExportUserRequest, successCallback?: () => void) => Promise<void>;
}

const StyleAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: '49%',
  '& .MuiOutlinedInput-root': {
    padding: '10px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));
const defaultFormValues: ExportUserRequest = {
  clinicAccountId: '',
};

export default function ExportForm({ clinicAccounts, onFormSubmit }: ExportUserFormProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const validationSchema = yup
    .object()
    .shape({
      clinicAccountId: yup.string().required(t('user.manageUsers.error.blankAccount')),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<ExportUserRequest>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const filterAccountOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getAccountOptionLabel(option),
  });

  const getAccountOptionLabel = (option: any) =>
    `${option.name}${option.name === ExportAllUserTypes.name ? '' : ` (${option.id})`}`;

  const handleAccountChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('clinicAccountId', selectedValue);
    setPageErrors([]);
  };

  const onSubmit = async (data: ExportUserRequest) => {
    setPageErrors([]);
    const submitData = {
      clinicAccountId: data.clinicAccountId,
    };

    try {
      await onFormSubmit(submitData, () => {
        setPageErrors([]);
      });
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        px: matches ? 0 : 3,
        pt: 3,
        pb: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Controller
          name="clinicAccountId"
          control={control}
          render={({ fieldState, formState, field: { value }, ...props }) => (
            <StyleAutocomplete
              style={{ width: isDesktop ? '78%' : '100%' }}
              {...props}
              data-testid="mui-component-select-clinic-account"
              options={clinicAccounts || []}
              getOptionLabel={getAccountOptionLabel}
              onChange={handleAccountChange}
              value={value ? clinicAccounts?.find((item) => item.id === value) || null : null}
              filterOptions={filterAccountOptions}
              renderOption={(props, option: any) => {
                return (
                  <li {...props} key={option.id} value={option.id}>
                    {getAccountOptionLabel(option)}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  fullWidth
                  label={t('user.manageUsers.userForm.account')}
                  error={!!errors.clinicAccountId}
                  helperText={errors.clinicAccountId?.message}
                  variant="outlined"
                />
              )}
            />
          )}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            width: isDesktop ? '20%' : '100%',
            height: isDesktop ? '60px' : '100%',
            my: 2,
            py: 2,
            float: 'right',
          }}
        >
          {t('user.manageUsers.exportUserForm.export')}
        </Button>
      </Box>
      <Box>
        {pageErrors?.length > 0 &&
          pageErrors.map((error) => (
            <Alert key={error} severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          ))}
      </Box>
    </Box>
  );
}
