import { ChangeEvent, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { QuestionAnswerType } from '../../../common/utils/questionAnswerTypes';
import { InterviewQuestionProps } from '../../../types/InterviewQuestionTypes';
import useSetWatchQuestion from '../hooks/useSetWatchQuestion';

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: '8px',

  '&:last-of-type': {
    color: theme.xPalette.lightGrey,
  },
}));

const StyledFormLabel = styled(FormControlLabel)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  margin: 0,
}));

const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-checked': {
    color: theme.xPalette.completed.color,
  },
}));

export default function MultiSelectQuestion({
  question,
  field,
  error,
  helperText,
  setValue,
}: InterviewQuestionProps) {
  const [multiSelect, setMultiSelect] = useState<string[]>([]);
  const debounce = useRef<boolean>(true);

  const { handleOnBlur } = useSetWatchQuestion({
    name: field?.name!,
    type: QuestionAnswerType.MultiSelectList,
    required: !!question.isRequired,
  });

  const renderItems = () => {
    const { possibleAnswers, displayAnswers } = question;
    const items = [];
    for (let i = 0; i < possibleAnswers.length; i++) {
      const value = possibleAnswers[i];
      const text = displayAnswers?.length === possibleAnswers?.length
          ? `${displayAnswers?.[i] || value}`.trim()
          : value.trim();
      items.push(
        <StyledFormLabel
          checked={multiSelect.includes(value)}
          key={value}
          value={value}
          control={<StyledCheckBox />}
          label={text}
        />
      );
    }
    return items;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let temp = [];
    const checkExistValue = multiSelect.includes(value);
    if (!checkExistValue) {
      temp = [...multiSelect, value];
      setMultiSelect(temp);
    } else {
      temp = [...multiSelect.filter((item) => item !== value)];
      setMultiSelect(temp);
    }

    handleOnBlur();
  };

  useEffect(() => {
    if (field?.value && typeof field?.value === 'string') {
      if (debounce.current) {
        debounce.current = false;
        const savedValue = field?.value.split('|');
        setMultiSelect(savedValue);
      }
    }
  }, [field?.value]);

  useEffect(() => {
    setValue(field?.name!, multiSelect.join('|'));
  }, [field?.name, multiSelect, setValue]);

  return (
    <Box sx={{ my: 2 }}>
      {question.questionPrompt && (
        <StyledTypography
          dangerouslySetInnerHTML={{
            __html: question.questionPrompt,
          }}
        ></StyledTypography>
      )}
      <StyledTypography
        dangerouslySetInnerHTML={{
          __html: question.questionText!,
        }}
      ></StyledTypography>
      <FormControl error={error} onChange={handleChange} variant="standard">
        <FormGroup {...field}>{renderItems()}</FormGroup>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
}
