import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import {
  IDLE_CHECK_EVENTS,
  PATIENT_SESSION_EXPIRE_MILLISECONDS,
} from '../../../common/utils/securityConfig';
import { useAppSelector } from '../../../app/hooks';
import { selectIsAuthenticated, selectIsFromIframe } from '../../auth/authSlice';
import { selectInterviewMode } from '../interviewSlice';
import { InterviewMode } from '../../../common/utils/interviewsMode';

interface Props {
  isClinician: boolean;
  assessmentUrl: string;
}

export default function usePatientSessionExpiration({ isClinician, assessmentUrl }: Props): void {
  const navigate = useNavigate();
  const isFromIframe = useAppSelector(selectIsFromIframe);
  const interviewMode = useAppSelector<string>(selectInterviewMode);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isClinicianResuming = interviewMode === InterviewMode.Clinician || (isAuthenticated && interviewMode === InterviewMode.ExternalClinician);
  const handleOnIdle = (_: Event) => {
    if (assessmentUrl) {
      navigate('/assessment/expire', { state: { assessmentUrl } });
    }
  };

  const { pause } = useIdleTimer({
    timeout: PATIENT_SESSION_EXPIRE_MILLISECONDS,
    onIdle: handleOnIdle,
    debounce: 500,
    events: IDLE_CHECK_EVENTS,
    startOnMount: !isClinician,
  });
  (isFromIframe || isClinicianResuming) && pause();
}
