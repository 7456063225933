import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Hidden from '@mui/material/Hidden';
import Popover from '@mui/material/Popover';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { InterviewMode } from '../../../common/utils/interviewsMode';
import { InterviewQuestionProps } from '../../../types/InterviewQuestionTypes';
import {
  selectInterviewType,
  selectResetQuestion,
  setResetQuestion,
} from '../../interview/interviewSlice';
import SectionOne from './sdsScaleSections/SectionOne';
import SectionThree from './sdsScaleSections/SectionThree';
import SectionTwo from './sdsScaleSections/SectionTwo';

const StyledFormLabel = styled(FormControlLabel)(() => ({
  alignItems: 'flex-start',
  margin: '8px 0px',
  '&.MuiFormControlLabel-root .MuiCheckbox-root': {
    padding: '0px 9px',
  },
}));

export const StyledFormSelected = styled(FormControlLabel)(() => ({
  margin: 0,
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: '16px 0px',
  width: '100%',

  '&.MuiFormControl-root .MuiFormGroup-root': {
    flexWrap: 'wrap',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const StyledTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  marginBottom: '8px',
}));

const commonStylesIcon = {
  minHeight: '38px',
  fontSize: '14px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
};

export const StyledRadioCheckIcon = styled(Box)(({ theme }) => ({
  minWidth: '38px',
  ...commonStylesIcon,
  backgroundColor: theme.xPalette.completed.color,
  color: theme.xPalette.white,
  border: `1px solid ${theme.xPalette.completed.color}`,
}));

export const StyledRadioIcon = styled(Box)(({ theme }) => ({
  minWidth: '38px',
  ...commonStylesIcon,
  backgroundColor: theme.xPalette.white,
  color: theme.xPalette.shadeGrey,
  border: `1px solid ${theme.xPalette.shadeGrey}`,
}));

export const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-checked': {
    color: theme.xPalette.completed.color,
  },
}));

enum PossibleAnswers {
  Na = 'N/A',
  Yes = 'yes',
  No = 'no',
}

const StyledCheckIconCustom = styled(Box)(({ theme }) => ({
  ...commonStylesIcon,
  width: '38px',
  [theme.breakpoints.down('md')]: {
    minHeight: '15px',
    width: '15px',
    height: '15px',
    fontSize: '9px',
  },
  backgroundColor: theme.xPalette.completed.color,
  color: theme.xPalette.white,
  border: `1px solid ${theme.xPalette.completed.color}`,
}));

const StyledIconCustom = styled(Box)(({ theme }) => ({
  ...commonStylesIcon,
  width: '38px',
  [theme.breakpoints.down('md')]: {
    minHeight: '15px',
    width: '15px',
    height: '15px',
    fontSize: '9px',
  },
  backgroundColor: theme.xPalette.background,
  color: theme.xPalette.shadeGrey,
  border: `1px solid ${theme.xPalette.shadeGrey}`,
}));

const sections = [
  { sectionId: 'section-one', component: SectionOne },
  { sectionId: 'section-two', component: SectionTwo },
  { sectionId: 'section-three', component: SectionThree },
];

interface subAnswersAnnTextType {
  [key: number]: { answers: string[]; texts: string[] };
}

export default function SdsScaleQuestion({
  question,
  field,
  error,
  helperText,
  setValue,
  questionId,
}: InterviewQuestionProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const theme = useTheme();
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [openSectionId, setOpenSectionId] = useState<string>('');
  const previousAnswer = field?.value;
  const defaultPossibleAnswersText = t('interview.form.sds', {
    returnObjects: true,
  });
  const isResetQuestion = useAppSelector<boolean>(selectResetQuestion);
  const dispatch = useAppDispatch();

  const isEditInterview = pathname?.includes('edit-interview');
  const interviewType = useAppSelector<string>(selectInterviewType);

  const isClinicianInterview =
    interviewType === InterviewMode.Clinician ||
    interviewType === InterviewMode.ExternalClinician ||
    isEditInterview;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sdsValue = event.target.value;
    setSelectedValue(sdsValue);
    if (sdsValue === selectedValue) {
      setSelectedValue('');
    } else {
      setSelectedValue(sdsValue);
    }
    handleClose();
    dispatch(setResetQuestion({ reset: false }));

    if (!isClinicianInterview) {
      const additionalMessage = document.getElementById(`additionalMessage-${questionId}`);
      if (additionalMessage) {
        if (additionalMessage.style.display === 'none' && sdsValue === PossibleAnswers.Na) {
          additionalMessage.style.display = 'inline';
        } else {
          additionalMessage.style.display = 'none';
        }
      }
    }
  };

  const controlProps = (item: string) => ({
    checked: selectedValue === item || (previousAnswer.toString() === item && !selectedValue),
    onChange: handleChange,
    value: item,
    name: 'sds-scale-radio-button',
    inputProps: { 'aria-label': item },
  });

  const renderAnswers = () => {
    const { possibleAnswers } = question;

    let skipAnswer: any = [];
    let selectAnswer: any = [];
    possibleAnswers.forEach((item) => {
      if (item !== PossibleAnswers.Na) {
        selectAnswer.push(
          <StyledFormSelected
            key={item}
            value={item}
            labelPlacement="start"
            control={
              <Checkbox
                sx={{ p: 0 }}
                checkedIcon={<StyledCheckIconCustom>{item}</StyledCheckIconCustom>}
                icon={<StyledIconCustom>{item}</StyledIconCustom>}
                {...controlProps(item)}
              />
            }
            label={
              <Hidden xlDown xlUp>
                {item}
              </Hidden>
            }
          />
        );
      } else {
        skipAnswer.push(
          <StyledFormLabel
            key={item}
            value={item}
            label={
              <Typography
                dangerouslySetInnerHTML={{
                  __html: question.questionSkipCheckboxText!,
                }}
              ></Typography>
            }
            control={<StyledCheckBox {...controlProps(item)} />}
          />
        );
      }
    });

    return {
      skipAnswer,
      selectAnswer,
    };
  };

  const handleOpenSections = (event: any) => {
    const idName = event.target.name;
    setAnchor(event.currentTarget);
    setOpenSectionId(idName);
  };

  const handleClose = () => {
    setAnchor(null);
    setOpenSectionId('');
  };

  const subAnswersAndText = (index: number) => {
    const possibleAnswers = question.possibleAnswers.filter((item) => item !== PossibleAnswers.Na);
    let answersText = question.possibleAnswersText;

    if (!answersText || answersText.includes(PossibleAnswers.Yes || PossibleAnswers.No)) {
      answersText = Object.values(defaultPossibleAnswersText);
    }

    const sectionPossibleAnswers: subAnswersAnnTextType = {
      1: { answers: possibleAnswers.slice(0, 4), texts: answersText.slice(0, 2) },
      2: { answers: possibleAnswers.slice(4, 7), texts: answersText.slice(2, 3) },
      3: { answers: possibleAnswers.slice(7), texts: answersText.slice(3) },
    };

    return sectionPossibleAnswers[index];
  };

  const renderPopUp = () => {
    return sections.map((item, index) => {
      return (
        <Box key={item.sectionId} sx={{ width: '33%', height: '80px' }}>
          <Button
            sx={{
              width: '100%',
              height: '100%',
              border: 'none',
              '&:hover': {
                backgroundColor: theme.xPalette.white,
                borderColor: theme.xPalette.white,
              },
            }}
            variant="outlined"
            onClick={handleOpenSections}
            name={item.sectionId}
          ></Button>
          <Popover
            open={openSectionId === item.sectionId}
            anchorEl={anchor}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            onClose={handleClose}
          >
            {
              <item.component
                possibleAnswersAndText={subAnswersAndText(index + 1)}
                controlProps={controlProps}
              />
            }
          </Popover>
        </Box>
      );
    });
  };

  const renderPossibleAnswersText = (possibleAnswersText: string[]) => {
    if (
      !possibleAnswersText ||
      possibleAnswersText.includes(PossibleAnswers.Yes || PossibleAnswers.No)
    ) {
      possibleAnswersText = Object.values(defaultPossibleAnswersText);
    }

    let customWidth = 10;
    return possibleAnswersText.map((title: string, index: number) => {
      if (index > 2) {
        customWidth -= 10;
      } else {
        customWidth += 10;
      }
      return (
        <Box key={title} sx={{ width: `${customWidth}%`, height: '40px', fontWeight: '500' }}>
          {title}
        </Box>
      );
    });
  };

  useEffect(() => {
    setValue(field?.name!, selectedValue);
    if (isResetQuestion) {
      setSelectedValue('');
    }
  }, [field?.name, selectedValue, setValue, isResetQuestion]);

  return (
    <Box sx={{ mt: 2 }}>
      {question.name && (
        <StyledTypography
          dangerouslySetInnerHTML={{
            __html: question.name,
          }}
        ></StyledTypography>
      )}
      {question.questionPrompt && (
        <StyledTypography
          dangerouslySetInnerHTML={{
            __html: question.questionPrompt,
          }}
        ></StyledTypography>
      )}
      <Typography
        dangerouslySetInnerHTML={{
          __html: question.questionText!,
        }}
      ></Typography>
      <StyledFormControl error={error} variant="standard">
        <RadioGroup row sx={{ width: '100%' }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '80px',
                position: 'absolute',
                zIndex: 0,
                opacity: 0,
                visibility: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                columnGap: 1,
                cursor: 'pointer',
                [theme.breakpoints.down('md')]: {
                  zIndex: 9,
                  visibility: 'visible',
                },
              }}
            >
              {renderPopUp()}
            </Box>
            <Box
              sx={{
                width: '100%',
                height: '40px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                position: 'relative',
                columnGap: '4%',
                textAlign: 'center',
                my: 2,
                [theme.breakpoints.down('md')]: {
                  fontSize: '11px',
                  height: '20px',
                  whiteSpace: 'nowrap',
                  my: 1,
                },
              }}
            >
              {renderPossibleAnswersText(question.possibleAnswersText || [])}
            </Box>
            <Box
              sx={{
                width: '90%',
                height: '40px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                position: 'absolute',
                bottom: '34px',
                [theme.breakpoints.down('md')]: {
                  height: '20px',
                  bottom: '24px',
                },
              }}
            >
              <Box
                sx={{
                  width: '9%',
                  height: '40px',
                  borderBottom: '1px solid black',
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    width: 0,
                    height: 0,
                    left: '14px',
                    bottom: '-3.5px',
                    borderTop: '4px solid transparent',
                    borderBottom: '4px solid transparent',
                    borderRight: `5px solid ${theme.xPalette.shadeGrey}`,
                  },
                  [theme.breakpoints.down('md')]: {
                    height: '20px',
                    '&:after': {
                      left: '5px',
                    },
                  },
                }}
              ></Box>
              <Box
                sx={{
                  width: '10%',
                  height: '40px',
                  border: `1px solid ${theme.xPalette.black}`,
                  borderRight: 0,
                  [theme.breakpoints.down('md')]: {
                    height: '20px',
                  },
                }}
              ></Box>
              <Box
                sx={{
                  width: '10%',
                  height: '40px',
                  border: `1px solid ${theme.xPalette.black}`,
                  borderRight: 0,
                  [theme.breakpoints.down('md')]: {
                    height: '20px',
                  },
                }}
              ></Box>
              <Box
                sx={{
                  width: '10%',
                  height: '40px',
                  border: `1px solid ${theme.xPalette.black}`,
                  borderTop: 0,
                  [theme.breakpoints.down('md')]: {
                    height: '20px',
                  },
                }}
              ></Box>
              <Box
                sx={{
                  width: '10%',
                  height: '40px',
                  border: `1px solid ${theme.xPalette.black}`,
                  borderLeft: 0,
                  [theme.breakpoints.down('md')]: {
                    height: '20px',
                  },
                }}
              ></Box>
              <Box
                sx={{
                  width: '10%',
                  height: '40px',
                  border: `1px solid ${theme.xPalette.black}`,
                  borderRight: 0,
                  borderLeft: 0,
                  [theme.breakpoints.down('md')]: {
                    height: '20px',
                  },
                }}
              ></Box>
              <Box
                sx={{
                  width: '10%',
                  height: '40px',
                  border: '1px solid black',
                  borderTop: 0,
                  [theme.breakpoints.down('md')]: {
                    height: '20px',
                  },
                }}
              ></Box>
              <Box
                sx={{
                  width: '10%',
                  height: '40px',
                  border: '1px solid black',
                  borderLeft: 0,
                  [theme.breakpoints.down('md')]: {
                    height: '20px',
                  },
                }}
              ></Box>
              <Box
                sx={{
                  width: '10%',
                  height: '40px',
                  border: '1px solid black',
                  borderLeft: 0,
                  [theme.breakpoints.down('md')]: {
                    height: '20px',
                  },
                }}
              ></Box>
              <Box
                sx={{
                  width: '9%',
                  height: '40px',
                  borderBottom: `1px solid ${theme.xPalette.black}`,
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    width: 0,
                    height: 0,
                    right: '14px',
                    bottom: '-3.5px',
                    borderTop: '4px solid transparent',
                    borderBottom: '4px solid transparent',
                    borderLeft: `5px solid ${theme.xPalette.shadeGrey}`,
                  },
                  [theme.breakpoints.down('md')]: {
                    height: '20px',
                    '&:after': {
                      right: '5px',
                    },
                  },
                }}
              ></Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
                my: 2,
                flexWrap: 'wrap',
              }}
            >
              {renderAnswers().selectAnswer}
            </Box>
          </Box>
          {question.questionCanBeSkippedWithOption && renderAnswers().skipAnswer}
        </RadioGroup>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </StyledFormControl>
    </Box>
  );
}
