import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { AuthType, AuthUserType, MfaInfoType } from '../../types/AuthTypes';

const initialState: AuthType = {
  isAuthenticated: false,
  isShowMfa: false,
  user: {} as AuthUserType,
  mfa: {} as MfaInfoType,
  isFromIframe: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initialCredentials: (
      state,
      {
        payload: {
          user,
          token,
          refreshToken,
          isShowMfa,
          remainingTime,
          isAuthenticated,
          lastLoginAt,
        },
      }: PayloadAction<{
        user: AuthUserType;
        token: string;
        refreshToken: string;
        isShowMfa: boolean;
        remainingTime: number;
        lastLoginAt: number;
        isAuthenticated: boolean;
      }>
    ) => {
      state.user = user;
      state.token = token;
      state.refreshToken = refreshToken;
      state.isAuthenticated = isAuthenticated;
      state.isShowMfa = isShowMfa;
      state.lastLoginAt = lastLoginAt;
      state.mfa.mfaType = user.mfaType;
      state.mfa.mfaVerified = user.mfaVerified;
      state.mfa.phoneNumber = user.phoneNumber;
      state.mfa.email = user.email;
      state.mfa.remainingTime = remainingTime;
    },
    setCredentials: (
      state,
      {
        payload: { user, accessToken, refreshToken, isShowMfa, remainingTime },
      }: PayloadAction<{
        user: AuthUserType;
        accessToken: string;
        refreshToken: string;
        isShowMfa?: boolean;
        remainingTime?: number;
      }>
    ) => {
      state.user = user;
      state.token = accessToken;
      state.refreshToken = refreshToken;
      state.isAuthenticated = true;
      state.isShowMfa = isShowMfa;
      state.lastLoginAt = Date.now();
      state.mfa.mfaType = user.mfaType;
      state.mfa.mfaVerified = user.mfaVerified;
      state.mfa.phoneNumber = user.phoneNumber;
      state.mfa.email = user.email;
      state.mfa.remainingTime = remainingTime;
    },
    clearAuthState: (state) => {
      state.user = {} as AuthUserType;
      state.mfa = {} as MfaInfoType;
      state.token = '';
      state.refreshToken = '';
      state.isAuthenticated = false;
      state.isShowMfa = false;
    },
    clearCredentials: (state) => {
      state.user = {} as AuthUserType;
      state.mfa = {} as MfaInfoType;
      state.token = '';
      state.refreshToken = '';
      state.isAuthenticated = false;
      state.isShowMfa = false;
    },
    rotateToken: (state, { payload: { accessToken } }: PayloadAction<{ accessToken: string }>) => {
      state.token = accessToken;
      state.lastLoginAt = Date.now();
    },
    verifiedMfa: (state) => {
      state.isShowMfa = false;
      state.user.mfaVerified = true;
      state.mfa.mfaVerified = true;
    },
    unVerifiedMfa: (state) => {
      state.isShowMfa = true;
      state.user.mfaVerified = false;
      state.mfa.mfaVerified = false;
    },
    setMfaInfo: (
      state,
      {
        payload: {
          mfaType,
          mfaVerified,
          isTokenLimitExceeded,
          userLockedOut,
          isOtpSent,
          phoneNumber,
          email,
          remainingTime,
        },
      }: PayloadAction<{
        mfaType?: number;
        mfaVerified?: boolean;
        isTokenLimitExceeded?: boolean;
        userLockedOut?: boolean;
        isOtpSent?: boolean;
        phoneNumber?: string;
        email?: string;
        remainingTime?: number;
      }>
    ) => {
      state.mfa.mfaType = mfaType;
      state.mfa.mfaVerified = mfaVerified;
      state.mfa.isTokenLimitExceeded = isTokenLimitExceeded;
      state.mfa.userLockedOut = userLockedOut;
      state.mfa.isOtpSent = isOtpSent;
      state.mfa.phoneNumber = phoneNumber;
      state.mfa.email = email;
      state.mfa.remainingTime = remainingTime;
    },
    setIsFromIframe: (state) => {
      state.isFromIframe = true;
    },
    setMfaUserLockedOut: (state) => {
      state.mfa.userLockedOut = true;
    },
    setGetCode: (state) => {
      state.mfa.isOtpSent = true;
    },
    setRemainingTime: (
      state,
      { payload: { remainingTime } }: PayloadAction<{ remainingTime?: number }>
    ) => {
      state.mfa.remainingTime = remainingTime;
    },
  },
});

export const {
  setCredentials,
  clearCredentials,
  rotateToken,
  verifiedMfa,
  unVerifiedMfa,
  setMfaInfo,
  setMfaUserLockedOut,
  setGetCode,
  setRemainingTime,
  initialCredentials,
  clearAuthState,
  setIsFromIframe,
} = authSlice.actions;

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectIsShowMfa = (state: RootState) => state.auth.isShowMfa;
export const selectMfaVerified = (state: RootState) => state.auth.mfa?.mfaVerified;
export const selectUser = (state: RootState) => state.auth.user;
export const selectMfa = (state: RootState) => state.auth.mfa;
export const selectIsFromIframe = (state: RootState): boolean => state.auth.isFromIframe;

export const selectTokenInfo = (state: RootState) => ({
  accessToken: state.auth.token,
  refreshToken: state.auth.refreshToken,
});
export default authSlice.reducer;
