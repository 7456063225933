import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../app/hooks';
import {
  useGetClinicianNotesAuthTokenQuery,
  useGetClinicianNotesQuery,
  useSubmitClinicianNotesAuthTokenMutation,
  useSubmitClinicianNotesMutation,
} from '../../app/services/interview';
import { useToast } from '../../app/toast';
import RoundedContainer from '../../common/components/RoundedContainer';
import { ClinicianNoteVersionsType } from '../../types/InterviewQuestionTypes';
import { ResponseError } from '../../types/ResponseError';
import {
  ClinicianNotesDto,
  ClinicianNotesSubmissionRequestDto,
} from '../../types/clinician-notes/ClinicianNotes';
import { selectIsFromIframe } from '../auth/authSlice';
import { selectPatientInterviewData } from '../interview/interviewSlice';

interface ClinicianNotesFormProps {
  interviewTestId: number;
  questionIds?: number[];
  onFormSubmitCallback: (result: boolean) => void;
}

interface ClinicianNotesFormData {
  note: string;
}

export default function ClinicianNotesForm({
  interviewTestId,
  questionIds,
  onFormSubmitCallback,
}: ClinicianNotesFormProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const toast = useToast();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [previousClinicianNoteVersions, setPreviousClinicianNoteVersions] = useState<
    ClinicianNoteVersionsType[]
  >([]);
  const patientInterviewData = useAppSelector(selectPatientInterviewData);
  const isFromIframe = useAppSelector(selectIsFromIframe);
  const [submitClinicianNotes] = useSubmitClinicianNotesMutation();
  const [submitClinicianNotesAuthToken] = useSubmitClinicianNotesAuthTokenMutation();

  const { data: clinicianNotesData } = useGetClinicianNotesQuery(
    !isFromIframe && interviewTestId
      ? {
          interviewTestId,
          questionIds,
        }
      : skipToken
  );

  const { data: clinicianNotesDataAuthToken } = useGetClinicianNotesAuthTokenQuery(
    isFromIframe && interviewTestId && patientInterviewData?.accessToken
      ? {
          accessToken: patientInterviewData.accessToken,
          interviewTestId,
          questionIds,
        }
      : skipToken
  );

  const notesData = clinicianNotesData || clinicianNotesDataAuthToken;

  const defaultFormValues = {
    note: '',
  };

  const validationSchema = yup.object({
    note: yup.string(),
  });

  const { control, handleSubmit, setValue } = useForm<ClinicianNotesFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  // initial data load
  useEffect(() => {
    if (notesData?.interviewTestId) {
      if (!isDataLoaded) {
        setIsDataLoaded(true);
        const note = notesData.previousClinicianNotes && notesData.previousClinicianNotes[0];
        setValue('note', note?.note || '');
        setPreviousClinicianNoteVersions(notesData.previousClinicianNoteVersions || []);
      }
    }
  }, [notesData, isDataLoaded, setValue]);

  const onSubmit = async (clinicianNotesFormData: ClinicianNotesFormData) => {
    setDisableSubmit(true);
    try {
      let submitData: ClinicianNotesSubmissionRequestDto;
      const notes = questionIds?.map((questionId) => {
        return {
          questionId,
          clinicianNotes: clinicianNotesFormData.note,
        } as ClinicianNotesDto;
      });
      submitData = { interviewTestId, ...clinicianNotesFormData, notes };

      if (isFromIframe) {
        await submitClinicianNotesAuthToken({
          ...submitData,
          accessToken: patientInterviewData?.accessToken,
        }).unwrap();
      } else {
        await submitClinicianNotes(submitData).unwrap();
      }

      onFormSubmitCallback(true);

      setPageErrors([]);

      toast.publish(t('interview.form.saveNote'), 'success');
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
    setDisableSubmit(false);
  };

  const submitForm = async () => {
    handleSubmit(onSubmit)();
  };

  return (
    <Box
      component="form"
      noValidate
      sx={{
        px: 0,
        pt: 0,
      }}
    >
      {previousClinicianNoteVersions && previousClinicianNoteVersions.length !== 0 && (
        <RoundedContainer sx={{ py: 2, mb: 2 }}>
          <Typography mb={1} fontWeight="bold">
            {t('interview.clinicianNoteVersions')}
          </Typography>
          {previousClinicianNoteVersions.map((noteInfo: ClinicianNoteVersionsType) => (
            <Box
              key={noteInfo.version}
              sx={{
                p: 2,
                mb: 2,
                backgroundColor: theme.xPalette.background,
                borderRadius: 2,
              }}
            >
              {`Version ${noteInfo.version} - ${
                noteInfo.interviewerName
                  ? noteInfo.interviewerName
                  : `${noteInfo.lastName}, ${noteInfo.firstName}`
              } wrote:`}
              <br />
              <Typography sx={{ ml: 2, mt: 2 }}>{noteInfo.noteContent}</Typography>
            </Box>
          ))}
        </RoundedContainer>
      )}
      <RoundedContainer sx={{ paddingTop: 1 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Controller
            name="note"
            control={control}
            render={({ field }) => (
              <TextField
                variant="outlined"
                {...field}
                sx={{
                  width: '100%',
                }}
                multiline
                maxRows={4}
                label={t('interview.form.note')}
              />
            )}
          />
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
            pt: 3,
          }}
        >
          {!isMobile && <Divider sx={{ width: '100%' }} />}

          {pageErrors?.length > 0 &&
            pageErrors.map((error) => (
              <Alert key={error} severity="error" sx={{ mt: 2, width: '100%' }}>
                {error}
              </Alert>
            ))}
          <Button
            onClick={submitForm}
            disabled={disableSubmit}
            fullWidth
            variant="contained"
            sx={{
              width: isMobile ? '100%' : '35%',
              my: 2,
              py: 2,
              float: 'right',
            }}
          >
            {t('interview.form.submit')}
          </Button>
        </Box>
      </RoundedContainer>
    </Box>
  );
}
