import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useGetClinicAccountsQuery } from '../../app/services/account';
import { interviewApis } from '../../app/services/interview';
import Loading from '../../common/components/Loading';
import { DefaultPageSize } from '../../common/utils/commonUtils';
import { Role } from '../../common/utils/loginRole';
import { canViewBillingReport } from '../../common/utils/permissionUtils';
import { masterReportTypes } from '../../common/utils/reportTypes';
import { ResponseError } from '../../types/ResponseError';
import { UsageReportSearch } from '../../types/UsageReport';
import { selectUser } from '../auth/authSlice';

let defaultFormValues: UsageReportSearch = {
  clinicAccountId: '',
  reportType: '',
  fromDate: undefined,
  toDate: undefined,
};

const StyleAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: '49%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const StyleDesktopDatePicker = styled(DesktopDatePicker)(({ theme }) => ({
  width: '49%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const validationDate = ({ fromDate, toDate }: { fromDate?: Date; toDate?: Date }) => {
  if ((fromDate && !toDate) || (!fromDate && toDate)) {
    return true;
  }
  if (fromDate && toDate) {
    const fromDateFormat = new Date(new Date(fromDate).toISOString().split('T')[0]);
    const toDateFormat = new Date(new Date(toDate).toISOString().split('T')[0]);
    if (fromDateFormat.getTime() > toDateFormat.getTime()) {
      return false;
    }
  }
  return true;
};

export default function BillingUsageReportForm() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams] = useSearchParams();
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const currentUser = useAppSelector(selectUser);
  const currentDate = dayjs(new Date()).format('MM/DD/YYYY');
  const loginUserRoleId = currentUser.roleId;
  const isNotSuperAdmin =
    loginUserRoleId === Role.ClinicAdmin ||
    loginUserRoleId === Role.MultiUserProvider ||
    loginUserRoleId === Role.SingleUserProvider;

  const isValidInputDate = (inputDate: string) => dayjs(inputDate, 'MM/DD/YYYY').isValid();

  const searchOptions = {
    clinicAccountId:
      searchParams.get('clinicAccountId') || (isNotSuperAdmin ? currentUser.clinicAccountId : ''),
    reportType: searchParams.get('reportType') || '',
    fromDate: searchParams.get('fromDate') || currentDate,
    toDate: searchParams.get('toDate') || currentDate,
    page: searchParams.get('page') || '1',
  };

  defaultFormValues = {
    clinicAccountId: searchOptions.clinicAccountId,
    reportType: searchOptions.reportType,
    fromDate: isValidInputDate(searchOptions.fromDate)
      ? new Date(searchOptions.fromDate)
      : undefined,
    toDate: isValidInputDate(searchOptions.fromDate) ? new Date(searchOptions.toDate) : undefined,
  };
  const [fromDate, setFromDate] = useState<string>(
    isValidInputDate(searchOptions.fromDate) ? searchOptions.fromDate : ''
  );
  const [toDate, setToDate] = useState<string>(
    isValidInputDate(searchOptions.toDate) ? searchOptions.toDate : ''
  );
  const [fromDateError, setFromDateError] = useState<string>('');
  const [toDateError, setToDateError] = useState<string>('');
  const canViewReport = canViewBillingReport(currentUser.roleId);
  const dispatch = useAppDispatch();
  const { data: clinicAccountData, isLoading } = useGetClinicAccountsQuery(undefined, {
    skip: !canViewReport,
  });

  const validationSchema = yup
    .object({
      clinicAccountId: yup
        .string()
        .trim()
        .test('validate-account', t('report.error.blankAccount'), function (clinicAccountId) {
          return clinicAccountId !== '';
        }),
      reportType: yup.string().required(t('report.error.blankReportType')),
      fromDate: yup
        .date()
        .required(t('report.error.blankFromDate'))
        .typeError(t('report.error.blankFromDate'))
        .test('from-date', '', function (fromDateValue) {
          const toDateValue = getValues('toDate') || undefined;
          const isValidDate = isValidInputDate(fromDate);
          if (!isValidDate && fromDateValue) {
            return false;
          }
          if (fromDateValue) {
            const isValidDate = validationDate({
              fromDate: fromDateValue,
              toDate: toDateValue,
            });

            if (!isValidDate) {
              setFromDateError(t('report.error.inValidDateRange'));
              return false;
            }
          }
          setFromDateError('');
          return true;
        })
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr)),

      toDate: yup
        .date()
        .required(t('report.error.blankToDate'))
        .typeError(t('report.error.blankToDate'))
        .test('from-date', '', function (toDateValue) {
          const fromDateValue = getValues('fromDate') || undefined;
          const isValidDate = isValidInputDate(toDate);
          if (!isValidDate && toDateValue) {
            return false;
          }
          if (toDateValue) {
            const isValidDate = validationDate({
              fromDate: fromDateValue,
              toDate: toDateValue,
            });

            if (!isValidDate) {
              setToDateError(t('report.error.inValidToDateRange'));
              return false;
            }
          }
          setToDateError('');
          return true;
        })
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr)),
    })
    .required();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm<UsageReportSearch>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const handleAccountChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('clinicAccountId', selectedValue);
  };

  const handleReportTypeChange = (e: any, values: any) => {
    const selectedValue = values ? values.id : '';
    setValue('reportType', selectedValue);
  };

  const getAccountOptionLabel = (option: any) => `${option.name} (${option.id})`;
  const getReportTypeOptionLabel = (option: any) => `${option.name}`;

  const onSubmit = async (data: UsageReportSearch) => {
    try {
      const billingUsageReport = dispatch(
        interviewApis.queryBillingUsageReport.initiate({
          clinicAccountId: data.clinicAccountId,
          reportType: data.reportType,
          fromDate,
          toDate,
          page: 1,
          pageSize: DefaultPageSize,
          sort: 'end desc',
        })
      );
      billingUsageReport.then((result) => {
        if (typeof result !== 'undefined') {
          if (result.data?.assessments.length !== 0) {
            const params = `?clinicAccountId=${data.clinicAccountId}&reportType=${data.reportType}&fromDate=${fromDate}&toDate=${toDate}&sort=end&dir=desc`;
            const updatedUrl = `/dashboard/billing-usage-report/output${params}`;
            navigate(updatedUrl, { state: { reportData: result.data || null } });
          } else {
            setPageErrors([t('report.error.noDataFound')]);
          }
        }
      });
    } catch (e) {
      const {
        data: { error },
      } = e as ResponseError;
      setPageErrors(error);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        px: matches ? 0 : 3,
        py: 2,
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            {currentUser.roleId === Role.SuperAdmin && (
              <Controller
                name="clinicAccountId"
                control={control}
                render={({ fieldState, formState, field: { value }, ...props }) => (
                  <StyleAutocomplete
                    {...props}
                    data-testid="mui-component-select-clinic-account"
                    options={clinicAccountData?.clinicAccounts || []}
                    getOptionLabel={getAccountOptionLabel}
                    onChange={handleAccountChange}
                    value={
                      value
                        ? clinicAccountData?.clinicAccounts?.find((item) => item.id === value) || []
                        : null
                    }
                    renderOption={(props, option: any) => {
                      return (
                        <li {...props} key={option.id} value={option.id}>
                          {getAccountOptionLabel(option)}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors.clinicAccountId}
                        helperText={errors.clinicAccountId?.message}
                        margin="normal"
                        fullWidth
                        label={t('report.usageReport.account')}
                      />
                    )}
                  />
                )}
              />
            )}
            <Controller
              name="reportType"
              control={control}
              render={({ fieldState, formState, field: { value }, ...props }) => (
                <StyleAutocomplete
                  {...props}
                  data-testid="mui-component-select-report-type"
                  options={masterReportTypes || []}
                  getOptionLabel={getReportTypeOptionLabel}
                  onChange={handleReportTypeChange}
                  value={value ? masterReportTypes.find((item) => item.id === value) || [] : null}
                  renderOption={(props, option: any) => {
                    return (
                      <li {...props} key={option.id} value={option.id}>
                        {getReportTypeOptionLabel(option)}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.reportType}
                      helperText={errors.reportType?.message}
                      margin="normal"
                      fullWidth
                      label={t('report.usageReport.reportType')}
                    />
                  )}
                  sx={{
                    width: currentUser?.roleId === Role.SuperAdmin ? '49%' : '100%',
                    [theme.breakpoints.down('md')]: {
                      width: '100%',
                    },
                  }}
                />
              )}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '49%',
                  [theme.breakpoints.down('md')]: {
                    width: '100%',
                  },
                }}
              >
                <Controller
                  name="fromDate"
                  control={control}
                  render={({ field }) => (
                    <StyleDesktopDatePicker
                      {...field}
                      label="From"
                      inputFormat="MM/DD/YYYY"
                      value={fromDate}
                      onChange={(newDateValue: any, inputValue: any) => {
                        if (newDateValue) {
                          const isValidDate = dayjs(newDateValue).isValid();
                          if (!isValidDate) {
                            setFromDate(inputValue);
                            setFromDateError(t('report.error.inValidFromDate'));
                          } else {
                            const dateString = dayjs(newDateValue).format('MM/DD/YYYY');
                            setValue('fromDate', new Date(dateString));
                            setFromDate(dateString);
                            setFromDateError('');
                          }
                        } else {
                          setValue('fromDate', newDateValue);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          data-testid="mui-component-from-date"
                          sx={{
                            width: '100%',
                          }}
                          error={!!errors.fromDate || !!fromDateError}
                          helperText={errors.fromDate?.message}
                          margin="normal"
                          variant="outlined"
                          aria-describedby="from-date-helper-text"
                        />
                      )}
                    />
                  )}
                />
                <FormHelperText id="from-date-helper-text" error={true}>
                  {fromDateError}
                </FormHelperText>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '49%',
                  [theme.breakpoints.down('md')]: {
                    width: '100%',
                  },
                }}
              >
                <Controller
                  name="toDate"
                  control={control}
                  render={({ field }) => (
                    <StyleDesktopDatePicker
                      {...field}
                      label="To"
                      inputFormat="MM/DD/YYYY"
                      value={toDate}
                      onChange={(newDateValue: any, inputValue: any) => {
                        if (newDateValue) {
                          const isValidDate = dayjs(newDateValue).isValid();
                          if (!isValidDate) {
                            setToDate(inputValue);
                            setToDateError(t('report.error.inValidToDate'));
                          } else {
                            const dateString = dayjs(newDateValue).format('MM/DD/YYYY');
                            setValue('toDate', new Date(dateString));
                            setToDate(dateString);
                            setToDateError('');
                          }
                        } else {
                          setValue('toDate', newDateValue);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          data-testid="mui-component-to-date"
                          sx={{
                            width: '100%',
                          }}
                          error={!!errors.toDate || !!toDateError}
                          helperText={errors.toDate?.message}
                          margin="normal"
                          variant="outlined"
                          aria-describedby="to-date-helper-text"
                        />
                      )}
                    />
                  )}
                />
                <FormHelperText id="to-date-helper-text" error={true}>
                  {toDateError}
                </FormHelperText>
              </Box>
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              width: '100%',
              float: 'right',
              pb: 3,
            }}
          >
            {!matches && <Divider sx={{ py: 1 }} />}

            {pageErrors?.length > 0 &&
              pageErrors.map((error) => (
                <Alert key={error} severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              ))}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                width: matches ? '100%' : '24%',
                my: 2,
                py: 2,
                float: 'right',
              }}
            >
              {t('report.usageReport.submit')}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
