import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../../app/hooks';
import RoundedContainer from '../../../common/components/RoundedContainer';
import { NextStepType } from '../../../common/utils/assessmentType';
import { intervals } from '../../../common/utils/intervalResource';
import { Role } from '../../../common/utils/loginRole';
import {
  InterviewTypeResponse,
  MonitorTypeResponse,
  NextStepActionsResponse,
} from '../../../types/ClinicalFollowUps';
import { selectUser } from '../../auth/authSlice';

const NextStepTypes = [
  {
    id: 1,
    name: 'Interview',
  },
  {
    id: 2,
    name: 'Monitor',
  },
  {
    id: 3,
    name: 'External',
  },
];

interface ClinicalFollowUpNextStepsProps {
  nextStepActionsData?: NextStepActionsResponse;
  automaticStates: any;
  fields: any[];
  errors: any;
  control: any;
  setValue: any;
  getValues: any;
  clearErrors: any;
}

const StyleTextField = styled(TextField)(() => ({
  width: '100%',
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.MuiCheckbox-root': {
    width: '45px',
    height: '45px',
  },
  '&.Mui-checked': {
    color: theme.palette.secondary.main,
  },
  '&.MuiCheckbox-indeterminate': {
    color: theme.palette.secondary.main,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 30,
  },
}));

const StyledMenuItem = styled(MenuItem)(() => ({
  maxWidth: 440,
  '&.MuiMenuItem-root': {
    whiteSpace: 'unset',
    lineBreak: 'strict',
    display: 'flex',
    alignItems: 'flex-start',
  },
}));

export default function ClinicalFollowUpsNextStepsForm({
  nextStepActionsData,
  automaticStates,
  fields,
  errors,
  control,
  setValue,
  getValues,
  clearErrors,
}: ClinicalFollowUpNextStepsProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const currentUser = useAppSelector(selectUser);

  const [isAutomateFollowUpDisabled, setAutomateFollowUpDisabled] = useState({});
  const [isStartAfterDaysDisabled, setStartAfterDaysDisabled] = useState({});
  const [isMaxSendTimesDisabled, setMaxSendTimesDisabled] = useState({});
  const [isDisplayedIntervalOptions, setDisplayedIntervalOptions] = useState({});

  const checkEnableSchedule = (
    index: number,
    nextStepActionId = null,
    itemType = null
  ): boolean => {
    nextStepActionId =
      nextStepActionId || getValues(`clinicalFollowUpNextStep.${index}.nextStepAction`);
    if (!nextStepActionId) return false;

    itemType = itemType || getValues(`clinicalFollowUpNextStep.${index}.type`);

    if (itemType === NextStepType.Interview) {
      const currentNextStepAction = nextStepActionsData?.accountInterviewTypes?.find(
        (interview: any) => interview?.id?.toString() === nextStepActionId
      );
      return !!currentNextStepAction?.enableSchedule;
    } else if (itemType === NextStepType.Monitor) {
      return true;
    } else if (itemType === NextStepType.External) {
      return false;
    }
    return false;
  };

  const showOrHideIntervalOptions = (index: number, nextStepId: number) => {
    if (
      getValues(`clinicalFollowUpNextStep.${index}.automateFollowup`) &&
      checkEnableSchedule(index)
    ) {
      setDisplayedIntervalOptions({
        ...isDisplayedIntervalOptions,
        [nextStepId]: true,
      });
      const interval = getValues(`clinicalFollowUpNextStep.${index}.interval`) || 'Once';
      const startAfterDays = getValues(`clinicalFollowUpNextStep.${index}.startAfterDays`) || 0;
      const maxSendTimes = getValues(`clinicalFollowUpNextStep.${index}.maxSendTimes`) || 0;
      setValue(`clinicalFollowUpNextStep.${index}.interval`, interval);
      setValue(`clinicalFollowUpNextStep.${index}.startAfterDays`, startAfterDays);
      setValue(`clinicalFollowUpNextStep.${index}.maxSendTimes`, maxSendTimes);
    } else {
      setDisplayedIntervalOptions({
        ...isDisplayedIntervalOptions,
        [nextStepId]: false,
      });
      setValue(`clinicalFollowUpNextStep.${index}.interval`, '');
      setValue(`clinicalFollowUpNextStep.${index}.startAfterDays`, 0);
      setValue(`clinicalFollowUpNextStep.${index}.maxSendTimes`, 0);
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => option.name,
  });

  const renderIntervals = () => {
    return intervals.map((interval) => (
      <StyledMenuItem key={interval} value={interval}>
        {interval}
      </StyledMenuItem>
    ));
  };

  const handleApplyNextStepChange = (currentValue: boolean, index: number, nextStepId: number) => {
    setValue(`clinicalFollowUpNextStep.${index}.applyNextStep`, !!currentValue);
    if (!currentValue) {
      setValue(`clinicalFollowUpNextStep.${index}.automateFollowup`, false);
    }
    setAutomateFollowUpDisabled({
      ...isAutomateFollowUpDisabled,
      [nextStepId]: !currentValue,
    });
    handleAutomateFolloUpChange(
      getValues(`clinicalFollowUpNextStep.${index}.automateFollowup`),
      index,
      nextStepId
    );
  };

  const handleAutomateFolloUpChange = (
    currentValue: boolean,
    index: number,
    nextStepId: number
  ) => {
    setValue(`clinicalFollowUpNextStep.${index}.automateFollowup`, !!currentValue);
    showOrHideIntervalOptions(index, nextStepId);
  };

  const handleIntervalChange = (currentValue: string, index: number, nextStepId: number) => {
    setValue(`clinicalFollowUpNextStep.${index}.interval`, currentValue || '');
    if (currentValue !== 'Once') {
      setStartAfterDaysDisabled({
        ...isStartAfterDaysDisabled,
        [nextStepId]: false,
      });
      setMaxSendTimesDisabled({
        ...isMaxSendTimesDisabled,
        [nextStepId]: false,
      });
    } else {
      setStartAfterDaysDisabled({
        ...isStartAfterDaysDisabled,
        [nextStepId]: true,
      });
      setValue(`clinicalFollowUpNextStep.${index}.startAfterDays`, 0);
      setMaxSendTimesDisabled({
        ...isMaxSendTimesDisabled,
        [nextStepId]: true,
      });
      setValue(`clinicalFollowUpNextStep.${index}.maxSendTimes`, 0);
      clearErrors(`clinicalFollowUpNextStep.${index}.startAfterDays`);
      clearErrors(`clinicalFollowUpNextStep.${index}.maxSendTimes`);
    }
  };

  const isSuperAdmin = () => currentUser?.roleId === Role.SuperAdmin;

  const automateFollowUpDisabled = (nextStepId: number) => {
    if (isAutomateFollowUpDisabled[nextStepId] !== undefined)
      return !!isAutomateFollowUpDisabled[nextStepId];
    return !!automaticStates.automateFollowUpDisabled[nextStepId];
  };

  const startAfterDaysDisabled = (nextStepId: number) => {
    if (isStartAfterDaysDisabled[nextStepId] !== undefined)
      return !!isStartAfterDaysDisabled[nextStepId];
    return !!automaticStates.startAfterDaysDisabled[nextStepId];
  };

  const maxSendTimesDisabled = (nextStepId: number) => {
    if (isMaxSendTimesDisabled[nextStepId] !== undefined)
      return !!isMaxSendTimesDisabled[nextStepId];
    return !!automaticStates.maxSendTimesDisabled[nextStepId];
  };

  const showInterval = (nextStepId: number) => {
    if (isDisplayedIntervalOptions[nextStepId] !== undefined)
      return !!isDisplayedIntervalOptions[nextStepId];
    return !!automaticStates.displayedIntervalOptions[nextStepId];
  };

  return (
    <>
      {fields &&
        fields.map((item, index) => (
          <RoundedContainer key={index} sx={{ paddingY: 2, marginBottom: 2 }}>
            <Typography sx={{ fontWeight: 'bold', marginBottom: 1, paddingLeft: 0, paddingTop: 1 }}>
              {item.nextStepName}
            </Typography>
            <Box sx={{ display: 'flex', width: '100%', gap: 3 }}>
              <Controller
                name={`clinicalFollowUpNextStep.${index}.applyNextStep`}
                control={control}
                render={({ field }) => (
                  <FormGroup {...field}>
                    <FormControlLabel
                      control={<StyledCheckbox />}
                      checked={!!field.value}
                      value={!!field.value}
                      disabled={!isSuperAdmin()}
                      onChange={(event: any) => {
                        handleApplyNextStepChange(event.target.checked, index, item.nextStepId);
                      }}
                      label={`${t(
                        'account.manageAccounts.accountForm.clinicalFollowUps.form.applyThisNextStep'
                      )}`}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                name={`clinicalFollowUpNextStep.${index}.automateFollowup`}
                control={control}
                render={({ field }) => (
                  <FormGroup {...field}>
                    <FormControlLabel
                      control={<StyledCheckbox />}
                      checked={!!field.value}
                      value={!!field.value}
                      disabled={!isSuperAdmin() || automateFollowUpDisabled(item.nextStepId)}
                      onChange={(event: any) => {
                        handleAutomateFolloUpChange(event.target.checked, index, item.nextStepId);
                      }}
                      label={`${t(
                        'account.manageAccounts.accountForm.clinicalFollowUps.form.automateFollowup'
                      )}`}
                    />
                  </FormGroup>
                )}
              />
            </Box>
            <Controller
              name={`clinicalFollowUpNextStep.${index}.reportText`}
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  disabled={!isSuperAdmin()}
                  error={!!errors.clinicalFollowUpNextStep?.[index]?.reportText}
                  helperText={errors.clinicalFollowUpNextStep?.[index]?.reportText?.message}
                  margin="normal"
                  fullWidth
                  label={t('account.manageAccounts.accountForm.clinicalFollowUps.form.reportText')}
                  variant="outlined"
                />
              )}
            />
            <Controller
              name={`clinicalFollowUpNextStep.${index}.nextStepText`}
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  disabled={!isSuperAdmin()}
                  error={!!errors.clinicalFollowUpNextStep?.[index]?.nextStepText}
                  helperText={errors.clinicalFollowUpNextStep?.[index]?.nextStepText?.message}
                  margin="normal"
                  fullWidth
                  label={t(
                    'account.manageAccounts.accountForm.clinicalFollowUps.form.nextStepText'
                  )}
                  variant="outlined"
                />
              )}
            />
            <Controller
              name={`clinicalFollowUpNextStep.${index}.followUpText`}
              control={control}
              render={({ field }) => (
                <StyleTextField
                  {...field}
                  disabled={!isSuperAdmin()}
                  error={!!errors.clinicalFollowUpNextStep?.[index]?.followUpText}
                  helperText={errors.clinicalFollowUpNextStep?.[index]?.followUpText?.message}
                  margin="normal"
                  fullWidth
                  label={t(
                    'account.manageAccounts.accountForm.clinicalFollowUps.form.followUpText'
                  )}
                  variant="outlined"
                />
              )}
            />
            <Controller
              name={`clinicalFollowUpNextStep.${index}.type`}
              control={control}
              render={({ fieldState, formState, field: { value }, ...props }) => (
                <Autocomplete
                  {...props}
                  sx={{
                    width: '100%',
                  }}
                  disabled
                  data-testid="mui-component-select-next-step-type"
                  options={NextStepTypes || []}
                  getOptionLabel={(option: any) => (option.name ? option.name : '')}
                  value={value ? NextStepTypes?.find((item) => item.id === value) : null}
                  renderOption={(props, option: any) => {
                    return (
                      <li {...props} key={option.id} value={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      fullWidth
                      label={t('account.manageAccounts.accountForm.clinicalFollowUps.form.type')}
                    />
                  )}
                />
              )}
            />
            {item.type === NextStepType.Interview && (
              <Controller
                name={`clinicalFollowUpNextStep.${index}.nextStepAction`}
                control={control}
                render={({ fieldState, formState, field: { value }, ...props }) => (
                  <Autocomplete
                    {...props}
                    sx={{
                      width: '100%',
                    }}
                    disabled={!isSuperAdmin()}
                    data-testid="mui-component-select-next-step-action-interview"
                    options={nextStepActionsData?.accountInterviewTypes || []}
                    getOptionLabel={(option: any) => (option.name ? option.name : '')}
                    onChange={(_, values: any) => {
                      const selectedValue = values ? values.id.toString() : '';
                      setValue(`clinicalFollowUpNextStep.${index}.nextStepAction`, selectedValue);
                      showOrHideIntervalOptions(index, item.nextStepId);
                    }}
                    value={
                      value
                        ? nextStepActionsData?.accountInterviewTypes?.find(
                            (interview: InterviewTypeResponse) =>
                              interview?.id?.toString() === value
                          )
                        : null
                    }
                    filterOptions={filterOptions}
                    renderOption={(props, option: any) => {
                      return (
                        <li {...props} key={option.id} value={option?.id?.toString()}>
                          {option.name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors.clinicalFollowUpNextStep?.[index]?.nextStepAction}
                        helperText={
                          errors.clinicalFollowUpNextStep?.[index]?.nextStepAction?.message
                        }
                        margin="normal"
                        fullWidth
                        label={t(
                          'account.manageAccounts.accountForm.clinicalFollowUps.form.nextStepAction'
                        )}
                      />
                    )}
                  />
                )}
              />
            )}
            {item.type === NextStepType.Monitor && (
              <Controller
                name={`clinicalFollowUpNextStep.${index}.nextStepAction`}
                control={control}
                render={({ fieldState, formState, field: { value }, ...props }) => (
                  <Autocomplete
                    {...props}
                    sx={{
                      width: '100%',
                    }}
                    disabled={!isSuperAdmin()}
                    data-testid="mui-component-select-next-step-action-monitor"
                    options={nextStepActionsData?.accountMonitorTypes || []}
                    getOptionLabel={(option: any) => (option.name ? option.name : '')}
                    onChange={(e: any, values: any) => {
                      const selectedValue = values ? values.id.toString() : '';
                      setValue(`clinicalFollowUpNextStep.${index}.nextStepAction`, selectedValue);
                      showOrHideIntervalOptions(index, item.nextStepId);
                    }}
                    value={
                      value
                        ? nextStepActionsData?.accountMonitorTypes?.find(
                            (monitor: MonitorTypeResponse) => monitor?.id?.toString() === value
                          )
                        : null
                    }
                    filterOptions={filterOptions}
                    renderOption={(props, option: any) => {
                      return (
                        <li {...props} key={option.id} value={option?.id?.toString()}>
                          {option.name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors.clinicalFollowUpNextStep?.[index]?.nextStepAction}
                        helperText={
                          errors.clinicalFollowUpNextStep?.[index]?.nextStepAction?.message
                        }
                        margin="normal"
                        fullWidth
                        label={t(
                          'account.manageAccounts.accountForm.clinicalFollowUps.form.nextStepAction'
                        )}
                      />
                    )}
                  />
                )}
              />
            )}
            {item.type === NextStepType.External && (
              <Controller
                name={`clinicalFollowUpNextStep.${index}.nextStepAction`}
                control={control}
                render={({ field }) => (
                  <StyleTextField
                    {...field}
                    disabled={!isSuperAdmin()}
                    error={!!errors.clinicalFollowUpNextStep?.[index]?.nextStepAction}
                    helperText={errors.clinicalFollowUpNextStep?.[index]?.nextStepAction?.message}
                    margin="normal"
                    fullWidth
                    label={t(
                      'account.manageAccounts.accountForm.clinicalFollowUps.form.nextStepAction'
                    )}
                    variant="outlined"
                  />
                )}
              />
            )}
            {showInterval(item.nextStepId) && (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  gap: 3,
                  flexWrap: matches ? 'wrap' : 'nowrap',
                }}
              >
                <Controller
                  name={`clinicalFollowUpNextStep.${index}.interval`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ width: matches ? '100%' : '50%' }}
                      error={!!errors.clinicalFollowUpNextStep?.[index]?.interval}
                      helperText={errors.clinicalFollowUpNextStep?.[index]?.interval?.message}
                      margin="normal"
                      disabled={!isSuperAdmin()}
                      select
                      fullWidth
                      label={t('interview.form.interval')}
                      variant="outlined"
                      value={field.value || 'Once'}
                      onChange={(event: any) => {
                        handleIntervalChange(event.target.value, index, item.nextStepId);
                      }}
                    >
                      {renderIntervals()}
                    </TextField>
                  )}
                />

                <Controller
                  name={`clinicalFollowUpNextStep.${index}.startAfterDays`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value || 0}
                      sx={{ width: matches ? '100%' : '25%' }}
                      error={!!errors.clinicalFollowUpNextStep?.[index]?.startAfterDays}
                      helperText={errors.clinicalFollowUpNextStep?.[index]?.startAfterDays?.message}
                      margin="normal"
                      fullWidth
                      label={t(
                        'account.manageAccounts.accountForm.clinicalFollowUps.form.startAfterDays'
                      )}
                      variant="outlined"
                      type="number"
                      disabled={!isSuperAdmin() || startAfterDaysDisabled(item.nextStepId)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />

                <Controller
                  name={`clinicalFollowUpNextStep.${index}.maxSendTimes`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value || 0}
                      sx={{ width: matches ? '100%' : '25%' }}
                      error={!!errors.clinicalFollowUpNextStep?.[index]?.maxSendTimes}
                      helperText={errors.clinicalFollowUpNextStep?.[index]?.maxSendTimes?.message}
                      margin="normal"
                      fullWidth
                      label={t(
                        'account.manageAccounts.accountForm.clinicalFollowUps.form.maxSendTimes'
                      )}
                      variant="outlined"
                      type="number"
                      disabled={!isSuperAdmin() || maxSendTimesDisabled(item.nextStepId)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Box>
            )}
          </RoundedContainer>
        ))}
    </>
  );
}
