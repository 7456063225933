import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useGetAccountsExportUsersQuery, useLazyExportUserQuery } from '../../app/services/account';
import { useToast } from '../../app/toast';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { addBreadcrumb, BreadcrumbType } from '../../common/utils/editBreadcrums';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import { ExportUserRequest } from '../../types/UserTypes';
import ExportForm from './ExportForm';

export default function ExportUserPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const toast = useToast();
  const [exportSuccess, setExportSuccess] = useState(false);

  const { data: accountData, isLoading: loadAccountsData } = useGetAccountsExportUsersQuery(
    undefined,
    {}
  );

  const [trigger, { isSuccess: successExport }] = useLazyExportUserQuery();
  const exportUsers = trigger;

  const { state } = useLocation();
  const { navBackUrl } = !!state && state;
  const newBreadcrumb: BreadcrumbType = {
    text: t('user.manageUsers.title'),
    link: navBackUrl || `/dashboard/users`,
  };
  useEffect(() => {
    if (successExport) {
      setExportSuccess(true);
    }
  }, [successExport]);

  useEffect(() => {
    if (exportSuccess) {
      toast.publish(t('user.manageUsers.exportUserForm.exportSuccess'), 'success', 5000);
      setExportSuccess(false);
    }
  }, [exportSuccess, t, toast]);

  const onFormSubmit = async (data: ExportUserRequest, successCallback?: () => void) => {
    toast.publish(t('user.manageUsers.exportUserForm.startExporting'), 'info', 5000);
    try {
      exportUsers(data);
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      toast.publish(t('error.unexpectedError'), 'error', 5000);
    }
  };

  return (
    <DashboardLayout breadcrumbs={addBreadcrumb(breadcrumbs!, newBreadcrumb)}>
      <Helmet>
        <title>{getPageTitle(t('user.manageUsers.exportUser'))}</title>
      </Helmet>

      <Container maxWidth="xl">
        <PageHeader
          sx={{ mt: 1, mb: 2 }}
          headerText={t('user.manageUsers.exportUser')}
          backUrl={navBackUrl || `/dashboard/users`}
        />
      </Container>

      <RoundedContainer>
        {loadAccountsData ? (
          <Box sx={{ my: 2 }}>
            <Loading />
          </Box>
        ) : (
          <ExportForm
            clinicAccounts={[{ id: 'all', name: 'All' }, ...(accountData?.clinicAccounts || [])]}
            onFormSubmit={onFormSubmit}
          />
        )}
      </RoundedContainer>
    </DashboardLayout>
  );
}
